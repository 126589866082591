import { Route, Switch } from "react-router-dom";

import { MenuManagerRoutes } from "#menu-manager/routes";
import { MenuTableScreen } from "#menu-manager/screens";
import { EditMenuScreen } from "#menu-manager/screens/EditMenuScreen";
import { NewMenuScreen } from "#menu-manager/screens/NewMenuScreen";

export function MenuManager() {
    // XXX: stubbed out for now, will do routing
    return (
        <Switch>
            <Route
                path={MenuManagerRoutes.NEW_MENU}
                component={NewMenuScreen}
            />
            <Route
                path={MenuManagerRoutes.EDIT_MENU}
                component={EditMenuScreen}
            />
            <Route path={MenuManagerRoutes.INDEX} component={MenuTableScreen} />
        </Switch>
    );
}
