import { z } from "zod";
import { useFormContext } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { FormCard, FormCardProps } from "src/@/components/form-card";
import { MenuNameSchema } from "#menu-manager/components/menu-form/schema";

type Props = Omit<FormCardProps, "title" | "subtitle">;

export function MenuNameCard(props: Props) {
    const form = useFormContext<z.infer<typeof MenuNameSchema>>();
    return (
        <FormCard
            title={"Name"}
            subtitle={
                "This is the name of the menu that will appear on point of sale and ordering platforms."
            }
            {...props}
        >
            <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                data-1p-ignore // ignore 1password autofill
                                placeholder="Menu name"
                                {...field}
                                aria-label="Menu name"
                                maxLength={500}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </FormCard>
    );
}
