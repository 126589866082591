import { Routes } from "#navigation/routes";

const baseRoute = Routes.MenuManager;

export enum MenuManagerRoutes {
    INDEX = baseRoute,
    NEW_MENU = baseRoute + "/new",
    EDIT_MENU = baseRoute + "/:id"
}

export const menuManagerRoutes: string[] = Object.values(MenuManagerRoutes);
