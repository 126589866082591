import { IUserWithEmployee } from "@snackpass/snackpass-types";
import { useCallback, useState } from "react";

import { AdminFields, SnackOSRole } from "#settings/settings-permissions/types";

const EMPTY_ADMIN_DATA = {
    firstName: "",
    lastName: "",
    identifier: "",
    email: "",
    number: "",
    pin: "",
    wage: "",
    role: ""
};

function findMatchingRole(roleToFind: string): string | null {
    for (const category of Object.values(SnackOSRole)) {
        const roles: { [key: string]: string } = category.roles as {
            [key: string]: string;
        };
        for (const roleKey of Object.keys(roles)) {
            if (roles[roleKey] === roleToFind) {
                return roleKey;
            }
        }
    }
    return null;
}

export function useAdminData(storeId?: string) {
    const [adminData, setAdminData] = useState<AdminFields>(EMPTY_ADMIN_DATA);

    const resetAdmin = useCallback(
        (anAdmin: IUserWithEmployee | null) => {
            let role = "";
            if (anAdmin?.snackpassPermissions.storeRoles) {
                anAdmin.snackpassPermissions.storeRoles.forEach((aRole) => {
                    if (
                        aRole.storeId === storeId &&
                        findMatchingRole(aRole.role)
                    ) {
                        role = aRole.role;
                    }
                });
            }
            if (anAdmin?._id) {
                setAdminData({
                    firstName: anAdmin.firstName ?? "",
                    lastName: anAdmin.lastName ?? "",
                    identifier: anAdmin.employee?.identifier ?? "",
                    email: anAdmin.email ?? "",
                    number: anAdmin.number ?? "",
                    pin: anAdmin.employee?.pin ?? "",
                    role,
                    wage: anAdmin.employee?.wage
                        ? anAdmin.employee.wage.toFixed(2)
                        : ""
                });
            } else {
                setAdminData(EMPTY_ADMIN_DATA);
            }
        },
        [setAdminData, storeId]
    );

    return { adminData, resetAdmin };
}
