/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    AddonGroup,
    FulfillmentTypeEnum,
    InventoryUnitType,
    IProduct,
    ITimeRangeSchema,
    ProductIngredientT,
    ScreenState,
    TaxPolicy,
    TaxPolicyEventType,
    WeightUnitTypes
} from "@snackpass/snackpass-types";
import { PartialDeep } from "type-fest";
import _, { differenceWith, noop } from "lodash";
import { compose, map, sortBy } from "lodash/fp";
import styled from "styled-components";
import "antd/dist/antd.css";
import { Button, Col, Divider, Form, Input, InputNumber } from "antd";
import { SystemColors } from "@snackpass/design-system";
import { useSelector } from "react-redux";
import moment from "moment";
import momentTZ from "moment-timezone";
import { apply } from "json-logic-js";
import { toast } from "sonner";

import CheckboxButton from "#reusable/buttons/default-button";
import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import api from "src/api/rest";
// @ts-ignore this package is installed
import {
    getActiveStore,
    getInventoryItems,
    getLegacyProducts,
    getUser,
    selectActiveMenu
} from "src/redux/selectors";
import Switch from "#reusable/input/toggle-input";
import DropDownSelect, { Options } from "#reusable/select/dropdown";
import { SpecialHourRows } from "#menu-editor/mobile-friendly/items/special-hours-rows";
import constants from "#core/constants";
import { getStoreTimezone } from "#utils/helpers";
import { DropdownOption } from "#core";
import Ingredients from "#menu-editor/mobile-friendly/items/ingredient-select";
import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import { isServerGeneratedId } from "#menu-editor/mobile-friendly/helpers/utils";
import {
    useChannelVisibilitySettings,
    useMultiMenusEnabled
} from "#navigation/utils";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { sendError } from "src/utils/errors";
import { Text } from "#reusable/text";
import {
    isCategoryNameValid,
    isCategoryNameTaken
} from "#menu-editor/mobile-friendly/helpers/menu-helpers";
import {
    InventoryItem,
    setActiveStore,
    setLegacyProducts
} from "src/redux/slices";
import { useAppDispatch } from "src/redux/hooks";
import { fetchLegacyMenu } from "#menu/api";

import {
    ProductNewSharedContext,
    MenuTopLevelContext
} from "../helpers/context";
import {
    convertTimeStringToNumberFormat,
    format12,
    formatDayOfWeek,
    formatTime,
    getStoreOpenDays,
    SpecialHoursItemType
} from "../helpers/date-time-helper";

import SoldOutModal from "./sold-out-modal";
import ModifierBottomDrawer from "./modifier-bottom-drawer";
import ModifierGroup from "./modifier-group";
import Modifiers from "./modifier-table/modifiers";
import CopyModifierGroups from "./copy-modifier-groups";
import ProductImage from "./product-image";

const DESKTOP_MIN_WIDTH = 992;

enum MenuTypeEnum {
    REGULAR = "regular",
    CATERING = "catering"
}

enum PriceOptionEnum {
    FLAT = "Flat",
    WEIGHT = "Weight"
}

const PRICE_OPTIONS = [
    {
        label: PriceOptionEnum.FLAT,
        value: PriceOptionEnum.FLAT
    },
    {
        label: PriceOptionEnum.WEIGHT,
        value: PriceOptionEnum.WEIGHT
    }
];

const findPolicyRate = (
    fulfillment: FulfillmentTypeEnum,
    taxPolicies: TaxPolicy[]
) => {
    const policy = ([...taxPolicies] || [])
        .sort((a, b) => b.priority - a.priority)
        .find((policy) =>
            // eslint-disable-next-line
            apply(policy.conditions, {
                fulfillment
            })
        );
    if (!policy) return undefined;
    const event = policy.events.find(
        (event) => event.type === TaxPolicyEventType.setTaxRate
    );
    if (!event) return undefined;
    return event.taxInfo.rate;
};

const buildTaxPolicy = (rate: number, fulfillment: FulfillmentTypeEnum) => {
    const policy: TaxPolicy = {
        priority: 0,
        conditions: { "==": [{ var: "fulfillment" }, fulfillment] },
        events: [
            {
                type: TaxPolicyEventType.setTaxRate,
                taxInfo: { rate }
            }
        ]
    };
    return policy;
};

const WEIGHT_UNITS: WeightUnitTypes[] = ["oz", "lb", "mg", "g", "kg"];
const PAY_BY_WEIGHT_OPTIONS = WEIGHT_UNITS.map((unit) => ({
    value: unit,
    label: unit
}));

type ProductNewProps = {
    menuAutomation: MenuAutomation;
    newCategory: boolean;
};

const ProductNew: React.FC<ProductNewProps> = ({
    menuAutomation,
    newCategory
}) => {
    const {
        closeNewProductSlideOver,
        storeHours,
        categoryOptions,
        modifierGroupsOptions,
        storeFulfillmentMethod,
        productInDraft,
        setIsLoading,
        setMenuReorderingChanges,
        setShowTableSpinner,
        showNewModifierForm,
        setShowNewModifierForm,
        isNew
    } = useContext(MenuTopLevelContext);
    const activeStore = useSelector(getActiveStore);
    const activeMenu = useSelector(selectActiveMenu);
    const dispatch = useAppDispatch();
    const multiMenusEnabled = useMultiMenusEnabled();
    const [isModifierGroupInEdit, setIsModifierGroupInEdit] =
        useState<boolean>(false);
    const products = useSelector(getLegacyProducts);
    const [storeOpenDays, setStoreOpenDays] = useState<number[]>([]);
    const { width } = useWindowDimensions();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [pun, setPun] = useState<string>("");
    const [category, setCategory] = useState<Options | null>(null);
    const [image, setImage] = useState<string | null | undefined>("");
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [taxRate, setTaxRate] = useState<number | undefined>();
    const [pickupRate, setPickupRate] = useState<number | undefined>();
    const [deliveryRate, setDeliveryRate] = useState<number | undefined>();
    const [dineInRate, setDineInRate] = useState<number | undefined>();
    const [taxPolicies, setTaxPolicies] = useState<TaxPolicy[]>([]);
    const [points, setPoints] = useState<number | undefined>(undefined);
    const [soldOut, setSoldOut] = useState<boolean>(false);
    const [showFulfillmentTaxRate, setShowFulfillmentTaxRate] =
        useState<boolean>(false);
    const [showTaxRateOverride, setShowTaxRateOverride] =
        useState<boolean>(false);
    const [isPickup, setIsPickup] = useState<boolean>(true);
    const [isDineIn, setIsDineIn] = useState<boolean>(true);
    const [isDelivery, setIsDelivery] = useState<boolean>(true);

    // XXX: Sets channel visibilities for the new product
    //      i.e. show product on app? show product on kiosk? etc.
    const channelVisibilityEnabled = useChannelVisibilitySettings();
    const [isAppVisible, setIsAppVisible] = useState<boolean>(true);
    const [isKioskVisible, setIsKioskVisible] = useState<boolean>(true);
    const [isRegisterVisible, setIsRegisterVisible] = useState<boolean>(true);
    const [isOnlineOrderingVisible, setIsOnlineOrderingVisible] =
        useState<boolean>(true);

    const [isPayByWeight, setIsPayByWeight] = useState<{
        label: string;
        value: string;
    }>(PRICE_OPTIONS[0]);
    const [unit, setUnit] = useState<{
        value: WeightUnitTypes | string;
        label: WeightUnitTypes | string;
    }>(PAY_BY_WEIGHT_OPTIONS[0]);
    const user = useSelector(getUser);
    const [perUnit, setPerUnit] = useState<number | undefined>(undefined);
    const [upsell, setUpsell] = useState<boolean>(false);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);
    const [showSpecialHour, setShowSpecialHour] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [specialHours, setSpecialHours] = useState<SpecialHoursItemType[]>(
        []
    );
    const [modifierGroups, setModifierGroups] = useState<
        PartialDeep<AddonGroup>[]
    >([]);
    const userIsTeam = user?.snackpassPermissions.isSnackpassEmployee || false;
    const [showBottomDrawer, setShowBottomDrawer] = useState<boolean>(false);
    const [isMarkProductSoldOutModalOpen, setIsMarkProductSoldOutModalOpen] =
        useState(false);
    const [soldOutDates, setSoldOutDates] = useState<{
        from: Date | string | null;
        until: Date | string | null;
    }>({ from: null, until: null });
    const timezone = getStoreTimezone(activeStore);
    const [modifierGroupInEdit, setModifierGroupInEdit] = useState<
        PartialDeep<AddonGroup> | undefined
    >();
    const [formattedOptions, setFormattedOptions] = useState<
        DropdownOption<InventoryItem>[]
    >([]);
    const [productInventoryItems, setProductInventoryItems] = useState<
        ProductIngredientT[] | undefined
    >([]);

    const allItems = useSelector(getInventoryItems);
    const isCatering = activeMenu === MenuTypeEnum.CATERING;
    const errorMsg = isCatering
        ? "Catering product category name must end with '(Catering)'"
        : "Non-catering product category name cannot end with '(Catering)'";
    const inventoryItems = useMemo(
        () =>
            differenceWith(
                allItems,
                productInventoryItems ?? [],
                (a, b) => a.id === b.inventoryItemId
            ),
        [allItems, productInventoryItems]
    );
    useMemo(() => {
        const sortedInventory = compose(
            map((item: InventoryItem) => ({
                label: item.name,
                value: item.id,
                data: item
            })),
            sortBy("name")
        )(inventoryItems);

        setFormattedOptions(sortedInventory);
    }, [allItems, productInventoryItems]);

    useEffect(() => {
        if (activeStore && activeStore.hours) {
            //get store open days
            const openDays = getStoreOpenDays(activeStore.hours);
            setStoreOpenDays(openDays);
            setSpecialHours([
                {
                    id: 0,
                    dayOfWeek: openDays[0],
                    time: {
                        start: moment("12:00 am", format12),
                        end: moment("11:59 pm", format12)
                    }
                }
            ]);
        }
        if (activeStore) {
            setIsDelivery(activeStore.delivery);
            setIsDineIn(activeStore.dineInPreferences.hasDineIn === true);
            setIsPickup(activeStore.pickup);
        }
    }, [activeStore]);

    useEffect(() => {
        setName(productInDraft?.name ?? "");
        setDescription(
            productInDraft?.description ? productInDraft.description : ""
        );
        setPun(productInDraft?.pun ? productInDraft.pun : "");
        setPoints(productInDraft?.points);
        newCategory && productInDraft?.category
            ? setCategory({
                  label: productInDraft?.category,
                  value: productInDraft?.category
              })
            : productInDraft && categoryOptions
              ? setCategory(
                    categoryOptions.filter(
                        (option) => option.label === productInDraft?.category
                    )[0]
                )
              : setCategory(null);
        setIsPayByWeight(
            productInDraft?.priceByWeight ? PRICE_OPTIONS[1] : PRICE_OPTIONS[0]
        );
        setImage(productInDraft?.image ?? "");
        setPrice(productInDraft?.price);
        if (productInDraft?.priceByWeight) {
            setPerUnit(productInDraft.priceByWeight?.perUnit);
            setUnit(
                PAY_BY_WEIGHT_OPTIONS.filter(
                    (option) =>
                        option.label === productInDraft?.priceByWeight?.unit
                )[0]
            );
        }
        productInDraft?.soldOutDates
            ? setSoldOut(productInDraft.soldOut === true)
            : setSoldOut(false);
        productInDraft &&
            setSoldOutDates({
                from: productInDraft?.soldOutDates?.from
                    ? productInDraft?.soldOutDates?.from
                    : null,
                until: productInDraft?.soldOutDates?.until
                    ? productInDraft?.soldOutDates?.until
                    : null
            });
        setUpsell(productInDraft?.upsell ?? false);
        setIsTemplate(productInDraft?.isTemplate ?? false);
        storeFulfillmentMethod.isPickup
            ? setIsPickup(
                  productInDraft?.fulfillmentMethods?.isPickup ??
                      storeFulfillmentMethod.isPickup
              )
            : null;
        storeFulfillmentMethod.isDelivery
            ? setIsDelivery(
                  productInDraft?.fulfillmentMethods?.isDelivery ??
                      storeFulfillmentMethod.isDelivery
              )
            : null;
        storeFulfillmentMethod.isDineIn
            ? setIsDineIn(
                  productInDraft?.fulfillmentMethods?.isDineIn ??
                      storeFulfillmentMethod.isDineIn
              )
            : null;
        setIsAppVisible(productInDraft?.channelVisibility?.app ?? true);
        setIsKioskVisible(productInDraft?.channelVisibility?.kiosk ?? true);
        setIsRegisterVisible(
            productInDraft?.channelVisibility?.register ?? true
        );
        setIsOnlineOrderingVisible(
            productInDraft?.channelVisibility?.onlineOrdering ?? true
        );
        productInDraft?.hours
            ? setShowSpecialHour(productInDraft.hours !== null)
            : setShowSpecialHour(false);
        setShowTaxRateOverride(productInDraft?.taxInfo?.rate !== undefined);
        setTaxPolicies(productInDraft?.taxPolicies ?? []);
        setTaxRate(productInDraft?.taxInfo?.rate);
        if (productInDraft?.hours) {
            const specialHours: SpecialHoursItemType[] =
                productInDraft.hours.local.map((item, i) => ({
                    id: i,
                    dayOfWeek: formatDayOfWeek(item.start),
                    time: {
                        start: formatTime(item.start),
                        end: formatTime(item.end)
                    }
                }));
            setSpecialHours(specialHours);
        } else {
            setSpecialHours([
                {
                    id: 0,
                    dayOfWeek: storeOpenDays[0],
                    time: {
                        start: moment("12:00 am", format12),
                        end: moment("11:59 pm", format12)
                    }
                }
            ]);
        }
        setModifierGroups(productInDraft?.addonGroups ?? []);
        setIsLoading(false);
    }, [productInDraft, isNew, newCategory]);

    useEffect(() => {
        setShowFulfillmentTaxRate(!!taxPolicies.length);
        setPickupRate(findPolicyRate(FulfillmentTypeEnum.Pickup, taxPolicies));
        setDeliveryRate(
            findPolicyRate(FulfillmentTypeEnum.Delivery, taxPolicies)
        );
        setDineInRate(findPolicyRate(FulfillmentTypeEnum.DineIn, taxPolicies));
    }, [taxPolicies]);

    useEffect(() => {
        // TODO: refactor to a higher level implementation
        // have both pickup and dine-in default selected for the fulfillment section when fulfillment specific taxes are being used
        if (pickupRate !== undefined) {
            setIsPickup(true);
        }
        if (dineInRate !== undefined) {
            setIsDineIn(true);
        }
    }, [showFulfillmentTaxRate, pickupRate, dineInRate]);

    useEffect(() => {
        form.setFieldsValue({
            name: name,
            category: category,
            points: points,
            price: price,
            isPayByWeight: isPayByWeight,
            unit: unit,
            perUnit: perUnit,
            image: image,
            description: description,
            pun: pun,
            isPickup: isPickup,
            isDelivery: isDelivery,
            isDineIn: isDineIn,
            hours: specialHours,
            soldOut: soldOut,
            upsell: upsell,
            modifierGroups: modifierGroups,
            pickupRate: pickupRate,
            deliveryRate: deliveryRate,
            dineInRate: dineInRate,
            taxInfo: taxRate
        });
    }, [
        name,
        category,
        points,
        price,
        isPayByWeight,
        unit,
        perUnit,
        image,
        description,
        pun,
        isDineIn,
        isDelivery,
        isPickup,
        specialHours,
        soldOut,
        soldOutDates,
        upsell,
        modifierGroups,
        specialHours,
        pickupRate,
        deliveryRate,
        dineInRate,
        taxRate
    ]);
    const handleUpdate = () => {
        const policies: TaxPolicy[] = [];
        if (pickupRate !== undefined)
            policies.push(
                buildTaxPolicy(pickupRate, FulfillmentTypeEnum.Pickup)
            );
        if (deliveryRate !== undefined)
            policies.push(
                buildTaxPolicy(deliveryRate, FulfillmentTypeEnum.Delivery)
            );
        if (dineInRate !== undefined)
            policies.push(
                buildTaxPolicy(dineInRate, FulfillmentTypeEnum.DineIn)
            );
        setTaxPolicies(policies);
    };
    const formatSpecialHours = () => {
        const newHours: ITimeRangeSchema[] = [];

        specialHours &&
            specialHours.map((item) => {
                if (item.time.start && item.time.end) {
                    const formattedHours = {
                        start: convertTimeStringToNumberFormat(
                            item.dayOfWeek,
                            item.time.start
                        ),
                        end: convertTimeStringToNumberFormat(
                            item.dayOfWeek,
                            item.time.end
                        )
                    };
                    newHours.push(formattedHours);
                }
            });

        return newHours;
    };

    const _isProductNameTaken = (
        products: IProduct[],
        newProductName: string
    ) => {
        let hasSameNameUnderSameCategory = false;
        products.map((product) => {
            if (
                product.name === newProductName &&
                product.category === category?.label
            ) {
                hasSameNameUnderSameCategory = true;
                return;
            }
        });
        return hasSameNameUnderSameCategory;
    };

    const createProduct = (
        activeStoreId: string,
        body: PartialDeep<IProduct>
    ) => {
        setShowTableSpinner(true);
        api.products
            .create(body)
            .then(async () => api.stores.getOne(activeStoreId))
            .then(async (res) => {
                const activeStore = res.data.store;
                dispatch(setActiveStore(activeStore));
                if (multiMenusEnabled)
                    dispatch(
                        multiMenuThunks.fetchStoreMultiMenus(activeStore._id)
                    );
                return fetchLegacyMenu(activeStore._id);
            })
            .then(({ products }) => {
                dispatch(setLegacyProducts(products));
                setShowTableSpinner(false);
            })
            .catch((err) => {
                sendError(err);
                toast.error(
                    `Could not create product 🤦‍♀️\n${err.response.data.message}`
                );
                return;
            })
            .finally(() => setShowTableSpinner(false));
    };

    const handleAddProduct = () => {
        if (!activeStore) {
            return;
        }
        const specialHours = {
            zone: activeStore.hours.zone,
            local: formatSpecialHours()
        };

        //have to put object here because new created addonGroups need to removed _id & some type in local states are different e.g.: soldOutDates
        const post: object = {
            name: name,
            description: description,
            pun: pun,
            category: category?.label,
            hours:
                showSpecialHour && !_.isEmpty(specialHours)
                    ? {
                          zone: activeStore.hours.zone,
                          local: formatSpecialHours()
                      }
                    : null,
            isCatering: isCatering,
            minimumQuantity: 1,
            price: isPayByWeight.value === PriceOptionEnum.FLAT ? price : 0,
            storeId: activeStore._id,
            soldOutDates: soldOutDates.until
                ? {
                      from: momentTZ(new Date()).tz(timezone).toISOString(),
                      until: soldOutDates.until
                  }
                : { from: null, until: null },
            soldOut: soldOut,
            priceByWeight:
                isPayByWeight.value === PriceOptionEnum.FLAT
                    ? null
                    : { unit: unit.value, perUnit: perUnit },
            points: points ?? 0,
            upsell: upsell,
            image: image ? image : null,
            //Remove _id in new created addonGroups and new created addons(new created has Date.now().toString() as temporary _id which has length of 13), server-side will generate unique ObjectId(length of 24) for new created addon
            addonGroups: modifierGroups.map((item) => {
                if (item && item._id && isServerGeneratedId(item?._id)) {
                    item = {
                        name: item?.name,
                        limit: item?.limit,
                        addons: item?.addons,
                        required: item?.required,
                        supportsMultiple: item?.supportsMultiple
                    };
                }
                if (item.addons) {
                    const addonsWithoutId = item.addons.map((addon) => {
                        if (
                            addon &&
                            addon._id &&
                            isServerGeneratedId(addon?._id)
                        ) {
                            return {
                                name: addon?.name,
                                price: addon?.price,
                                soldOutDates: addon?.soldOutDates,
                                soldOut: addon?.soldOut,
                                inventoryItems: addon?.inventoryItems
                            };
                        }
                        return addon;
                    });
                    return { ...item, addons: addonsWithoutId };
                }
                return item;
            }),
            fulfillmentMethods: {
                isDelivery: isDelivery,
                isDineIn: isDineIn,
                isPickup: isPickup
            },
            channelVisibility: {
                app: isAppVisible,
                kiosk: isKioskVisible,
                register: isRegisterVisible,
                onlineOrdering: isOnlineOrderingVisible
            },
            taxPolicies: taxPolicies,
            taxInfo:
                taxRate !== undefined && showTaxRateOverride
                    ? { rate: taxRate }
                    : null,
            isTemplate: isTemplate,
            inventoryItems: productInventoryItems
        };
        createProduct(activeStore._id, post);
        setMenuReorderingChanges(0);
    };

    const onFinish = () => {
        handleAddProduct();
        closeNewProductSlideOver();
    };

    const onFinishFailed = () => {
        toast.error(
            "Failed to update product, please check all the required fields"
        );
    };

    const validateMessages = {
        required: "${label} is required!",
        types: {
            name: "${label} is not valid",
            number: "${label} is not a valid number!"
        },
        number: {
            min: "${label} cannot be less than ${min}"
        }
    };

    return showNewModifierForm ? (
        <ProductNewSharedContext.Provider
            value={{
                showBottomDrawer,
                setShowBottomDrawer,
                modifierGroups,
                setModifierGroups,
                setShowNewModifierForm,
                isModifierGroupInEdit,
                setDeletedAddonGroups: noop,
                addDeletedAddons: noop,
                modifierGroupInEdit,
                modifierGroupsOptions,
                setIsModifierGroupInEdit,
                showNewModifierForm,
                setModifierGroupInEdit
            }}
        >
            <ModifierGroup
                isProductInEditMode={false}
                menuAutomation={menuAutomation}
            ></ModifierGroup>
        </ProductNewSharedContext.Provider>
    ) : (
        <ProductNewSharedContext.Provider
            value={{
                showBottomDrawer,
                setShowBottomDrawer,
                modifierGroups,
                setModifierGroups,
                setDeletedAddonGroups: noop,
                addDeletedAddons: noop,
                setShowNewModifierForm,
                isModifierGroupInEdit,
                modifierGroupInEdit,
                modifierGroupsOptions,
                setIsModifierGroupInEdit,
                showNewModifierForm,
                setModifierGroupInEdit
            }}
        >
            <Body>
                <HeaderRow>
                    <span
                        className="header-text"
                        onClick={closeNewProductSlideOver}
                    >
                        <Chevron
                            fill={SystemColors.v1.sesame}
                            className="clickable-icon"
                        />
                        Create New Item
                    </span>
                </HeaderRow>
                <Divider />
                <Form
                    form={form}
                    layout="vertical"
                    name="nest-messages"
                    requiredMark={true}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    validateMessages={validateMessages}
                    className="new-product"
                >
                    <Row className="flexWrap f1 mt12">
                        <Col span={width >= DESKTOP_MIN_WIDTH ? 14 : 24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true
                                    },
                                    {
                                        async validator(_, value) {
                                            const isTaken = _isProductNameTaken(
                                                products,
                                                value
                                            );
                                            if (isTaken) {
                                                return Promise.reject(
                                                    "This product name is already taken in this category"
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Item Name"
                                    value={name}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Row>
                                <Col span={16}>
                                    <Form.Item
                                        name="category"
                                        label="Category"
                                        className="category-row"
                                        hasFeedback
                                        validateFirst
                                        rules={[
                                            {
                                                required: true
                                            },
                                            {
                                                async validator() {
                                                    const isTaken =
                                                        isCategoryNameTaken(
                                                            category?.value.toString() ||
                                                                "",
                                                            products
                                                        );
                                                    if (isTaken) {
                                                        return Promise.reject(
                                                            "This category name is already taken"
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                }
                                            },
                                            {
                                                async validator(_, value) {
                                                    const categoryNameValid =
                                                        isCategoryNameValid(
                                                            value.label,
                                                            isCatering
                                                        );
                                                    if (!categoryNameValid) {
                                                        return Promise.reject(
                                                            errorMsg
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                        ]}
                                    >
                                        <DropDownSelect
                                            isCreatable
                                            square
                                            options={categoryOptions}
                                            value={category}
                                            onChange={(
                                                selectedOption: React.SetStateAction<Options | null>
                                            ) => {
                                                setCategory(selectedOption);
                                            }}
                                            placeholder="Select or create a category"
                                            formatCreateLabel="Create a New Category"
                                            height="40px"
                                        ></DropDownSelect>
                                    </Form.Item>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={7}>
                                    <Form.Item
                                        name="points"
                                        label="Points"
                                        required
                                        rules={[
                                            {
                                                type: "number",
                                                min: 0
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            className="input-number"
                                            size="large"
                                            value={points}
                                            onChange={(e) => {
                                                if (e !== null) setPoints(e);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    span={
                                        isPayByWeight.value ===
                                        PriceOptionEnum.WEIGHT
                                            ? 8
                                            : 12
                                    }
                                >
                                    <Form.Item
                                        name="isPayByWeight"
                                        label="Price Type"
                                        rules={[
                                            {
                                                required: true
                                            }
                                        ]}
                                    >
                                        <DropDownSelect
                                            square
                                            options={PRICE_OPTIONS}
                                            value={PRICE_OPTIONS.filter(
                                                (option) =>
                                                    option.label ===
                                                    PriceOptionEnum.FLAT
                                            )}
                                            onChange={(
                                                selectedOption: React.SetStateAction<{
                                                    label: string;
                                                    value: string;
                                                }>
                                            ) => {
                                                setIsPayByWeight(
                                                    selectedOption
                                                );
                                            }}
                                            placeholder="Select"
                                            height="40px"
                                        ></DropDownSelect>
                                    </Form.Item>
                                </Col>
                                <Col span={1}></Col>

                                {isPayByWeight.value ===
                                PriceOptionEnum.WEIGHT ? (
                                    <>
                                        <Col span={8}>
                                            <Form.Item
                                                name="unit"
                                                label="Unit"
                                                rules={[
                                                    {
                                                        required: true
                                                    }
                                                ]}
                                            >
                                                <DropDownSelect
                                                    square
                                                    options={
                                                        PAY_BY_WEIGHT_OPTIONS
                                                    }
                                                    value={unit}
                                                    onChange={(
                                                        selectedOption: React.SetStateAction<{
                                                            value: string;
                                                            label: string;
                                                        }>
                                                    ) => {
                                                        setUnit(selectedOption);
                                                    }}
                                                    placeholder="Select"
                                                    height="40px"
                                                ></DropDownSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col>
                                            <Form.Item
                                                name="perUnit"
                                                label="Per Unit"
                                                rules={[
                                                    {
                                                        required:
                                                            isPayByWeight.value ===
                                                            PriceOptionEnum.WEIGHT
                                                    },
                                                    {
                                                        type: "number",
                                                        min: 0
                                                    }
                                                ]}
                                            >
                                                <InputNumber
                                                    formatter={(value) =>
                                                        `$ ${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )
                                                    }
                                                    className="input-number"
                                                    size="large"
                                                    value={perUnit}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setPerUnit(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col span={11}>
                                            <Form.Item
                                                name="price"
                                                label="Price"
                                                rules={[
                                                    {
                                                        required:
                                                            isPayByWeight.value ===
                                                            PriceOptionEnum.FLAT
                                                    },
                                                    {
                                                        type: "number",
                                                        min: 0
                                                    }
                                                ]}
                                            >
                                                <InputNumber
                                                    className="input-number"
                                                    size="large"
                                                    value={price}
                                                    onChange={(e) =>
                                                        setPrice(e || 0)
                                                    }
                                                    formatter={(value) =>
                                                        // Use tolocaleString to format the number with commas visually for thousands place.
                                                        parseFloat(
                                                            // @ts-expect-error value is incorrectly typed in antd as a number when it is actually a string
                                                            value || 0
                                                        ).toLocaleString()
                                                    }
                                                    precision={2}
                                                    prefix={
                                                        <Text
                                                            weight="thin"
                                                            size="s"
                                                        >
                                                            $
                                                        </Text>
                                                    }
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>
                        {width > DESKTOP_MIN_WIDTH ? (
                            <>
                                <Col span={2}></Col>
                                <Col span={8}>
                                    <Form.Item name="image" label="Image">
                                        <ProductImage
                                            imageURL={image}
                                            setImage={setImage}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        ) : null}
                    </Row>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea
                            placeholder="Add a optional description for this item. Include details like ingredients, flavors, textures or spice"
                            size="large"
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </Form.Item>
                    {width <= DESKTOP_MIN_WIDTH ? (
                        <Col span={24}>
                            <Form.Item label="Photo">
                                <ProductImage
                                    imageURL={image}
                                    setImage={setImage}
                                />
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Form.Item
                        label="Fulfillment"
                        name="fulfillment"
                        rules={[
                            {
                                async validator() {
                                    if (!(isPickup || isDelivery || isDineIn)) {
                                        return Promise.reject(
                                            "Please select at least 1 fulfillment method"
                                        );
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Row className="fulfillment">
                            {storeFulfillmentMethod.isPickup ? (
                                <Col span={8}>
                                    <Form.Item
                                        label=""
                                        name="isPickup"
                                        className="fulfillmentMethod"
                                    >
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                checkBox
                                                checked={isPickup}
                                                onClick={() =>
                                                    setIsPickup(!isPickup)
                                                }
                                                mr={4}
                                                ml={4}
                                            >
                                                Pickup
                                            </CheckboxButton>{" "}
                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                            ) : null}
                            {storeFulfillmentMethod.isDelivery ? (
                                <Col span={8}>
                                    <Form.Item
                                        name="isDelivery"
                                        label=""
                                        className="fulfillmentMethod"
                                    >
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                mr={4}
                                                ml={4}
                                                onClick={() => {
                                                    form.setFieldsValue(
                                                        isDelivery
                                                    );
                                                    setIsDelivery(!isDelivery);
                                                }}
                                                checked={isDelivery}
                                                checkBox
                                            >
                                                Delivery
                                            </CheckboxButton>
                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                            ) : null}
                            {storeFulfillmentMethod.isDineIn ? (
                                <Col span={8}>
                                    <Form.Item
                                        name="isDineIn"
                                        label=""
                                        className="fulfillmentMethod"
                                    >
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                onClick={() =>
                                                    setIsDineIn(!isDineIn)
                                                }
                                                checkBox
                                                checked={isDineIn}
                                                mr={4}
                                                ml={4}
                                            >
                                                Dine In
                                            </CheckboxButton>
                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                            ) : null}
                        </Row>
                    </Form.Item>
                    {channelVisibilityEnabled ? (
                        <Form.Item label="Channels" name="channels">
                            <Row style={{ gap: 8, flexWrap: "wrap" }}>
                                <Form.Item noStyle>
                                    <CheckboxButton
                                        style={{ flexGrow: 1 }}
                                        variant="outline"
                                        squareBtn
                                        fullWidth
                                        checkBox
                                        checked={isAppVisible}
                                        onClick={() =>
                                            setIsAppVisible(!isAppVisible)
                                        }
                                    >
                                        App
                                    </CheckboxButton>
                                </Form.Item>
                                <Form.Item noStyle>
                                    <CheckboxButton
                                        style={{ flexGrow: 1 }}
                                        variant="outline"
                                        squareBtn
                                        fullWidth
                                        checkBox
                                        checked={isKioskVisible}
                                        onClick={() =>
                                            setIsKioskVisible(!isKioskVisible)
                                        }
                                    >
                                        Kiosk
                                    </CheckboxButton>
                                </Form.Item>
                                <Form.Item noStyle>
                                    <CheckboxButton
                                        style={{ flexGrow: 1 }}
                                        variant="outline"
                                        squareBtn
                                        fullWidth
                                        checkBox
                                        checked={isRegisterVisible}
                                        onClick={() =>
                                            setIsRegisterVisible(
                                                !isRegisterVisible
                                            )
                                        }
                                    >
                                        Register
                                    </CheckboxButton>
                                </Form.Item>
                                <Form.Item noStyle>
                                    <CheckboxButton
                                        style={{ flexGrow: 1 }}
                                        variant="outline"
                                        squareBtn
                                        fullWidth
                                        checkBox
                                        checked={isOnlineOrderingVisible}
                                        onClick={() =>
                                            setIsOnlineOrderingVisible(
                                                !isOnlineOrderingVisible
                                            )
                                        }
                                    >
                                        Online Ordering
                                    </CheckboxButton>
                                </Form.Item>
                            </Row>
                        </Form.Item>
                    ) : null}
                    {userIsTeam ? (
                        <>
                            <Form.Item label="Sales Tax" className="sales-tax">
                                <Form.Item className="taxRateOverride">
                                    <RowForSwitch>
                                        <Label>Tax Rate Override</Label>
                                        <Form.Item
                                            name="taxRateOverride"
                                            noStyle
                                        >
                                            <Switch
                                                id="taxRateOverride"
                                                onChange={() => {
                                                    if (showTaxRateOverride)
                                                        setTaxRate(undefined);

                                                    if (!showTaxRateOverride) {
                                                        setShowFulfillmentTaxRate(
                                                            false
                                                        );
                                                        setPickupRate(
                                                            undefined
                                                        );
                                                        setDineInRate(
                                                            undefined
                                                        );
                                                        setDeliveryRate(
                                                            undefined
                                                        );
                                                    }
                                                    setShowTaxRateOverride(
                                                        !showTaxRateOverride
                                                    );
                                                }}
                                                isOn={showTaxRateOverride}
                                            ></Switch>
                                        </Form.Item>
                                    </RowForSwitch>
                                </Form.Item>
                                {showTaxRateOverride ? (
                                    <Form.Item
                                        name="taxInfo"
                                        className="taxInfo"
                                        rules={[
                                            {
                                                type: "number",
                                                min: 0,
                                                max: 20,
                                                message:
                                                    "Invalid tax rate. Please enter a numeric value between 0 and 20."
                                            },
                                            {
                                                required: showTaxRateOverride
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            className="input-number tax"
                                            size="large"
                                            value={taxRate}
                                            onChange={(e) => {
                                                if (e !== null) setTaxRate(e);
                                            }}
                                            onKeyDown={(
                                                e: React.KeyboardEvent<HTMLInputElement>
                                            ) => {
                                                if (e.key === "Enter") {
                                                    e.currentTarget.blur();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                ) : null}
                                <RowForSwitch>
                                    <Label>
                                        Fulfillment-Specific Tax Rates
                                    </Label>
                                    <Form.Item
                                        name="fulfillmentTaxRate"
                                        noStyle
                                    >
                                        <Switch
                                            id="fulfillmentTaxRate"
                                            onChange={() => {
                                                if (showFulfillmentTaxRate) {
                                                    setPickupRate(undefined);
                                                    setDineInRate(undefined);
                                                    setDeliveryRate(undefined);
                                                }

                                                if (!showFulfillmentTaxRate) {
                                                    setShowTaxRateOverride(
                                                        false
                                                    );
                                                    setTaxRate(undefined);
                                                }

                                                setShowFulfillmentTaxRate(
                                                    !showFulfillmentTaxRate
                                                );
                                            }}
                                            isOn={showFulfillmentTaxRate}
                                        ></Switch>
                                    </Form.Item>
                                </RowForSwitch>
                                <Extra>
                                    Set individual tax rates for each
                                    fulfillment method
                                </Extra>

                                {showFulfillmentTaxRate ? (
                                    <Row className="fulfillmentTaxRateRow">
                                        <Col span={6}>
                                            <Form.Item
                                                name="pickupRate"
                                                className="fulfillmentTaxRate"
                                                label="Pickup"
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                        max: 20,
                                                        message:
                                                            "Invalid tax rate. Please enter a numeric value between 0 and 20."
                                                    },
                                                    {
                                                        required:
                                                            showFulfillmentTaxRate
                                                    }
                                                ]}
                                            >
                                                <InputNumber
                                                    formatter={(value) =>
                                                        `${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )
                                                    }
                                                    className="input-number tax"
                                                    size="large"
                                                    value={pickupRate}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setPickupRate(e);
                                                    }}
                                                    onBlur={handleUpdate}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}></Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="deliveryRate"
                                                label="Delivery"
                                                className="fulfillmentTaxRate"
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                        max: 20,
                                                        message:
                                                            "Invalid tax rate. Please enter a numeric value between 0 and 20."
                                                    },
                                                    {
                                                        required:
                                                            showFulfillmentTaxRate
                                                    }
                                                ]}
                                            >
                                                <InputNumber
                                                    formatter={(value) =>
                                                        `${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )
                                                    }
                                                    className="input-number tax"
                                                    size="large"
                                                    value={deliveryRate}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setDeliveryRate(e);
                                                    }}
                                                    onBlur={handleUpdate}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}></Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="dineInRate"
                                                label="Dine-in"
                                                className="fulfillmentTaxRate"
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                        max: 20,
                                                        message:
                                                            "Invalid tax rate. Please enter a numeric value between 0 and 20."
                                                    },
                                                    {
                                                        required:
                                                            showFulfillmentTaxRate
                                                    }
                                                ]}
                                            >
                                                <InputNumber
                                                    formatter={(value) =>
                                                        `${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )
                                                    }
                                                    className="input-number tax"
                                                    size="large"
                                                    value={dineInRate}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setDineInRate(e);
                                                    }}
                                                    onBlur={handleUpdate}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ) : null}
                            </Form.Item>
                        </>
                    ) : null}
                    <Divider />
                    <Form.Item label="Additional Options">
                        <RowForSwitch>
                            <Label>Sold Out</Label>
                            <Form.Item name="soldOut" noStyle>
                                <Switch
                                    id="sold-out"
                                    onChange={() => {
                                        if (
                                            !soldOut &&
                                            soldOutDates.until === null
                                        ) {
                                            setIsMarkProductSoldOutModalOpen(
                                                true
                                            );
                                        } else {
                                            setSoldOutDates({
                                                from: null,
                                                until: null
                                            });
                                            setSoldOut(false);
                                        }
                                    }}
                                    isOn={soldOut}
                                ></Switch>
                            </Form.Item>
                        </RowForSwitch>
                        {soldOutDates.until ? (
                            <Extra>
                                {`Sold out until ${momentTZ(soldOutDates.until)
                                    .tz(timezone)
                                    .format("LLL")}`}
                            </Extra>
                        ) : null}
                        <div className="mb12" />
                        <RowForSwitch>
                            <Label>Show as Upsell</Label>
                            <Form.Item name="upsell" noStyle>
                                <Switch
                                    id="up-sell"
                                    onChange={() => {
                                        setUpsell(!upsell);
                                    }}
                                    isOn={upsell}
                                ></Switch>
                            </Form.Item>
                        </RowForSwitch>
                        <Extra>
                            Product will show up on checkout screen in "People
                            also get" section if product has image and in stock.
                        </Extra>
                        <div className="mb12" />
                        <RowForSwitch>
                            <Label>Use As Template</Label>
                            <Form.Item name="template" noStyle>
                                <Switch
                                    id="template"
                                    onChange={() => {
                                        setIsTemplate(!isTemplate);
                                    }}
                                    isOn={isTemplate}
                                ></Switch>
                            </Form.Item>
                        </RowForSwitch>
                        <div className="mb12" />
                        <RowForSwitch>
                            <Label>Special Hours</Label>
                            <Form.Item name="hours" noStyle>
                                <Switch
                                    id="special-hour"
                                    onChange={() => {
                                        setShowSpecialHour(!showSpecialHour);
                                    }}
                                    isOn={showSpecialHour}
                                ></Switch>
                            </Form.Item>
                        </RowForSwitch>
                        <Extra>Product is sold only during these hours</Extra>
                        <div className="mb12" />
                        <Form.Item name="hours" noStyle>
                            <SpecialHourRows
                                show={showSpecialHour}
                                specialHours={specialHours}
                                setSpecialHours={setSpecialHours}
                                setShowSpecialHour={setShowSpecialHour}
                                storeHours={storeHours}
                                storeOpenDays={storeOpenDays}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Divider />
                    {width < constants.TABLET_MIN_WIDTH ? (
                        <>
                            <Form.Item
                                className="customize-item-mobile"
                                label="Customize Item"
                            >
                                <NewModifiers
                                    onClick={() => {
                                        setShowBottomDrawer(true);
                                    }}
                                >
                                    <span className="new-modifier-group">
                                        New Modifier Groups
                                    </span>
                                    <span className="add">+</span>
                                    <span className="new-modifier-description">
                                        Select or create a new modifier group
                                    </span>
                                </NewModifiers>
                            </Form.Item>
                            <Modifiers isProductInEditMode={false}></Modifiers>
                        </>
                    ) : (
                        <>
                            <Form.Item
                                name="modifierGroups"
                                label="Customize Item"
                                className="customize-item"
                            >
                                <CopyModifierGroups
                                    modifierGroups={modifierGroups}
                                    setModifierGroups={setModifierGroups}
                                    showNewModifierForm={showNewModifierForm}
                                    setShowNewModifierForm={
                                        setShowNewModifierForm
                                    }
                                    modifierGroupsOptions={
                                        modifierGroupsOptions
                                    }
                                    setIsModifierGroupInEdit={
                                        setIsModifierGroupInEdit
                                    }
                                ></CopyModifierGroups>
                            </Form.Item>
                            <Form.Item className="customize-item">
                                <Modifiers
                                    isProductInEditMode={false}
                                ></Modifiers>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item className="ingredients" label="Ingredients">
                        <Ingredients
                            formattedOptions={formattedOptions}
                            productInventoryItems={productInventoryItems}
                            setProductInventoryItems={setProductInventoryItems}
                            handleAddIngredients={(
                                newIngredient: DropdownOption<InventoryItem>
                            ) => {
                                const formatIngredient: ProductIngredientT = {
                                    inventoryItemId: newIngredient.value,
                                    unitsConsumed: {
                                        amount: 0,
                                        unit:
                                            (newIngredient.data
                                                ?.baseUnit as InventoryUnitType) ??
                                            "",
                                        precision: 3
                                    }
                                };
                                const newProductInventoryItems: ProductIngredientT[] =
                                    productInventoryItems
                                        ? productInventoryItems.slice()
                                        : [];
                                newProductInventoryItems?.push(
                                    formatIngredient
                                );
                                setProductInventoryItems(
                                    newProductInventoryItems
                                );
                            }}
                            handleUpdateIngredients={(
                                ingredientId: string,
                                newPerUnit: number
                            ) => {
                                const newProductInventoryItems:
                                    | ProductIngredientT[]
                                    | undefined = productInventoryItems?.map(
                                    (item) => {
                                        if (
                                            item.inventoryItemId ===
                                            ingredientId
                                        ) {
                                            return {
                                                inventoryItemId:
                                                    item.inventoryItemId,
                                                unitsConsumed: {
                                                    ...item.unitsConsumed,
                                                    amount: newPerUnit
                                                }
                                            };
                                        }
                                        return item;
                                    }
                                );

                                setProductInventoryItems(
                                    newProductInventoryItems
                                );
                            }}
                            handleRemoveIngredients={(ingredientId: string) => {
                                const newProductInventoryItems: ProductIngredientT[] =
                                    productInventoryItems
                                        ? productInventoryItems.slice()
                                        : [];
                                productInventoryItems?.map((item, i) => {
                                    if (item.inventoryItemId === ingredientId) {
                                        newProductInventoryItems.splice(i, 1);
                                    }
                                });
                                setProductInventoryItems(
                                    newProductInventoryItems
                                );
                            }}
                        />
                    </Form.Item>
                    <Row className="save-btn-bg">
                        <Button
                            type="primary"
                            className="save-btn"
                            size="large"
                            htmlType="submit"
                            shape="round"
                        >
                            Save Item
                        </Button>
                    </Row>
                </Form>
                <ModifierBottomDrawer isProductInEditMode={false} />
                <SoldOutModal
                    onHide={() => setIsMarkProductSoldOutModalOpen(false)}
                    handleOk={({ soldOut, soldOutDates }) => {
                        setSoldOut(soldOut);
                        setSoldOutDates(soldOutDates);
                        setIsMarkProductSoldOutModalOpen(false);
                    }}
                    isMarkProductSoldOutModalOpen={
                        isMarkProductSoldOutModalOpen
                    }
                    storeTimeZone={timezone}
                />
            </Body>
        </ProductNewSharedContext.Provider>
    );
};

const Body = styled.div`
    width: 100%;
    overflow-x: "auto";
    margin-bottom: 10rem;
    position: relative;
    /* z-index: 5; */
    font-family: "Inter" !important;

    .new-product {
        position: relative;
    }

    input {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    @media ${ScreenState.MOBILE} {
        margin-bottom: 10rem;
    }

    .clickable-icon {
        cursor: pointer;
        margin-right: 8px;
    }

    .mt12 {
        margin-top: 12px;
    }

    .mb12 {
        margin-bottom: 12px;
    }

    .mt18 {
        margin-top: 18px;
    }

    .ml24 {
        margin-left: 24px;
    }

    .align-start {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .w100 {
        width: 100%;
    }

    .input-number {
        border-radius: 8px;
        width: 100%;
    }
    .tax::after {
        content: "%";
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 16px;
    }
    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-input-number-handler-wrap {
        display: none;
    }

    input {
        border-radius: 8px;
        width: 100%;
    }
    .f2 {
        flex: 2;
    }
    .f1 {
        flex: 1 !important;
    }

    .flexWrap {
        flex-wrap: wrap;
    }

    .choose-photo {
        align-self: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }

    .ant-form label {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #282d32;
        font-size: 16px;
    }

    .ant-row .ant-form-item {
        font-size: 16px;
    }

    .taxRateOverride {
        margin-top: 12px;
    }

    .ant-row.ant-form-item.taxRateOverride {
        margin-bottom: 6px;
    }

    .ant-input {
        border-radius: 8px;
        ::placeholder {
            font-size: 16px;
        }
    }

    .category-row {
        margin-bottom: 0;
    }

    .customize-item {
        margin-bottom: 0;
    }

    .customize-item-mobile {
        margin-bottom: 0;
    }

    .price {
        position: relative;
    }
    .price input {
        padding-left: 15px;
    }
    .prefix {
        top: 10px;
        left: 4px;
        position: absolute;
        color: #a0adba;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .save-btn {
        flex: auto;
        background: ${SystemColors.v1.candy50};
        color: ${SystemColors.v1.white};
        padding: 2px 14px;
        cursor: pointer;
        height: 39px;
        white-space: nowrap;
        display: inline-block;
        width: 375px;
        position: fixed;
        bottom: 10px;
        @media ${ScreenState.MOBILE} {
            bottom: 85px;
            width: 80%;
        }
    }

    .save-btn-bg {
        background-color: ${SystemColors.v2.salt10.light};
        position: sticky;
        position: -webkit-sticky;
        bottom: -30px;
        right: 0px;
        left: 0px;
        padding-top: 20px;
        padding-bottom: 40px;
        align-items: center;
        @media ${ScreenState.MOBILE} {
            bottom: 40px;
        }
    }

    .ant-input-number-input {
        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .ant-btn-primary {
        &:hover {
            color: ${SystemColors.v1.white} !important;
            background: ${SystemColors.v1.candy50} !important;
        }
    }
    .ant-row .ant-form-item {
        font-size: 16px;
    }
    .fulfillmentMethod > .ant-col.ant-form-item-label {
        display: none;
    }
    .fulfillmentTaxRate > .ant-col.ant-form-item-label label {
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .fulfillmentTaxRate > .ant-col.ant-form-item-label {
        padding-bottom: 5px;
    }
    .taxInfo {
        margin-top: 12px;
    }
    .ant-row.ant-form-item.fulfillmentTaxRate {
        margin: 0px;
    }
    .ant-row.ant-form-item.sales-tax {
        margin: 0px;
    }
    .sales-tax > .ant-col.ant-form-item-label {
        padding-bottom: 0px;
        padding-top: 0px;
    }
    .ant-row.ant-form-item.fulfillmentMethod {
        margin: 0px;
    }
    .fulfillmentTaxRateRow {
        justify-content: start;
    }
    .fulfillment {
        justify-content: start;
    }

    .add {
        font-size: 2rem;
        grid-column: 3;
        padding-left: 1.5rem;
        grid-row: 1;
        cursor: pointer;
    }
    .new-modifier-group {
        grid-column: 1;
        margin-left: 1rem;
        grid-row: 1;
        cursor: pointer;
    }
    .new-modifier-description {
        grid-column: 1/2;
        grid-row: 1;
        margin-left: 1rem;
        font-size: 16px;
        padding-top: 1.5rem;
        color: #606c76;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
    }
    .ingredients {
        margin-top: 1rem;
    }
`;

const Row = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const Label = styled.div`
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #282d32;
`;

const RowForSwitch = styled(Row)`
    justify-content: space-between;
`;

const Extra = styled.span`
    color: rgba(96, 108, 118, 1);
    font-family: Inter;
`;

const HeaderRow = styled.div`
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

const NewModifiers = styled.div`
    font-size: 16px;
    font-family: "Inter";
    display: grid;
    grid-template-columns: auto 1fr 60px;
    padding: 10px 10px;
    align-items: start;
    justify-content: center;
    cursor: pointer;
`;

export default ProductNew;
