import { Col, Form, Input, Row, Select } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Upload from "antd/lib/upload/Upload";
import { UploadFile } from "antd/lib/upload";
import { debounce } from "radash";

import api from "src/api/rest";
import CloseButton from "#payouts/components/shared/CloseButton";
import { StyledModal, Header } from "#payouts/components/shared/Modal";
import {
    Statuses,
    StoreVerificationAddress,
    VerificationsResponse
} from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { descriptions } from "#payouts/utils/descriptions";
import {} from "date-fns";
import "antd/dist/antd.css";
import { CompanySchema } from "#payouts/utils/validation/schema";
import { stateList } from "#payouts/utils/StateList";
import { appendToBody } from "#payouts/utils/appendToBody";
import {
    companyNameValidation,
    companyDbaValidation,
    companyTaxIdValidation,
    phoneValidation,
    line2Validation,
    cityValidation,
    stateValidation,
    countryValidation,
    postalCodeValidation,
    line1Validation,
    urlValidation
} from "#payouts/utils/validation/form";
import { Label, RowDivider } from "#payouts/components/shared/ModalComponents";
import { messages } from "#payouts/utils/messages";
import { notifyFailure } from "#payouts/utils/notify";
import { handleException } from "#payouts/utils/handleException";
import { filterOption, filterSort } from "#payouts/utils/filterOptions";
import { acceptFiles, fileSizeInMB } from "#payouts/utils/validation/files";
import Text from "#payouts/components/shared/Text";
import { ReactComponent as UploadButton } from "#payouts/assets/upload-button.svg";
import { UpdatedButton } from "#payouts/components/shared/UpdatedButton";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { ModalRequirement } from "#payouts/components/shared/ModalRequirement";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import ss4HelpPng1 from "src/assets/images/stripe-ss4-help-1.png";
import ss4HelpPng2 from "src/assets/images/stripe-ss4-help-2.png";
import one47CHelpPng1 from "src/assets/images/stripe-147c-help-1.png";
import one47CHelpPng2 from "src/assets/images/stripe-147c-help-2.png";

const corporateEndingsRegex = /LLC|Inc|Corp|Partnership/i;

export const CompanyModal = () => {
    const [form] = Form.useForm();

    const { isDesktop } = useWindowDimensions();

    const {
        storeId,
        verification,
        setVerification,
        companyModal,
        handleModalChange
    } = useContext(PayoutsSettingsContext);

    // Check if we're editing an existing company, or creating a new one
    const updateToExisting = !!verification?.name;

    const isVerified = verification?.status === Statuses.enabled;
    const namePending = verification?.name === "pending";
    const dbaPending = verification?.dba === "pending";
    const taxIdProvided = !!verification?.taxId;
    const addressPending = !!verification?.address?.pending;
    const phonePending = verification?.phone === "pending";
    const urlPending = verification?.url === "pending";

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(
        namePending ? "Pending" : verification?.name
    );
    const [dba, setDba] = useState(dbaPending ? "Pending" : verification?.dba);
    const [taxId, setTaxId] = useState(taxIdProvided ? "Provided" : undefined);
    const [front, setFront] = useState<UploadFile | undefined>(undefined);
    const [frontFileList, setFrontFileList] = useState<UploadFile[]>([]);
    const [ss4DialogOpen, setSS4DialogOpen] = useState(false);
    const [one47CDialogOpen, setOne47CDialogOpen] = useState(false);
    const [address, setAddress] = useState(
        addressPending
            ? {
                  line1: "Pending",
                  line2: "Pending",
                  city: "Pending",
                  state: "Pending",
                  postalCode: "Pending",
                  country: "US"
              }
            : {
                  line1: verification?.address?.line1,
                  line2: verification?.address?.line2,
                  city: verification?.address?.city,
                  state: verification?.address?.state,
                  postalCode: verification?.address?.postalCode,
                  country: verification?.address?.country ?? "US"
              }
    );
    const [phone, setPhone] = useState(
        phonePending ? "Pending" : verification?.phone?.replace("+1", "")
    );

    const [url, setUrl] = useState(
        urlPending
            ? "Pending"
            : verification?.url
              ? verification.url
              : `https://order.snackpass.co/$${storeId}`
    );
    const [filteredStates, setFilteredStates] = useState([...stateList]);

    const showTaxIdDocument = !!verification?.eventualRequirements?.find(
        (r) => r === "documents.company_tax_id_verification.files"
    );

    const errorInParse = !CompanySchema.safeParse({
        name,
        dba,
        taxId,
        phone,
        address,
        url
    }).success;

    const error = companyModal && errorInParse;

    const handleStateSearch = (search: string) => {
        setFilteredStates(
            search.length
                ? [...stateList].filter(
                      (c) =>
                          c.label
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                          c.value.toLowerCase().includes(search.toLowerCase())
                  )
                : [...stateList]
        );
    };

    const handleAddressChange = (update: Partial<StoreVerificationAddress>) => {
        setHasChange(true);
        setAddress({ ...address, ...update });
    };

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        handleModalChange();
    };

    const disabled = !storeId || loading;
    const isValid = hasChange && !error;

    const submitUpdate = async (formData: FormData): VerificationsResponse => {
        if (disabled || !isValid) {
            return Promise.reject();
        }
        setLoading(true);
        return api.verifications.update(storeId, formData);
    };

    const debouncedSubmit = debounce({ delay: 300 }, async (u: FormData) =>
        submitUpdate(u)
            .then((response) => {
                const { data } = response;
                if (data?.success) {
                    setVerification(data.verification);
                    setHasChange(false);
                    setLoading(false);
                    handleModalChange(
                        updateToExisting ? {} : { account: true }
                    );
                } else {
                    notifyFailure(messages.modal.company);
                    handleException(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyFailure({
                    message: messages.modal.company.message,
                    description:
                        error.cause?.response?.data?.error ??
                        messages.modal.company.description
                });
                handleException(error);
                setLoading(false);
            })
    );

    if (!companyModal) return <></>;

    return (
        <StyledModal
            open={companyModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center="Business Details"
                />
            }
            footer={
                <>
                    <UpdatedButton
                        block
                        smallRadius
                        variant="tertiary"
                        size="regular"
                        onClick={() => handleModalChange()}
                        disabled={disabled}
                        isValid={true} // cancel is always valid
                        children={<>Cancel</>}
                    />
                    <UpdatedButton
                        block
                        smallRadius
                        variant="primary"
                        size="regular"
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        children={<>{updateToExisting ? "Update" : "Submit"}</>}
                        onClick={async () => {
                            if (disabled) {
                                return;
                            }
                            if (!isValid) {
                                form.setFields([
                                    {
                                        name: "name",
                                        errors: companyNameValidation(
                                            name,
                                            namePending
                                        )
                                    },
                                    {
                                        name: "dba",
                                        errors: companyDbaValidation(
                                            dba,
                                            dbaPending
                                        )
                                    },
                                    {
                                        name: "taxId",
                                        errors: companyTaxIdValidation(
                                            taxId,
                                            taxIdProvided
                                        )
                                    },
                                    {
                                        name: "phone",
                                        errors: phoneValidation(
                                            phone,
                                            phonePending
                                        )
                                    },
                                    {
                                        name: "url",
                                        errors: urlValidation(url, urlPending)
                                    },
                                    {
                                        name: "line1",
                                        errors: line1Validation(
                                            address.line1,
                                            addressPending
                                        )
                                    },
                                    {
                                        name: "line2",
                                        errors: line2Validation(
                                            address.line2,
                                            addressPending
                                        )
                                    },
                                    {
                                        name: "city",
                                        errors: cityValidation(
                                            address.city,
                                            addressPending
                                        )
                                    },
                                    {
                                        name: "state",
                                        errors: stateValidation(
                                            address.state,
                                            addressPending
                                        )
                                    },
                                    {
                                        name: "country",
                                        errors: countryValidation(
                                            address.country,
                                            addressPending
                                        )
                                    },
                                    {
                                        name: "postalCode",
                                        errors: postalCodeValidation(
                                            address.postalCode,
                                            addressPending
                                        )
                                    }
                                ]);
                                return;
                            }

                            const body = new FormData();
                            appendToBody(body, "name", name?.trim());

                            // DBA Validation unique because it can be unset (Optional field)
                            if (dba?.length || dba === "") {
                                body.append("dba", dba.trim());
                            }

                            appendToBody(body, "taxId", taxId?.trim());
                            appendToBody(
                                body,
                                "address",
                                JSON.stringify({
                                    line1: address.line1?.trim(),
                                    line2: address.line2?.trim(),
                                    city: address.city?.trim(),
                                    state: address.state?.trim(),
                                    postalCode: address.postalCode?.trim(),
                                    country: address.country.trim()
                                })
                            );

                            if (front) {
                                appendToBody(
                                    body,
                                    "document",
                                    JSON.stringify({
                                        name: "company_tax_id_verification",
                                        purpose: "account_requirement",
                                        filename: front.name.trim(),
                                        mimeType: front.type,
                                        type: "company"
                                    })
                                );
                                appendToBody(body, "frontImage", front);
                            }

                            appendToBody(
                                body,
                                "phone",
                                ["+1", phone?.trim()].join("")
                            );
                            appendToBody(body, "url", url);

                            void debouncedSubmit(body);
                        }}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name,
                    dba,
                    taxId,
                    phone,
                    line1: address.line1,
                    line2: address.line2,
                    city: address.city,
                    state: address.state,
                    postalCode: address.postalCode,
                    country: address.country,
                    url
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={
                                <Label
                                    required
                                    label={"Company Name"}
                                    subtitle={
                                        isVerified
                                            ? descriptions.nameProvided
                                            : descriptions.name
                                    }
                                />
                            }
                        >
                            <Input
                                className="mb-4"
                                disabled={isVerified || namePending}
                                placeholder="Company name"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = companyNameValidation(value);
                                    setHasChange(true);
                                    setName(value);
                                    form.setFields([
                                        {
                                            name: "name",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                        <span
                            className="mr-4 text-blue-600 hover:cursor-pointer hover:underline"
                            onClick={() => {
                                setSS4DialogOpen(!ss4DialogOpen);
                            }}
                        >
                            SS-4 Example
                        </span>
                        <span
                            className="text-blue-600 hover:cursor-pointer hover:underline"
                            onClick={() => {
                                setOne47CDialogOpen(!one47CDialogOpen);
                            }}
                        >
                            147C Example
                        </span>
                    </Col>
                    {!isVerified && (
                        <ModalRequirement
                            good={corporateEndingsRegex.test(name ?? "")}
                            message={descriptions.nameClarifier}
                        />
                    )}
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="dba"
                            label={
                                <Label
                                    label={"Doing Business As"}
                                    subtitle={descriptions.dba}
                                />
                            }
                        >
                            <Input
                                disabled={dbaPending}
                                placeholder="DBA"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = companyDbaValidation(value);
                                    setHasChange(true);
                                    setDba(value);
                                    form.setFields([
                                        {
                                            name: "dba",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="taxId"
                            label={
                                <Label
                                    required
                                    label={
                                        isDesktop
                                            ? "Federal Employer Identification Number (F-EIN)"
                                            : "Employer Identification Number (EIN)"
                                    }
                                    subtitle={
                                        taxIdProvided
                                            ? descriptions.taxIdProvided
                                            : descriptions.taxId
                                    }
                                    note={
                                        taxIdProvided
                                            ? ""
                                            : descriptions.socialOption
                                    }
                                />
                            }
                        >
                            <Input
                                disabled={isVerified || taxIdProvided}
                                placeholder="XX-XXXXXXX"
                                onChange={async (e) => {
                                    if (!verification?.taxId) {
                                        const value = e.target.value;
                                        const errors =
                                            companyTaxIdValidation(value);
                                        setHasChange(true);
                                        setTaxId(value);
                                        form.setFields([
                                            {
                                                name: "taxId",
                                                value,
                                                errors
                                            }
                                        ]);
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showTaxIdDocument && (
                    <>
                        <RowDivider />
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="verification"
                                    label={
                                        <Label
                                            required
                                            label={
                                                "Tax ID Verification Documents"
                                            }
                                            subtitle={
                                                descriptions.taxIdDocument
                                            }
                                        />
                                    }
                                >
                                    <Upload
                                        beforeUpload={() => false}
                                        openFileDialogOnClick={
                                            !frontFileList.length
                                        }
                                        accept={acceptFiles}
                                        fileList={frontFileList}
                                        onChange={({ file, fileList }) => {
                                            if (
                                                file.size &&
                                                file.size > fileSizeInMB
                                            ) {
                                                return notifyFailure(
                                                    messages.modal.fileSize
                                                );
                                            }

                                            if (
                                                !fileList.length ||
                                                fileList.length === 1
                                            ) {
                                                setHasChange(true);
                                                setFront(file);
                                                setFrontFileList([...fileList]);
                                            }
                                        }}
                                    >
                                        <FileText>
                                            <Text.SmallLabel>
                                                Front:
                                            </Text.SmallLabel>
                                        </FileText>
                                        {frontFileList.length ? null : (
                                            <UploadButton />
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            className="compact"
                            name="country"
                            label={
                                <Label
                                    required
                                    label="Registered Business Address"
                                    subtitle={descriptions.address}
                                />
                            }
                        >
                            <Select
                                disabled
                                placeholder="Country"
                                className="full"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = countryValidation(value);
                                    setHasChange(true);
                                    handleAddressChange({ country: value });
                                    form.setFields([
                                        {
                                            name: "country",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            >
                                <Select.Option value="US">
                                    {"United States"}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="line1" className="compact">
                            <Input
                                disabled={addressPending}
                                placeholder="Address line 1"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = line1Validation(value);
                                    setHasChange(true);
                                    handleAddressChange({ line1: value });
                                    form.setFields([
                                        { name: "line1", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="line2" className="compact">
                            <Input
                                disabled={addressPending}
                                placeholder="Address line 2 (optional)"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = line2Validation(value);
                                    setHasChange(true);
                                    handleAddressChange({ line2: value });
                                    form.setFields([
                                        { name: "line2", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item className="compact">
                            <Row>
                                <Col span={isDesktop ? 12 : 10}>
                                    <Form.Item name="city">
                                        <Input
                                            disabled={addressPending}
                                            placeholder="City"
                                            className="left"
                                            onChange={async (e) => {
                                                const value = e.target.value;
                                                const errors =
                                                    cityValidation(value);
                                                setHasChange(true);
                                                handleAddressChange({
                                                    city: value
                                                });
                                                form.setFields([
                                                    {
                                                        name: "city",
                                                        value,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={isDesktop ? 4 : 6}>
                                    <Form.Item name="state">
                                        <Select
                                            disabled={addressPending}
                                            placeholder="State"
                                            className="center"
                                            onChange={async (state) => {
                                                const errors =
                                                    stateValidation(state);
                                                setHasChange(true);
                                                handleAddressChange({ state });
                                                form.setFields([
                                                    {
                                                        name: "state",
                                                        value: state,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                            showSearch
                                            onSearch={handleStateSearch}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                filterOption(
                                                    input,
                                                    option?.children?.toString()
                                                )
                                            }
                                            filterSort={(a, b) =>
                                                filterSort(
                                                    a?.children?.toString(),
                                                    b?.children?.toString()
                                                )
                                            }
                                        >
                                            {filteredStates.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="postalCode">
                                        <Input
                                            disabled={addressPending}
                                            placeholder="Postal Code"
                                            className="right"
                                            onChange={async (e) => {
                                                const value = e.target.value;
                                                const errors =
                                                    postalCodeValidation(value);
                                                setHasChange(true);
                                                handleAddressChange({
                                                    postalCode: value
                                                });
                                                form.setFields([
                                                    {
                                                        name: "postalCode",
                                                        value,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="phone"
                            label={
                                <Label
                                    required
                                    label="Business Phone Number"
                                    subtitle={descriptions.phone}
                                />
                            }
                        >
                            <Input
                                disabled={phonePending}
                                addonBefore="+1"
                                placeholder="Company phone"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = phoneValidation(value);
                                    setHasChange(true);
                                    setPhone(value);
                                    form.setFields([
                                        {
                                            name: "phone",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="url"
                            label={
                                <Label
                                    required
                                    label="Business Website"
                                    subtitle={descriptions.url}
                                />
                            }
                        >
                            <Input
                                disabled={urlPending}
                                placeholder="Business Website"
                                onChange={async (e) => {
                                    const value = e.target.value;
                                    const errors = urlValidation(value);
                                    setHasChange(true);
                                    setUrl(value);
                                    form.setFields([
                                        {
                                            name: "url",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Dialog open={ss4DialogOpen} onOpenChange={setSS4DialogOpen}>
                <DialogContent
                    className="z-[1002] my-4 max-h-screen w-full overflow-y-scroll"
                    overlayClassName="z-[1000]"
                >
                    <DialogHeader>
                        <DialogTitle>SS-4 Examples</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                        <div>
                            <div>
                                <img src={ss4HelpPng1} className="w-full" />
                            </div>
                            <hr />
                            <div>
                                If your entity name looks like the above on your
                                SS-4 confirmation letter, you might need to
                                enter the following as your legal entity name:
                            </div>
                            <div className="font-mono">
                                ACME LLC THERESA LOPEZ-FITZGERALD SOLE MBR
                            </div>
                            <hr />
                            <div>
                                <img src={ss4HelpPng2} className="w-full" />
                            </div>
                            <hr />
                            <div>
                                If your entity name looks like this on your SS-4
                                confirmation letter, you might only need to
                                enter the following as your legal entity name:
                            </div>
                            <div className="font-mono">ACME INC</div>
                        </div>
                    </DialogDescription>
                </DialogContent>
            </Dialog>
            <Dialog open={one47CDialogOpen} onOpenChange={setOne47CDialogOpen}>
                <DialogContent
                    className="z-[1002] my-4 max-h-screen w-full overflow-y-scroll"
                    overlayClassName="z-[1000]"
                >
                    <DialogHeader>
                        <DialogTitle>Letter 147C Examples</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                        <div>
                            <div>
                                <img src={one47CHelpPng1} className="w-full" />
                            </div>
                            <hr />
                            <div>
                                If your entity name looks like the above on your
                                Letter 147C, you might only need to enter the
                                following as your legal entity name:
                            </div>
                            <div className="font-mono">ACME INC</div>
                            <hr />
                            <div>
                                <img src={one47CHelpPng2} className="w-full" />
                            </div>
                            <hr />
                            <div>
                                If your entity name looks like this on your
                                Letter 147C, you might need to enter the
                                following as your legal entity name:
                            </div>
                            <div className="font-mono">
                                ACME LLC THERESA LOPEZ-FITZGERALD MBR % PILAR
                                LOPEZ
                            </div>
                        </div>
                    </DialogDescription>
                </DialogContent>
            </Dialog>
        </StyledModal>
    );
};

const FileText = styled.span`
    margin-right: ${({ theme }) => theme.spacing.double};
`;
