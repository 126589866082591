import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    FulfillmentTypeEnum,
    KioskCustomerIdentifierEnum,
    READABLE_THIRD_PARTY_MAPPING,
    ThirdPartySource,
    KioskMenuDisplayType
} from "@snackpass/snackpass-types";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
import { getActiveStore } from "@snackpass/accounting";
import { TrashIcon, PlusCircledIcon, ReloadIcon } from "@radix-ui/react-icons";

import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { BLACKLISTED_REWARD_MAX_PHONE_NUMBERS } from "#settings/settings-platforms/helper";
import { Routes } from "#navigation/routes";
import { PhoneInput } from "src/@/components/ui/phone-input";
import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import { Textarea } from "src/@/components/ui/textarea";
import { Switch } from "src/@/components/ui/switch";
import { Checkbox } from "src/@/components/ui/checkbox";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices/activeStore";
import { sendError } from "src/utils/errors";
import { useBlacklistedPhoneKioskRewardsFieldEnabled } from "#navigation/utils";

const sources: string[] = Object.values(ThirdPartySource) as string[];
const identifierTypes: string[] = Object.values(
    KioskCustomerIdentifierEnum
) as string[];
const menuTypes: string[] = Object.values(KioskMenuDisplayType) as string[];
type HandleFulfillmentChangeType = (
    field: {
        value: FulfillmentTypeEnum[];
        onChange: (value: FulfillmentTypeEnum[]) => void;
    },
    fulfillment: FulfillmentTypeEnum
) => void;
const menuDisplayTypeOptions = {
    [KioskMenuDisplayType.CLASSIC]: {
        label: "Classic",
        value: KioskMenuDisplayType.CLASSIC
    },
    [KioskMenuDisplayType.GALLERY]: {
        label: "Gallery",
        value: KioskMenuDisplayType.GALLERY
    }
};

const dineInSchema = z.object({
    hasDineIn: z.boolean(),
    requireTableNumber: z.boolean()
});

const kioskSchema = z
    .object({
        customerIdentifierType: z.enum(
            identifierTypes as unknown as readonly [string, ...string[]]
        ),
        fulfillmentTypes: z
            .enum([
                FulfillmentTypeEnum.Delivery,
                FulfillmentTypeEnum.DineIn,
                FulfillmentTypeEnum.Pickup
            ])
            .array(),
        customWayfindingMessage: z.string(),
        disablePopularSection: z.boolean(),
        rewardsEnabled: z.boolean(),
        blacklistedRewardPhoneNumbers: z.array(z.string()),
        menuDisplayType: z.enum(
            menuTypes as unknown as readonly [string, ...string[]]
        )
    })
    .refine(
        (data) =>
            data.blacklistedRewardPhoneNumbers.length === 0 ||
            data.blacklistedRewardPhoneNumbers.every((phoneNum) => {
                try {
                    return isPossiblePhoneNumber(phoneNum);
                } catch (err) {
                    sendError(err);
                    return false;
                }
            }),
        {
            message: "One or more phone numbers invalid",
            path: ["blacklistedRewardPhoneNumbers"]
        }
    );

const platformSchema = z
    .object({
        pickup: z.boolean(),
        pickupMin: z.number().optional(),
        hasPickupNow: z.boolean(),
        hasScheduledOrders: z.boolean(),
        scheduleAheadInterval: z.number().optional(),
        scheduledOrderMinLeadTime: z.number().optional(),
        pickupDirections: z.string(),
        hasBatching: z.boolean(),
        scheduleAheadBatchSize: z.number().optional(),
        dineInPreferences: dineInSchema,
        //delivery
        delivery: z.boolean(),
        deliveryMin: z.number().optional(),
        hasSpecialDeliveryHours: z.boolean(),
        //online ordering:
        onlineOrderingEnabled: z.boolean(),
        // KIOSK AND REGISTER
        kioskPreferences: kioskSchema,
        hasKiosk: z.boolean(),
        registerRewardsEnabled: z.boolean(),
        manualThirdPartySources: z.array(
            z.enum(sources as unknown as readonly [string, ...string[]])
        )
    })
    .refine(
        (data) =>
            data.kioskPreferences.fulfillmentTypes.includes(
                FulfillmentTypeEnum.DineIn
            ) ||
            data.kioskPreferences.fulfillmentTypes.includes(
                FulfillmentTypeEnum.Pickup
            ),
        {
            message:
                "At least one fulfillment type must be chosen, dine-in or pickup",
            path: ["kioskPreferences.fulfillmentTypes"]
        }
    );

const platformFromInitialValue = {
    // APP & ONLINE
    pickup: true,
    pickupMin: null,
    hasPickupNow: false,
    hasScheduledOrders: false,
    scheduleAheadInterval: 15,
    scheduledOrderMinLeadTime: 15,
    pickupDirections: "",
    hasBatching: false,
    scheduleAheadBatchSize: 10,
    //dine-in
    dineInPreferences: {
        hasDineIn: false,
        requireTableNumber: false
    },
    //delivery
    delivery: false,
    deliveryMin: 0,
    hasSpecialDeliveryHours: true,
    //online ordering:
    onlineOrderingEnabled: false,
    // KIOSK AND REGISTER
    hasKiosk: false,
    registerRewardsEnabled: true,
    kioskPreferences: {
        customerIdentifierType: "ORDER_NUMBER",
        customWayfindingMessage: "",
        disablePopularSection: false,
        fulfillmentTypes: [],
        blacklistedRewardPhoneNumbers: [],
        rewardsEnabled: false,
        menuDisplayType: KioskMenuDisplayType.GALLERY
    },
    manualThirdPartySources: []
};

const SUPPORTED_3P_SOURCES = Object.values(ThirdPartySource).filter(
    (p) => p !== ThirdPartySource.SNACKPASS && p !== ThirdPartySource.DELIVERECT
);

const SettingsPlatforms = () => {
    const [isLoading, setIsLoading] = useState(false);
    const canEdit = useHasEditSettingsForActiveStore();
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();
    const isBlacklistedPhoneKioskRewardsFieldEnabled =
        useBlacklistedPhoneKioskRewardsFieldEnabled();

    const form = useForm<z.infer<typeof platformSchema>>({
        resolver: zodResolver(platformSchema),
        // @ts-ignore
        defaultValues: platformFromInitialValue
    });

    const valuesFromActiveStore: z.infer<typeof platformSchema> = useMemo(
        () => ({
            //pickup
            pickup: activeStore?.pickup ?? true,
            pickupMin: activeStore?.pickupMin ?? undefined,
            hasPickupNow: !!activeStore?.hasPickupNow,
            hasScheduledOrders: !!activeStore?.hasScheduledOrders,
            scheduleAheadInterval: activeStore?.scheduleAheadInterval,
            scheduledOrderMinLeadTime: activeStore?.scheduledOrderMinLeadTime,
            pickupDirections: activeStore?.pickupDirections
                ? activeStore?.pickupDirections
                : "",
            hasBatching: activeStore?.hasBatching ? true : false,
            scheduleAheadBatchSize: activeStore?.scheduleAheadBatchSize,

            //dine-in
            dineInPreferences: {
                hasDineIn: activeStore?.dineInPreferences.hasDineIn
                    ? true
                    : false,
                requireTableNumber: activeStore?.dineInPreferences
                    .requireTableNumber
                    ? true
                    : false
            },
            //delivery
            delivery: !!activeStore?.delivery,
            deliveryMin: activeStore?.deliveryMin,
            hasSpecialDeliveryHours: !!activeStore?.hasSpecialDeliveryHours,
            onlineOrderingEnabled: !!activeStore?.onlineOrderingEnabled,

            //KIOSK AND REGISTER
            kioskPreferences: {
                customerIdentifierType:
                    activeStore?.kioskPreferences.customerIdentifierType ??
                    KioskCustomerIdentifierEnum.orderNumber,

                customWayfindingMessage: activeStore?.kioskPreferences
                    .customWayfindingMessage
                    ? activeStore?.kioskPreferences.customWayfindingMessage
                    : "",
                disablePopularSection:
                    !!activeStore?.kioskPreferences.disablePopularSection,
                fulfillmentTypes: activeStore?.kioskPreferences
                    ?.fulfillmentTypes
                    ? (activeStore?.kioskPreferences
                          .fulfillmentTypes as FulfillmentTypeEnum[])
                    : [],
                blacklistedRewardPhoneNumbers: activeStore?.kioskPreferences
                    .blacklistedRewardPhoneNumbers
                    ? activeStore?.kioskPreferences
                          .blacklistedRewardPhoneNumbers
                    : [],
                rewardsEnabled: !!activeStore?.kioskPreferences.rewardsEnabled,
                menuDisplayType:
                    activeStore?.kioskPreferences.menuDisplayType ??
                    KioskMenuDisplayType.CLASSIC
            },
            hasKiosk: !!activeStore?.hasKiosk,
            registerRewardsEnabled: !!activeStore?.registerRewardsEnabled,
            manualThirdPartySources: activeStore?.manualThirdPartySources || []
        }),
        [activeStore]
    );

    const resetForm = useCallback(() => {
        form.reset(valuesFromActiveStore);
    }, [form, valuesFromActiveStore]);

    // resets form every time new session store is selected or session store is updated
    useEffect(() => {
        if (activeStore) {
            resetForm();
        }
    }, [activeStore, form, resetForm, valuesFromActiveStore]);

    const onSubmit = useCallback(
        async (values: z.infer<typeof platformSchema>) => {
            if (!activeStore || !canEdit) return;
            try {
                setIsLoading(true);
                const {
                    blacklistedRewardPhoneNumbers,
                    ...restKioskPreferences
                } = values.kioskPreferences;
                const { data } = await api.stores.update(activeStore._id, {
                    ...values,
                    dineInPreferences: {
                        ...activeStore.dineInPreferences,
                        ...values.dineInPreferences
                    },
                    kioskPreferences: {
                        ...activeStore.kioskPreferences,
                        ...restKioskPreferences,
                        ...(isBlacklistedPhoneKioskRewardsFieldEnabled && {
                            blacklistedRewardPhoneNumbers
                        }),
                        customerIdentifierType: values.dineInPreferences
                            .requireTableNumber
                            ? KioskCustomerIdentifierEnum.tableNumber
                            : KioskCustomerIdentifierEnum.orderNumber
                    }
                });

                dispatch(setActiveStore(data.store));
                toast.success("Store updated successfully");
            } catch (e) {
                sendError(e);
                toast.error("Can not update the store, please try again later");
            } finally {
                setIsLoading(false);
            }
        },
        [
            activeStore,
            canEdit,
            isBlacklistedPhoneKioskRewardsFieldEnabled,
            dispatch
        ]
    );

    const handleThirdParty = (
        checked: boolean,
        value: string[],
        onChange: (val: string[]) => void,
        item: string
    ) => {
        const currentThirdPartySources = checked
            ? [...value, item]
            : value.filter((source: string) => source !== item);
        onChange(currentThirdPartySources);
    };

    const updateNumber = (
        value: string[],
        onChange: (val: string[]) => void,
        index: number,
        number: string
    ) => {
        const newNumbers = value;
        newNumbers[index] = number;
        onChange(newNumbers);
    };

    const deleteNumber = (
        value: string[],
        onChange: (val: string[]) => void,
        index: number
    ) => {
        onChange(value?.filter((_, idx) => idx !== index));
    };

    const addNumber = (value: string[], onChange: (val: string[]) => void) => {
        onChange([...(value ?? []), ""]);
    };

    const handleFulfillmentChange: HandleFulfillmentChangeType = (
        field,
        fulfillment
    ) => {
        field.value.findIndex((v) => v === fulfillment) !== -1
            ? field.onChange(
                  field.value.filter(
                      (v: FulfillmentTypeEnum) => v !== fulfillment
                  )
              )
            : field.onChange([...field.value, fulfillment]);
    };

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            In-store orders
                        </CardTitle>
                        <div className="text-gray-600">
                            Configure your in-store ordering experience for
                            kiosk and register orders.
                        </div>
                        <hr className="border-gray-300" />
                    </div>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Pickup</CardTitle>
                            <CardDescription>
                                Configure your pickup order settings.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="kioskPreferences.fulfillmentTypes"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Pickup
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Allow customers to pickup
                                                    from store.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value.includes(
                                                            FulfillmentTypeEnum.Pickup
                                                        )}
                                                        onCheckedChange={() => {
                                                            handleFulfillmentChange(
                                                                field,
                                                                FulfillmentTypeEnum.Pickup
                                                            );
                                                        }}
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="kioskPreferences.customWayfindingMessage"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Directions
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Direct customers to where they
                                                can find their order.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Textarea
                                                        className="resize-none outline-none [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden"
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="Example: Your boba will be waiting in the fridge!🧋"
                                                        rows={3}
                                                        maxLength={275}
                                                        disabled={
                                                            !form
                                                                .watch(
                                                                    "kioskPreferences.fulfillmentTypes"
                                                                )
                                                                .includes(
                                                                    FulfillmentTypeEnum.Pickup
                                                                ) || !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Dine-In
                            </CardTitle>
                            <CardDescription>
                                Configure your dine-in settings.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="kioskPreferences.fulfillmentTypes"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Dine-in
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Reminder: go to the
                                                    <Link
                                                        to={Routes.MenuEditor}
                                                        className="px-1 no-underline"
                                                    >
                                                        Menu tab
                                                    </Link>
                                                    to select dine-in on your
                                                    desired dine-in menu items.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value.includes(
                                                            FulfillmentTypeEnum.DineIn
                                                        )}
                                                        onCheckedChange={() => {
                                                            handleFulfillmentChange(
                                                                field,
                                                                FulfillmentTypeEnum.DineIn
                                                            );
                                                        }}
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="dineInPreferences.requireTableNumber"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Require Table Number
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Prompt the customer for
                                                    table number during
                                                    checkout.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            !form
                                                                .watch(
                                                                    "kioskPreferences.fulfillmentTypes"
                                                                )
                                                                .includes(
                                                                    FulfillmentTypeEnum.DineIn
                                                                ) || !canEdit
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Rewards
                            </CardTitle>
                            <CardDescription>
                                Manage your store's rewards system.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="kioskPreferences.rewardsEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Show Rewards Banner on Kiosk
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Show a banner on the menu
                                                    screen for your rewards and
                                                    let people login to see
                                                    their points.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="registerRewardsEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Register Rewards
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Allow customers to redeem
                                                    rewards on the register.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            {(form.watch("registerRewardsEnabled") ||
                                form.watch(
                                    "kioskPreferences.rewardsEnabled"
                                )) && (
                                <>
                                    <hr />
                                    <FormField
                                        control={form.control}
                                        name="kioskPreferences.blacklistedRewardPhoneNumbers"
                                        render={({
                                            field: { value, onChange }
                                        }) => (
                                            <FormItem>
                                                <div>
                                                    <CardTitle className="text-base font-medium">
                                                        Phone Number Blocklist
                                                    </CardTitle>
                                                    <CardDescription className="pt-0.5">
                                                        Exclude specific phone
                                                        numbers from your
                                                        store's rewards program.
                                                        For example, a phone
                                                        number given to
                                                        customers who don't want
                                                        to provide their own.
                                                    </CardDescription>

                                                    <FormControl>
                                                        <div className="pt-4">
                                                            {value?.length > 0
                                                                ? value.map(
                                                                      (
                                                                          phoneNumber,
                                                                          index
                                                                      ) => (
                                                                          <div
                                                                              key={
                                                                                  index
                                                                              }
                                                                              className="flex items-center space-x-2 pt-2"
                                                                          >
                                                                              <PhoneInput
                                                                                  value={
                                                                                      phoneNumber
                                                                                  }
                                                                                  onChange={(
                                                                                      number
                                                                                  ) => {
                                                                                      updateNumber(
                                                                                          value,
                                                                                          onChange,
                                                                                          index,
                                                                                          number
                                                                                      );
                                                                                  }}
                                                                                  placeholder="Enter number..."
                                                                                  disabled={
                                                                                      !canEdit
                                                                                  }
                                                                                  defaultCountry="US"
                                                                              />

                                                                              <Button
                                                                                  type="button"
                                                                                  variant="outline"
                                                                                  onClick={() => {
                                                                                      deleteNumber(
                                                                                          value,
                                                                                          onChange,
                                                                                          index
                                                                                      );
                                                                                  }}
                                                                                  disabled={
                                                                                      !canEdit ||
                                                                                      !isBlacklistedPhoneKioskRewardsFieldEnabled
                                                                                  }
                                                                              >
                                                                                  <TrashIcon className="mr-1 h-4 w-4" />{" "}
                                                                                  Delete
                                                                              </Button>
                                                                          </div>
                                                                      )
                                                                  )
                                                                : null}

                                                            {value?.length <
                                                                BLACKLISTED_REWARD_MAX_PHONE_NUMBERS && (
                                                                <div className="flex justify-between pt-4">
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            addNumber(
                                                                                value,
                                                                                onChange
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            !canEdit
                                                                        }
                                                                    >
                                                                        <PlusCircledIcon className="mr-1 h-4 w-4" />{" "}
                                                                        Add
                                                                        Number
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Kiosk Only
                            </CardTitle>
                            <CardDescription>
                                Visit
                                <Link
                                    style={{ marginRight: 4, marginLeft: 4 }}
                                    to={Routes.Devices}
                                    className="no-underline"
                                >
                                    {"Devices -> Kiosks"}
                                </Link>
                                to manage device-specific settings.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="kioskPreferences.disablePopularSection"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Popular Category
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Show the 5 most frequently
                                                    ordered items in a section
                                                    at the top of your menu.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={!field.value}
                                                        onCheckedChange={(
                                                            checked
                                                        ) => {
                                                            field.onChange(
                                                                !checked
                                                            );
                                                        }}
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="kioskPreferences.menuDisplayType"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Menu Face
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Give your menu a different look
                                                by selecting a menu face that
                                                matches your products.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Select
                                                        value={field.value}
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    >
                                                        <SelectTrigger className="w-[180px]">
                                                            <SelectValue placeholder="Select Menu" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {Object.values(
                                                                menuDisplayTypeOptions
                                                            ).map((option) => (
                                                                <SelectItem
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                >
                                                                    {
                                                                        option.label
                                                                    }
                                                                </SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Register Only
                            </CardTitle>
                            <CardDescription>
                                Manually enter third-party orders on the
                                register.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="manualThirdPartySources"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Available Providers
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Choose available third-party
                                                providers.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <div className="mt-4 grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
                                                        {SUPPORTED_3P_SOURCES.map(
                                                            (
                                                                thirdPartySource
                                                            ) => (
                                                                <div
                                                                    key={
                                                                        thirdPartySource
                                                                    }
                                                                >
                                                                    <div className="flex items-center space-x-2">
                                                                        <Checkbox
                                                                            value={
                                                                                thirdPartySource
                                                                            }
                                                                            checked={value.includes(
                                                                                thirdPartySource
                                                                            )}
                                                                            onCheckedChange={(
                                                                                checked: boolean
                                                                            ) => {
                                                                                handleThirdParty(
                                                                                    checked,
                                                                                    value,
                                                                                    onChange,
                                                                                    thirdPartySource
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                !canEdit
                                                                            }
                                                                        />
                                                                        <label
                                                                            htmlFor="terms2"
                                                                            className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                                                        >
                                                                            {
                                                                                READABLE_THIRD_PARTY_MAPPING[
                                                                                    thirdPartySource
                                                                                ]
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};
export default SettingsPlatforms;
