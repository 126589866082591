import {
    IStore,
    CashDrawerWithPrinter,
    PrepStation,
    Printer,
    PrinterFormat,
    PrinterUpdateAction
} from "@snackpass/snackpass-types";

export type ParsedDeviceEvent = {
    id: string;
    title: string;
    date: Date;
    user: string;
    status: string;
};

export type PrinterEvent = {
    serial: string;
    name: string;
    format: string;
    store: IStore;
    printer: string;
    status: string;
    asbStatus: string;
    lastPing: Date;
    action: PrinterUpdateAction;
    createdAt: Date;
};

export type PrinterDeviceDetails = Printer & {
    recentPrints: number;
    connectionHistory: PrinterEvent[];
    cashDrawer: string | null;
    prepStationDetails: PrepStation | null;
    cashDrawerDetails: CashDrawerWithPrinter | null;
};

export const printerFormatNames = {
    [PrinterFormat.LABEL_IMAGE]: "Condensed Labels",
    [PrinterFormat.LABEL_V2]: "Full Labels",
    [PrinterFormat.RECEIPT]: "Condensed Receipt",
    [PrinterFormat.CHIT]: "Kitchen Ticket",
    [PrinterFormat.CUSTOMER]: "Full Receipt"
};

export const printerEventActions = {
    [PrinterUpdateAction.CREATED]: "Created",
    [PrinterUpdateAction.DEPRECATED]: "Deprecated",
    [PrinterUpdateAction.CLONED]: "Cloned",
    [PrinterUpdateAction.REGISTERED]: "Registered",
    [PrinterUpdateAction.DISCONNECTED]: "Disconnected",
    [PrinterUpdateAction.FLAKY]: "Flaky",
    [PrinterUpdateAction.CONNECTED]: "Connected"
};

export const PREP_STATION_FORMATS: PrinterFormat[] = [
    PrinterFormat.CHIT,
    PrinterFormat.LABEL_IMAGE,
    PrinterFormat.LABEL_V2
];
