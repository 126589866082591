import _ from "lodash/fp";
import { ChannelMetadataObject, ObjectCustom } from "pubnub";
import { ChatChannelMetadata } from "@snackpass/conversations.types";

export const filterByChannelName = <T extends ObjectCustom>(
    data: ChannelMetadataObject<T>[],
    query: string
): ChannelMetadataObject<T>[] =>
    data.filter((datum) =>
        _.includes(query.toLocaleLowerCase(), datum.name?.toLocaleLowerCase())
    );
/**
 * PubNub timetokens are 17-digit precision time stamps, whereas standard Unix
 * timestamps are in seconds (10-digit precision). To convert, we divide by
 * 10^7, discarding the extra precision -- this means that comparisons will
 * be precise to the second, which is good enough for me.
 *
 * NOTE: The particular reason we do this is that JS comparisons of large
 * numbers doesn't work too well since they get rounded (without using BigInt).
 * e.g. (node REPL):
 *  > TT = 16770894887068217
 *  > TT - (TT - 1)
 *  0
 *  > TT > (TT - 1)
 *  false
 */
const timetokenToUnix = (timetoken: number) => timetoken / 10_000_000;

export const isUnread = ({
    custom
}: ChannelMetadataObject<ChatChannelMetadata>): boolean =>
    timetokenToUnix(custom?.userLastChatTT ?? 0) >
    timetokenToUnix(custom?.storeLastReadTT ?? 0);
