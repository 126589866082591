import React, { Fragment } from "react";
import styled from "styled-components";

import Text from "#devices/components/Text";
import { copyAndNotify } from "#utils/helpers";

type ItemsType = {
    term: string;
    description?: string;
    icon: React.ReactNode;
    copyable?: boolean;
};

type DescriptionListProps = {
    items: ItemsType[];
};

const StyledDt = styled.dt`
    margin-bottom: ${({ theme }) => theme.spacing.quarter};
`;

// overwrite Text.Label font-weight to match design better
const StyledDd = styled.dd`
    margin-bottom: ${({ theme }) => theme.spacing.half};
    & span {
        font-weight: 400;
    }
`;

const StyledTextLabel = styled(Text.Label)<{ copyable?: boolean }>`
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.5s ease;
    &:hover {
        ${({ copyable }) =>
            copyable ? "background-color: rgba(0, 0, 0, 0.10)" : ""}
    }
    cursor: ${({ copyable }) => (copyable ? "pointer" : "auto")};
`;

const DescriptionList = ({
    items
}: DescriptionListProps): JSX.Element | null => {
    const handleClick = (value?: string, field?: string) => {
        if (value && field) copyAndNotify({ value, field });
    };

    if (!items || items.length === 0) {
        return null;
    }
    return (
        <dl>
            {items.map((item, idx) => (
                <Fragment key={idx}>
                    <StyledDt>
                        <Text.Small>{item.term}</Text.Small>
                    </StyledDt>
                    <StyledDd>
                        {item.description ===
                        "only available under Kitchen Ticket format" ? (
                            <span className="text-sm italic text-gray-500">
                                {item.description}
                            </span>
                        ) : (
                            <StyledTextLabel
                                copyable={item.copyable}
                                onClick={() =>
                                    item.copyable
                                        ? handleClick(
                                              item.description,
                                              item.term
                                          )
                                        : null
                                }
                            >
                                {item.icon} {item.description || "--"}
                            </StyledTextLabel>
                        )}
                    </StyledDd>
                </Fragment>
            ))}
        </dl>
    );
};

export default DescriptionList;
