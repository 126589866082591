// When adding new route make sure to add entry to switch statement below
export enum Routes {
    // Dashboard
    Onboarding = "/welcome",
    Index = "/",
    WhatsNew = "/whats-new",
    // Orders
    BetaOrders = "/orders",
    OrdersDetails = "/orders/:id",
    PurchaseDetails = "/purchases/:id",

    // Financial Reports
    FinancialReports = "/financial-reports",

    FinancialReportsPayoutHistory = "/financial-reports-payout-history",
    FinancialReportsCash = "/financial-reports-cash",
    FinancialReportsEssentials = "/financial-reports-essentials",

    // Menu Manager
    Menu = "/menu",
    MenuManager = "/menu-manager", // menus v3 endpoint
    MenuEditor = "/menu-editor",
    MultiMenusCreate = "/multi-menus-create",
    MultiMenus = "/multi-menus",
    MultiMenusOutline = "/multi-menus/:id",
    MultiMenusEdit = "/multi-menus-edit/:id",
    MultiMenusProductOverridesEdit = "/multi-menus-overrides/:id/products/:productId",
    MultiMenusAddonOverridesEdit = "/multi-menus-overrides/:id/products/:productId/addons/:addonId",
    MultiMenusSettings = "/multi-menus-settings/:id",
    Inventory = "/inventory",
    MenuInventoryManager = "/menu-inventory-manager",

    // Promotions
    Promotion = "/promotion",
    CreatePromotionGeneric = "/promotion/generic",
    CreatePromotionReward = "/promotion/reward",
    CreatePromotionHappyHour = "/promotion/happy-hour",
    CreatePromotionBogo = "/promotion/bogo",
    CreatePromotionAudience = "/promotion/audience", // Used for internal routing
    CreatePromotionStudents = "/promotion/audience/students",
    CreatePromotionFirstTime = "/promotion/audience/first-time",
    CreatePromotionDealDrop = "/promotion/deal-drop",
    CreatePromotionPromoCode = "/promotion/promo-code",
    CreatePromotionGiftCard = "/promotion/gift-card",

    // Reports
    ReportsSalesSummary = "/reports",
    ReportsSalesChannels = "/channels",
    ReportsMenuItemInsights = "/item-insights",
    ReportsMenuCategoryInsights = "/category-insights",
    ReportsLocationSales = "/location-sales",
    ReportsLocationMenuCategories = "/location-menu-categories",
    ReportsLocationMenuItems = "/location-menu-items",
    ReportsCustomerDirectoryInsights = "/customer-insights",
    ReportsPromotions = "/promo-reports",
    ReportsGiftCardPurchases = "/gift-card-purchases",
    ReportsGiftCardBalances = "/gift-card-balances",
    ReportsGiftCardTransactions = "/gift-card-transactions",
    ReportsGiftCardLiabilities = "/gift-card-liabilities",

    // Guestbook
    GuestbookCampaignsSMS = "/guestbook/campaigns/sms",
    GuestbookCampaignsSMSDetails = "/guestbook/campaigns/sms/:campaignId",
    GuestbookCampaignsPush = "/guestbook/campaigns/push",
    GuestbookCampaignsPushDetails = "/guestbook/campaigns/push/:campaignId",
    GuestbookConversations = "/guestbook/conversations",
    GuestbookCustomers = "/guestbook/customers",
    GuestbookAuditLog = "/guestbook/audit-log",

    // Billing
    Billing = "/billing",
    Payouts = "/payouts",
    PayoutsSchedule = "/payouts-schedule",

    // Employees
    EmployeeLaborCost = "/employee-labor-cost",
    EmployeeTimeCards = "/employee-time-cards",

    // Universal Device management
    Devices = "/devices",
    PrepStations = "/prep-stations",

    // Settings
    Settings = "/settings",
    SettingsCatering = "/settings-catering",
    SettingsGiftCard = "/settings-gift-card",
    SettingsQrCode = "/settings-qr-codes",
    SettingsTipping = "/settings-tipping",
    SettingsEmployees = "/settings-employees",
    SettingsIntegrations = "/settings-integrations",
    SettingsBusinessInfo = "/settings-business-info",
    SettingsProducts = "/settings-products",
    SettingsBrandAndAppearance = "/settings-brand-and-appearance",
    SettingsPlatforms = "/settings-platforms",
    SettingsOrders = "/settings-orders",
    SettingsTax = "/settings-tax",
    SettingsPermissions = "/users",
    SettingsLegal = "/settings-legal",
    SettingsAllOrders = "/settings-all-orders",
    SettingsBackOfHouse = "/settings-back-of-house",
    SettingsOnlineOrders = "/settings-online-orders",
    SettingsStoreTables = "/settings-store-tables",

    // Snackpass Admin
    InternalSettings = "/settings-internal",
    InternalFees = "/settings-fees",
    InternalSeniorSupport = "/settings-senior-support",

    // My Account
    SettingsAccount = "/settings-account",
    MyAccountTeams = "/my-teams",

    // Tax Forms
    TaxForms = "/tax-forms",

    // Resources
    Resources = "/resources",

    // Invoices
    Invoices = "/invoices",

    // Others
    Catering = "/catering",
    NewUserAcceptInvite = "/accept-invite",
    NotAllowed = "/not-allowed",
    Logout = "/logout"
}
