import { useSetRecoilState } from "recoil";

import { Button } from "src/@/components/ui/button";
import { CardTitle } from "src/@/components/ui/card";
import {
    BrandRegistrationStep,
    brandRegistrationStepAtom
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import {
    NewCampaignButton,
    CampaignScreenHeader
} from "#guestbook/screens/Campaigns/CampaignScreenHeader";
import { useActiveUser } from "src/redux/selectors";
import { PastCampaigns } from "#guestbook/screens/Campaigns/PastCampaigns";
import { SegmentEvents } from "#utils/segment";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import { ReachableSMSBanner } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/ReachableSMSBanner";

function CampaignBrandRegistrationGetStarted() {
    const setStep = useSetRecoilState(brandRegistrationStepAtom);
    const isSnackpassEmployee =
        useActiveUser()?.snackpassPermissions.isSnackpassEmployee;
    const trackCampaignEvent = useTrackCampaignSegmentEvent();
    return (
        <div>
            <CampaignScreenHeader button={<NewCampaignButton disabled />} />
            <div className="flex justify-center">
                <div className="max-w-screen-md overflow-hidden">
                    <div className="flex justify-center pt-4">
                        <ReachableSMSBanner />
                    </div>
                    <div className="my-[64px] flex flex-col gap-4 px-2 text-center sm:px-4">
                        <CardTitle className="text-2xl">
                            Set up Text Messages
                        </CardTitle>
                        <div className="text-neutral-600">
                            Get your own marketing phone number to message
                            customers that have opted in to receive texts with
                            updates, promotions, and more!
                        </div>
                        <div className="flex justify-center">
                            <Button
                                type="button"
                                onClick={() => {
                                    trackCampaignEvent(
                                        SegmentEvents.Guestbook.Campaigns
                                            .SETUP_SMS.START
                                    );
                                    setStep(BrandRegistrationStep.SMSForm);
                                }}
                            >
                                Get Started
                            </Button>
                        </div>
                    </div>
                    {isSnackpassEmployee && (
                        <div>
                            <div className="px-2 text-sm text-neutral-600 sm:px-4">
                                Only Snackpass employees can see this section
                            </div>
                            <PastCampaigns campaignType="sms" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CampaignBrandRegistrationGetStarted;
