import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "sonner";

import { CreatePromoButton } from "#promotion/components/create-promo-button";
import PromoTable from "#promotion/components/promos-table";
import { ShortcutCard } from "#promotion/components/shortcut-card";
import { useInfoForShortcutType } from "#promotion/lib";
import { FormSubmitState, PromoShortcut } from "#promotion/utils/types";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { getActiveStore, useActiveUser } from "src/redux/selectors";
import { genericShortcuts, socialShortcuts } from "#promotion/utils/constants";
import { Separator } from "src/@/components/ui/separator";
import { useAppSelector } from "src/redux/hooks";

type ShortcutsProps = {
    shortcuts: PromoShortcut[];
    mobile?: boolean;
    visible?: boolean;
};
interface PromotionsProps extends Omit<RouteComponentProps, "location"> {
    location: {
        state: FormSubmitState;
    };
}

const Shortcuts = ({
    mobile = false,
    visible = false,
    shortcuts
}: ShortcutsProps) => {
    const activeStore = useAppSelector(getActiveStore);
    const user = useActiveUser();

    const onClickShortcut = (type: PromoShortcut) => {
        trackSegmentEvent(SegmentEvents.CLICKED_PROMO_SHORTCUT, {
            store_id: activeStore?._id || null,
            user: { id: user?._id, name: user?.name },
            type
        });
    };

    return (
        <div className="flex max-w-[1020px] flex-row flex-wrap gap-6">
            {shortcuts.map((type, n) =>
                !mobile || visible || n <= 2 ? (
                    <Shortcut key={n} type={type} onClick={onClickShortcut} />
                ) : null
            )}
        </div>
    );
};

type ShortcutProps = {
    type: PromoShortcut;
    onClick: (type: PromoShortcut) => void;
};

function Shortcut({ type, onClick }: ShortcutProps) {
    const [icon, title, description, enabled, disabledText] =
        useInfoForShortcutType(type, 36); // 36px size icon

    return (
        <ShortcutCard
            onClick={() => onClick(type)}
            icon={icon}
            title={title}
            description={description}
            enabled={enabled}
            disabledText={disabledText}
            type={type}
        />
    );
}

const Promotions = ({ location: { state } }: PromotionsProps) => {
    // Only run once on load
    useEffect(() => {
        if (state) {
            if (state.success) {
                toast.success(
                    `Promotion Successfully ${
                        state.formUsage == "Create" ? "Created" : "Edited"
                    }`
                );
            } else {
                toast.error(`${state.formUsage} Promotion Failure`);
            }

            // Clear state after toasting
            window.history.replaceState({}, "");
        }
    }, []);

    return (
        <div>
            <div className="p-6 md:px-24">
                <div className="flex items-center justify-between">
                    <h1 className="m-0 text-heading font-semibold leading-10">
                        Promos
                    </h1>
                    <CreatePromoButton />
                </div>
            </div>
            <Separator className="" />
            <div className="mb-8 flex w-full flex-col space-y-12 p-6 md:px-24">
                <div>
                    <div className="mb-6 text-2xl font-semibold leading-8">
                        Shortcuts
                    </div>
                    <Shortcuts shortcuts={genericShortcuts} />
                </div>

                <div className="mb-6">
                    <div className="mb-6 text-2xl font-semibold leading-8">
                        Social Promos
                    </div>
                    <Shortcuts shortcuts={socialShortcuts} />
                </div>
                <div>
                    <div className="mb-6 text-2xl font-semibold leading-8">
                        Current Promos
                    </div>
                    <PromoTable />
                </div>
            </div>
        </div>
    );
};
export default Promotions;
