/** @jsxImportSource @emotion/react */
import { IProduct } from "@snackpass/snackpass-types";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { SetStateAction, useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";

import { selectActiveMenu } from "src/redux/selectors";
import {
    isCategoryNameTaken,
    isValidCateringCategoryName
} from "#menu-editor/mobile-friendly/helpers/utils";

enum MenuTypeEnum {
    REGULAR = "regular",
    CATERING = "catering"
}

type NewProductModalProp = {
    newCategory: boolean;
    setNewCategory: React.Dispatch<SetStateAction<boolean>>;
    openNewCategoryModal: boolean;
    setOpenNewCategoryModal: React.Dispatch<SetStateAction<boolean>>;
    openNewProductSlideOver: () => void;
    setProductInDraft: React.Dispatch<
        React.SetStateAction<Partial<IProduct> | null>
    >;
    products: IProduct[];
};

const NewCategoryModal: React.FC<NewProductModalProp> = ({
    setNewCategory,
    openNewCategoryModal,
    setOpenNewCategoryModal,
    openNewProductSlideOver,
    setProductInDraft,
    products
}) => {
    const [form] = Form.useForm();
    const activeMenu = useSelector(selectActiveMenu) as string;
    const [categoryName, setCategoryName] = useState("");
    const createCategory = () => {
        setOpenNewCategoryModal(false);
        setProductInDraft({
            category: categoryName
        });
        setNewCategory(true);
        openNewProductSlideOver();
    };

    return (
        <Modal
            centered
            css={ModalCSS}
            open={openNewCategoryModal}
            destroyOnClose={true}
            footer={null}
            onCancel={() => {
                setOpenNewCategoryModal(false);
            }}
        >
            <HeaderRow>
                <span className="header-text">Create New Category</span>
            </HeaderRow>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 15 }}
                form={form}
                preserve={false}
                onFinish={createCategory}
                autoComplete="off"
                layout="horizontal"
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="categoryName"
                            label="Product Category"
                            rules={[
                                {
                                    required: true,
                                    message: "Category name is required"
                                },
                                {
                                    async validator(_, value) {
                                        if (value) {
                                            const isTaken = isCategoryNameTaken(
                                                value,
                                                products,
                                                activeMenu ===
                                                    MenuTypeEnum.CATERING
                                            );
                                            if (isTaken) {
                                                return Promise.reject(
                                                    "This category name is already taken"
                                                );
                                            }
                                            if (
                                                activeMenu ===
                                                    MenuTypeEnum.CATERING &&
                                                !isValidCateringCategoryName(
                                                    value
                                                )
                                            ) {
                                                return Promise.reject(
                                                    "Catering product category name must end with '(Catering)'"
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Category Name"
                                value={categoryName}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setCategoryName(e.target.value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="create-new-btn">
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        disabled={
                            !form.isFieldsTouched(true) ||
                            form
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length >
                                0
                        }
                    >
                        Create New Category
                    </Button>
                </Row>
            </Form>
        </Modal>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    font-size: 18px;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
    .ant-input {
        border-radius: 8px;
        ::placeholder {
            font-size: 16px;
        }
    }
    .create-new-btn {
        margin-top: 14px;
        justify-content: center;
    }
`;

const HeaderRow = styled.div`
    margin-bottom: 1rem;
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

export default NewCategoryModal;
