import { useAppSelector } from "src/redux/hooks";

import {
    getActiveCampaignId,
    getCampaignAudience,
    getCampaignMessageInput,
    getCampaignMessageInputTitle,
    getCampaignMessageInputVariables,
    getCampaignState,
    getCampaignType,
    getDiscountDollarOff,
    getDiscountExpiresInDays,
    getDiscountMaximumUses,
    getDiscountPercentOff,
    getDiscountProduct,
    getDiscountPurchaseRequired,
    getDiscountType,
    getDiscountPromoType
} from "./selectors";

export const useCampaignState = () => useAppSelector(getCampaignState);

export const useCampaignMessageInput = () =>
    useAppSelector(getCampaignMessageInput);

export const useCampaignMessageInputTitle = () =>
    useAppSelector(getCampaignMessageInputTitle);

export const useCampaignMessageInputVariables = () =>
    useAppSelector(getCampaignMessageInputVariables);

export const useCampaignAudience = () => useAppSelector(getCampaignAudience);

export const useCampaignType = () => useAppSelector(getCampaignType);

export const useDiscountPromoType = () => useAppSelector(getDiscountPromoType);

export const useDiscountType = () => useAppSelector(getDiscountType);

export const useDiscountPercentOff = () =>
    useAppSelector(getDiscountPercentOff);

export const useDiscountDollarOff = () => useAppSelector(getDiscountDollarOff);

export const useDiscountExpiresInDays = () =>
    useAppSelector(getDiscountExpiresInDays);

export const useDiscountPurchaseRequired = () =>
    useAppSelector(getDiscountPurchaseRequired);

export const useDiscountMaximumUses = () =>
    useAppSelector(getDiscountMaximumUses);

export const useDiscountProduct = () => useAppSelector(getDiscountProduct);

export const useActiveCampaignId = () => useAppSelector(getActiveCampaignId);
