import { useCallback, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { NumberInput } from "@tremor/react";

import { CurrentTable, TableNameSchema } from "#table-definitions/types";
import { Label } from "src/@/components/ui/label";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import { useUpdateTableMutation } from "src/api/graphql/generated/types";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";

type TableDetailProps = {
    table: CurrentTable;
    onEdit: () => void;
    openToEdit: boolean;
};

export const TableDetail = ({
    table,
    onEdit,
    openToEdit
}: TableDetailProps) => {
    const [isEditing, setIsEditing] = useState(openToEdit);
    const [isLoading, setIsLoading] = useState(false);

    const resolver: Resolver<z.infer<typeof TableNameSchema>> = useCallback(
        async (data, context, options) =>
            zodResolver(TableNameSchema)(data, context, options),
        []
    );

    const form = useForm<z.infer<typeof TableNameSchema>>({
        resolver,
        defaultValues: {
            name: table.name,
            numSeats: table.numSeats
        }
    });

    const [updateTableMutation] = useUpdateTableMutation();

    const handleSubmit = useCallback(
        async (formValues: z.infer<typeof TableNameSchema>) => {
            setIsLoading(true);
            try {
                await updateTableMutation({
                    variables: {
                        tableId: table.id,
                        input: {
                            ...formValues
                        }
                    }
                });
                onEdit();
                toast.success("Table updated");
            } catch (err) {
                toast.error("Error creating table");
                form.reset({ ...formValues });
            }
            setIsLoading(false);
        },
        [form, updateTableMutation, table.id, onEdit]
    );

    if (!table) {
        return null;
    }

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <h1 className="text-2xl font-bold">Table</h1>
            </div>
            <div className="grid grid-cols-2 gap-4">
                {isEditing ? (
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(handleSubmit)}
                            className="space-y-4"
                        >
                            <div>
                                <Label>Table Number</Label>
                                <FormField
                                    disabled={isLoading}
                                    control={form.control}
                                    name="name"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    id="name"
                                                    placeholder="Enter table number"
                                                    maxLength={4}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div>
                                <Label>Number of Seats</Label>
                                <FormField
                                    disabled={isLoading}
                                    control={form.control}
                                    name="numSeats"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <NumberInput
                                                    min={0}
                                                    className="flex-1 [&>input]:pl-2"
                                                    value={field.value}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            Number.parseFloat(
                                                                e.target
                                                                    .value ?? 0
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <Button type="submit">Save</Button>
                        </form>
                    </Form>
                ) : (
                    <>
                        <div>
                            <Label>Table Number</Label>
                            <p>{table.name}</p>
                        </div>
                        <div>
                            <Label>Number of Seats</Label>
                            <p>{table.numSeats}</p>
                        </div>
                    </>
                )}
            </div>
            <Button
                onClick={() => {
                    setIsEditing(!isEditing);
                    if (!isEditing) {
                        form.reset({
                            name: table.name,
                            numSeats: table.numSeats
                        });
                    }
                }}
            >
                {isEditing ? "Cancel" : "Edit"}
            </Button>
        </div>
    );
};
