import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { Spinner } from "react-activity";

import { Button } from "src/@/components/ui/button";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { GetTablesQuery } from "src/api/graphql/generated/types";

type TableSessionRow = NonNullable<GetTablesQuery["storeTables"]>[number];

export const useColumns = (
    onCellSelected: (cell: TableSessionRow) => void,
    deleteTable: (tableId: string) => void,
    setDefaultEditOnOpen: (edit: boolean) => void,
    loadingTableId?: string
): ColumnDef<TableSessionRow>[] =>
    useMemo(
        () => [
            {
                id: "Table Number",
                accessorKey: "name",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Table Number"
                    />
                ),
                cell: ({ row }) => (
                    <span
                        className="cursor-pointer text-blue-600"
                        onClick={() => onCellSelected(row?.original)}
                    >
                        {row.original.name}
                    </span>
                ),
                enableGlobalFilter: false
            },
            {
                id: "Seats",
                accessorKey: "numSeats",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Seats" />
                ),
                cell: ({ row }) => <div>{row.original.numSeats}</div>,
                enableGlobalFilter: false
            },
            {
                id: "actions",
                accessorKey: "actions",
                header: "",
                size: 40,
                cell: ({ row }) => (
                    <div className="flex justify-end">
                        <Button
                            variant="outline"
                            onClick={(e) => {
                                setDefaultEditOnOpen(true);
                                onCellSelected(row.original);
                                e.stopPropagation(); // the row has a click listener as well, avoid propagating to it
                            }}
                            className="mr-1"
                        >
                            <Pencil1Icon />
                        </Button>
                        <Button
                            onClick={(e) => {
                                deleteTable(row.original.id);
                                e.stopPropagation(); // the row has a click listener as well, avoid propagating to it
                            }}
                            variant="outline"
                        >
                            {loadingTableId === row.original.id ? (
                                <Spinner />
                            ) : (
                                <TrashIcon className="text-red-500" />
                            )}
                        </Button>
                    </div>
                )
            }
        ],
        []
    );
