import { useContext } from "react";

import { PersonStatuses } from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";

export const useRepresentative = () => {
    const { user, verification } = useContext(PayoutsSettingsContext);

    const ownersAndExecutives =
        verification?.representatives?.filter((r) => r.owner || r.executive) ??
        [];
    const verifiedOwnersAndExecutives =
        ownersAndExecutives?.filter(
            (r) => r.status === PersonStatuses.verified
        ) ?? [];

    // we don't want to filter on verifiedOwners... here in case a primary who was
    // previously verified primary is not in a verified state due to additional
    // requirements
    const primary = ownersAndExecutives?.find((r) => r.representative);

    // only allow primaries with the same email as the current dashboard user
    const eligiblePrimary =
        verifiedOwnersAndExecutives.filter(
            (r) =>
                user?.email &&
                r.email.toLowerCase() === user.email.toLowerCase()
        ) ?? [];

    const started = !!verification?.representatives?.length;
    const hasAnOwnerOrExec = !!ownersAndExecutives.length;
    const hasVerifiedOwnerOrExec = !!verifiedOwnersAndExecutives.length;
    const hasEligiblePrimary = !!eligiblePrimary.length;
    const hasPrimary = !!primary;

    // terms of service
    const hasTos =
        !!verification?.termsOfServiceDate && !!verification.termsOfServiceDate;

    const done = hasPrimary && hasTos;

    return {
        verifiedOwnersAndExecutives,
        eligiblePrimary,
        primary,
        //
        hasAnOwnerOrExec,
        hasVerifiedOwnerOrExec,
        hasEligiblePrimary,
        hasPrimary,
        //
        hasTos,
        //
        started,
        done
    };
};
