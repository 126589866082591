/** @jsxImportSource @emotion/react */
import {
    Addon,
    AddonGroup,
    IProduct,
    IStore
} from "@snackpass/snackpass-types";
import { Button, Col, Form, Modal, Row } from "antd";
import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { css } from "@emotion/react";
import OnChangeValue, { ActionMeta } from "react-select";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import {
    getActiveProductCategory,
    getActiveStore,
    getStores,
    getUser,
    selectActiveMenu
} from "src/redux/selectors";
import DropDownSelect from "#reusable/select/dropdown";
import api from "src/api/rest";

import { sortByCategory } from "./helpers";

type CategoryOptionType = {
    readonly value: string;
    readonly label: string;
};

enum MenuTypeEnum {
    REGULAR = "regular",
    CATERING = "catering"
}

type Template = {
    label: string;
    value: string;
    product: IProduct;
};

type NewProductModalProp = {
    openNewItemModal: boolean;
    setOpenNewItemModal: React.Dispatch<SetStateAction<boolean>>;
    productOptions: Template[];
    categoryOptions: CategoryOptionType[] | undefined;
    isLoading: boolean;
    setIsLoading: React.Dispatch<SetStateAction<boolean>>;
    openNewProductSlideOver: () => void;
    setProductInDraft: React.Dispatch<
        React.SetStateAction<Partial<IProduct> | null>
    >;
};

const validateMessages = {
    required: "${label} is required!"
};

const NewProductModal: React.FC<NewProductModalProp> = ({
    openNewItemModal,
    setOpenNewItemModal,
    productOptions,
    categoryOptions,
    isLoading,
    setIsLoading,
    openNewProductSlideOver,
    setProductInDraft
}) => {
    const activeStore = useSelector(getActiveStore) as IStore;
    const stores = useSelector(getStores);
    const activeMenu = useSelector(selectActiveMenu);
    const activeProductCategory = useSelector(getActiveProductCategory);
    const user = useSelector(getUser);
    const [category, setCategory] = useState<CategoryOptionType | null>(null);
    const [selectedStoreOption, setSelectedStoreOption] = useState({
        label: activeStore.name,
        value: activeStore._id
    });
    const [productTemplates, setTemplates] = useState<Template[]>([]);
    const [template, setTemplate] = useState<Template | null>(null);
    const [existingProduct, setExistingProduct] = useState<Template | null>(
        null
    );

    const storeOptions = useMemo(
        () =>
            stores.map((store) => ({
                label: store.name,
                value: store._id,
                store
            })),
        [stores]
    );

    const isSnackpassEmployee =
        !!user?.snackpassPermissions?.isSnackpassEmployee;

    useEffect(() => {
        (async () => {
            const response = await api.products.getTemplates(
                selectedStoreOption.value
            );

            const templateOptions = response.data.templateProducts.map(
                (template) => ({
                    label: `${template.name}`,
                    value: template._id,
                    product: template
                })
            );

            setTemplates(
                templateOptions.sort((t1, t2) =>
                    sortByCategory(
                        t1.product.category,
                        t2.product.category,
                        activeProductCategory
                    )
                )
            );
        })();
    }, [activeProductCategory, selectedStoreOption]);
    const createProduct = () => {
        const selectedProduct: IProduct | null = template?.product ?? null;
        if (!selectedProduct) {
            return;
        }

        if (!activeStore) {
            return;
        }
        setIsLoading(true);
        const addonGroups = selectedProduct.addonGroups.map(
            (addonGroup: Partial<AddonGroup>) => {
                const groupRet = {
                    ...addonGroup,
                    addons: addonGroup.addons?.map((addon: Addon) => {
                        const ret = cloneDeep(addon) as Partial<Addon>;
                        delete ret._id;
                        return ret;
                    })
                };
                delete groupRet._id;
                return groupRet;
            }
        );

        const post: object = {
            addonGroups,
            points: selectedProduct.points,
            description: selectedProduct.description,
            pun: selectedProduct.pun,
            name: selectedProduct.name,
            price: selectedProduct.price,
            image: selectedProduct.image,
            hours: selectedProduct.hours,
            upsell: selectedProduct.upsell,
            category: category?.label,
            isCatering: activeMenu === MenuTypeEnum.CATERING,
            numberFeeds: selectedProduct.numberFeeds,
            minimumQuantity: selectedProduct.minimumQuantity,
            fulfillmentMethods: selectedProduct.fulfillmentMethods,
            channelVisibility: selectedProduct.channelVisibility
        };

        setProductInDraft(post);
        setIsLoading(false);
        openNewProductSlideOver();
    };

    return (
        <Modal
            centered
            css={ModalCSS}
            visible={openNewItemModal}
            destroyOnClose
            footer={null}
            onCancel={() => {
                setOpenNewItemModal(false);
            }}
        >
            <HeaderRow>
                <span className="header-text" onClick={() => {}}>
                    Create Item From Template
                </span>
            </HeaderRow>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 15 }}
                onFinish={() => {
                    if (category && (template || existingProduct)) {
                        createProduct();
                        setOpenNewItemModal(false);
                    }
                }}
                autoComplete="off"
                validateMessages={validateMessages}
                layout="horizontal"
            >
                {" "}
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="template_source"
                            label="Store"
                            className="template"
                        >
                            <DropDownSelect
                                square
                                placeholder={selectedStoreOption.label}
                                value={selectedStoreOption}
                                options={storeOptions}
                                onChange={(selectedOption: {
                                    label: string;
                                    value: string;
                                }) => {
                                    setSelectedStoreOption(selectedOption);
                                }}
                                isLoading={isLoading}
                                fontSize="16"
                                fontWeight="500"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="template"
                            label="Item Template"
                            className="template"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <DropDownSelect
                                square
                                placeholder={"Select Template"}
                                value={template}
                                options={productTemplates}
                                onChange={(
                                    selectedOption: React.SetStateAction<Template | null>,
                                    newValue: OnChangeValue<Template, false>,
                                    actionMeta: ActionMeta<Template>
                                ) => {
                                    setTemplate(selectedOption);
                                }}
                                isLoading={isLoading}
                                fontSize="16"
                                fontWeight="500"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="category"
                            label="Add to Category"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <DropDownSelect
                                square
                                options={categoryOptions}
                                value={category}
                                onChange={(
                                    selectedOption: React.SetStateAction<CategoryOptionType | null>
                                ) => {
                                    setCategory(selectedOption);
                                }}
                                placeholder="Add a Category"
                                fontSize="16"
                                fontWeight="500"
                            ></DropDownSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="create-new-btn">
                    <Col span={8}></Col>
                    <Col span={8}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            shape="round"
                        >
                            Create Item
                        </Button>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </Form>
        </Modal>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    font-size: 18px;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
    .create-new-btn {
        margin-top: 8px;
    }
`;

const HeaderRow = styled.div`
    margin-bottom: 1rem;
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

export default NewProductModal;
