/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Row, Select, SelectProps, Typography } from "antd";
import { css } from "@emotion/react";
import {
    IKiosk,
    PrepStation,
    PrinterWithPrepStation,
    FulfillmentTypeEnum,
    PurchaseReportTransactionSourceTypeEnum
} from "@snackpass/snackpass-types";
import { Link } from "react-router-dom";

import { Input } from "src/@/components/ui/input";
import {
    useCategoriesWithProducts,
    useProductEssentials,
    useProductToCategoryMap
} from "#menu/hooks";
import { ConfirmDeleteAlert } from "#inventory/components/confirm-delete-alert";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { Routes } from "#navigation/routes";
import {
    usePrepStationsChannelFulfillmentEnabled,
    usePrepStationsEnabled
} from "#navigation/utils";
import { findAssignedPrepStationDevices } from "#devices/utils/findAssignedPrepStationDevices";

import { usePrepStationConflictsAPI } from "./usePrepStationConflictsAPI";
import { usePrepStationLists } from "./usePrepStationLists";

const { Title, Paragraph } = Typography;
const { Option } = Select;

export type PrepStationUpdate = {
    name: string;
    store: string;
    printers: Array<string>;
    isInclusive: boolean;
    allowCategoryList: Array<string>;
    allowProductList: Array<string>;
    rejectCategoryList: Array<string>;
    rejectProductList: Array<string>;
    allowChannelList: Array<string>;
    allowFulfillmentList: Array<string>;
};

type PrepStationDetailProps = {
    storeId: string;
    isCreate: boolean;
    activePrepStation: PrepStation | undefined;
    printers: Array<PrinterWithPrepStation>;
    kiosks: IKiosk[];
    onSave: (id: string, values: PrepStationUpdate) => void;
    onCreate: (values: PrepStationUpdate) => void;
    onDelete: (id: string) => void;
};

const PrepStationDetail = ({
    storeId,
    isCreate,
    activePrepStation,
    printers,
    kiosks,
    onCreate,
    onSave,
    onDelete
}: PrepStationDetailProps) => {
    const {
        hasExclusiveAllowConflict,
        hasExclusiveProductConflict,
        hasInclusiveMinimum,
        hasInclusiveProductConflict,
        hasInclusiveRejectConflict,
        hasChannelFulfillmentMinimum
    } = usePrepStationConflictsAPI();
    const prepStationEnabled = usePrepStationsEnabled();
    const prepStationChannelFulfillmentEnabled =
        usePrepStationsChannelFulfillmentEnabled();
    const [form] = Form.useForm();
    const {
        allowedCategories,
        allowedProducts,
        rejectedCategories,
        rejectedProducts,
        allowedChannels,
        allowedFulfillments,
        setAllowedCategories,
        setAllowedProducts,
        setRejectedCategories,
        setRejectedProducts,
        setAllowedChannels,
        setAllowedFulfillments,
        setFromPrepStation
    } = usePrepStationLists(form);

    const { storeDevices } = useContext(DevicesPageContext);
    const products = useProductEssentials();
    const productToCategoriesMap = useProductToCategoryMap();
    const categories = useCategoriesWithProducts();
    const channels = [
        {
            id: PurchaseReportTransactionSourceTypeEnum.App,
            name: "App"
        },
        {
            id: PurchaseReportTransactionSourceTypeEnum.Register,
            name: "Register"
        },
        {
            id: PurchaseReportTransactionSourceTypeEnum.Kiosk,
            name: "Kiosk"
        },
        {
            id: PurchaseReportTransactionSourceTypeEnum.Online,
            name: "Online Ordering"
        },
        {
            id: PurchaseReportTransactionSourceTypeEnum.ThirdParty,
            name: "Third Party"
        }
    ];
    const fulfillments = [
        {
            id: FulfillmentTypeEnum.DineIn,
            name: "Dine In"
        },
        {
            id: FulfillmentTypeEnum.Delivery,
            name: "Delivery"
        },
        {
            id: FulfillmentTypeEnum.Pickup,
            name: "Pickup"
        }
    ];

    const [prepStationName, setPrepStationName] = useState<string | undefined>(
        undefined
    );

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isInclusive, setIsInclusive] = useState(true);

    const [hasSubmitError, setHasSubmitError] = useState<string | null>(null);
    const [hasAllowProductListError, setHasAllowProductListError] = useState<
        string | null
    >(null);
    const [hasRejectProductListError, setHasRejectProductListError] = useState<
        string | null
    >(null);

    const hasError =
        !!hasSubmitError ||
        !!hasAllowProductListError ||
        !!hasRejectProductListError;

    useEffect(() => {
        // Default to use inclusion lists unless explicitly set.
        const isInclusiveDefault = activePrepStation?.isInclusive ?? true;
        form.setFieldsValue({
            name: activePrepStation?.name,
            isInclusive: isInclusiveDefault
        });
        setPrepStationName(activePrepStation?.name);
        setIsInclusive(isInclusiveDefault);
        setFromPrepStation(activePrepStation);
    }, [activePrepStation]);

    // Validate prep-station spec when updates occur.
    useEffect(() => {
        let hasNameOrPrinterIssue = false;
        if (!prepStationName) {
            setHasSubmitError("Need to set prep-station name.");
            hasNameOrPrinterIssue = true;
        } else {
            setHasSubmitError(null);
        }

        // If inclusive, verify the user hasn't already specified
        // allow products that are now covered under category.
        if (isInclusive) {
            // Check if allowProductList has items that are now covered by category.
            if (
                hasInclusiveProductConflict(allowedCategories, allowedProducts)
            ) {
                setHasAllowProductListError(
                    "Allow Product List has items now covered by Category."
                );
            } else {
                setHasAllowProductListError(null);
            }

            // Verify rejectProductList only has items that are covered by category.
            if (
                hasInclusiveRejectConflict(allowedCategories, rejectedProducts)
            ) {
                setHasRejectProductListError(
                    "Reject Product List must only contain items allowed by Category."
                );
            } else {
                setHasRejectProductListError(null);
            }

            // Validate for submission
            if (
                !hasInclusiveMinimum(allowedCategories, allowedProducts) &&
                !hasChannelFulfillmentMinimum(
                    allowedChannels,
                    allowedFulfillments
                ) &&
                !hasNameOrPrinterIssue
            ) {
                setHasSubmitError(
                    "Need to select at least one channel, fulfillment, or allowed category or product."
                );
            } else if (!hasNameOrPrinterIssue) {
                setHasSubmitError(null);
            }
        } else {
            // Check if rejectProductList has items that are now covered by category.
            if (
                hasExclusiveProductConflict(
                    rejectedCategories,
                    rejectedProducts
                )
            ) {
                setHasRejectProductListError(
                    "Reject Product List has items now covered by Category."
                );
            } else {
                setHasRejectProductListError(null);
            }

            // Verify allowProductList only has items that are covered by category.
            if (
                hasExclusiveAllowConflict(rejectedCategories, allowedProducts)
            ) {
                setHasAllowProductListError(
                    "Allow Product List must only contain items rejected by Category."
                );
            } else {
                setHasAllowProductListError(null);
            }
        }
    }, [
        prepStationName,
        isInclusive,
        allowedCategories,
        allowedProducts,
        rejectedCategories,
        rejectedProducts,
        hasInclusiveProductConflict,
        hasInclusiveRejectConflict,
        hasExclusiveProductConflict,
        hasExclusiveAllowConflict,
        hasInclusiveMinimum,
        hasChannelFulfillmentMinimum,
        allowedChannels,
        allowedFulfillments
    ]);

    const initialPrinterIds = printers
        .filter(
            (printer) =>
                activePrepStation?.id &&
                printer.prepStation?.id === activePrepStation?.id
        )
        .map((printer) => printer.id);

    const initialKioskIds = kiosks
        .filter(
            (kiosk) =>
                activePrepStation?.id &&
                kiosk.prepStation === activePrepStation?.id
        )
        .map((kiosk) => kiosk._id);

    const printerNames = findAssignedPrepStationDevices(
        initialPrinterIds,
        storeDevices
    );
    const kioskNames = findAssignedPrepStationDevices(
        initialKioskIds,
        storeDevices
    );

    const onFinish = () => {
        const prepStation: PrepStationUpdate = {
            name: prepStationName as string,
            printers: initialPrinterIds,
            isInclusive,
            allowCategoryList: isInclusive ? allowedCategories : [],
            allowProductList: isInclusive ? allowedProducts : [],
            rejectCategoryList: isInclusive ? [] : rejectedCategories,
            rejectProductList: isInclusive ? [] : rejectedProducts,
            allowChannelList: allowedChannels,
            allowFulfillmentList: allowedFulfillments,
            store: storeId
        };

        if (isCreate) {
            onCreate(prepStation);
            return;
        }
        if (activePrepStation?.id) {
            onSave(activePrepStation.id, prepStation);
        }
    };

    const onDeleteClick = () => {
        if (activePrepStation?.id) {
            onDelete(activePrepStation.id);
        }
    };

    const onFilterOption: SelectProps["filterOption"] = (
        inputValue,
        option
    ) => {
        const filterOption =
            typeof option !== "boolean" && option?.props.children;

        if (!filterOption) {
            return;
        }

        return filterOption
            .toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase());
    };

    const onAllowCategoryListChange = (value: Array<string>) => {
        form.setFieldsValue({ allowCategoryList: value });
        setAllowedCategories(value);
    };

    const onRejectCategoryListChange = (value: Array<string>) => {
        form.setFieldsValue({ rejectCategoryList: value });
        setRejectedCategories(value);
    };

    const onAllowProductListChange = (value: Array<string>) => {
        form.setFieldsValue({ allowProductList: value });
        setAllowedProducts(value);
    };

    const onRejectProductListChange = (value: Array<string>) => {
        form.setFieldsValue({ rejectProductList: value });
        setRejectedProducts(value);
    };

    const onAllowChannelListChange = (value: Array<string>) => {
        form.setFieldsValue({ allowedChannels: value });
        setAllowedChannels(value);
    };

    const onAllowFulfillmentListChange = (value: Array<string>) => {
        form.setFieldsValue({ allowedFulfillments: value });
        setAllowedFulfillments(value);
    };

    // XXX: These memoized "elements" should really be components but where it is used expects only `Option` children.
    // In the future one solution would be building select components for each of these.

    const ChannelOptionsElements = useMemo(
        () =>
            channels.map((channel) => {
                const disabled = allowedChannels.includes(channel.id);
                return (
                    <Option
                        key={channel.id}
                        value={channel.id}
                        disabled={disabled}
                    >
                        {channel.name}
                    </Option>
                );
            }),
        [allowedChannels]
    );

    const FulfillmentOptionsElements = useMemo(
        () =>
            fulfillments.map((fulfillment) => {
                const disabled = allowedFulfillments.includes(fulfillment.id);
                return (
                    <Option
                        key={fulfillment.id}
                        value={fulfillment.id}
                        disabled={disabled}
                    >
                        {fulfillment.name}
                    </Option>
                );
            }),
        [allowedFulfillments]
    );

    const CategoryOptionsElements = useMemo(
        () =>
            categories.map((category) => (
                <Option key={category.id} value={category.id}>
                    {category.name}
                </Option>
            )),
        [categories]
    );

    const AllowPublicOptionsElements = useMemo(
        () =>
            products
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((product) => {
                    if (isInclusive) {
                        const disabled = allowedCategories.includes(
                            productToCategoriesMap[product.id]
                        );
                        return (
                            <Option
                                key={product.id}
                                value={product.id}
                                disabled={disabled}
                            >
                                {`${product.name} (${product.categoryName}) ${
                                    disabled
                                        ? "-- already allowed by category"
                                        : ""
                                }`}
                            </Option>
                        );
                    }

                    const enabled =
                        !rejectedProducts.includes(product.id) &&
                        rejectedCategories.includes(
                            productToCategoriesMap[product.id]
                        );
                    return (
                        <Option
                            key={product.id}
                            value={product.id}
                            disabled={!enabled}
                        >
                            {`${product.name} (${product.categoryName}) ${
                                !enabled ? "-- not rejected by category" : ""
                            }`}
                        </Option>
                    );
                }),
        [
            products,
            productToCategoriesMap,
            isInclusive,
            allowedCategories,
            rejectedProducts,
            rejectedCategories
        ]
    );

    const RejectProductOptionsElements = useMemo(
        () =>
            products
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((product) => {
                    if (isInclusive) {
                        const enabled =
                            !allowedProducts.includes(product.id) &&
                            allowedCategories.includes(
                                productToCategoriesMap[product.id]
                            );
                        return (
                            <Option
                                key={product.id}
                                value={product.id}
                                disabled={!enabled}
                            >
                                {`${product.name} (${product.categoryName}) ${
                                    !enabled ? "-- not allowed by category" : ""
                                }`}
                            </Option>
                        );
                    }

                    const disabled = rejectedCategories.includes(
                        productToCategoriesMap[product.id]
                    );
                    return (
                        <Option
                            key={product.id}
                            value={product.id}
                            disabled={disabled}
                        >
                            {`${product.name} (${product.categoryName}) ${
                                disabled
                                    ? "-- already rejected by category"
                                    : ""
                            }`}
                        </Option>
                    );
                }),
        [
            products,
            productToCategoriesMap,
            isInclusive,
            rejectedCategories,
            allowedProducts,
            allowedCategories
        ]
    );

    const updateInclusivity = (value: boolean) => {
        setIsInclusive(value);

        // Reset allow / reject lists if exclusivity changes.
        if (value !== isInclusive) {
            setHasAllowProductListError(null);
            setHasRejectProductListError(null);
        }
    };

    return (
        <div css={bodyStyle}>
            <Title level={3}>{activePrepStation?.name}</Title>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    wrapperCol={{ span: 8 }}
                    required
                >
                    <Input
                        onChange={(e) => setPrepStationName(e.target.value)}
                        value={prepStationName}
                    />
                </Form.Item>
                {!isCreate && (
                    <Form.Item label="Devices" name="devices">
                        <Paragraph>
                            Devices that the current Prep Station rules apply
                            to. Update devices
                            <Link
                                to={{
                                    pathname: Routes.Devices
                                }}
                            >
                                {" "}
                                here.
                            </Link>
                        </Paragraph>
                        <span className="font-bold text-neutral-600">
                            Printers:{" "}
                        </span>
                        <span className="italic text-neutral-600">
                            {!printerNames?.length ? "N/A" : printerNames}
                        </span>
                        {/* NB: Hard code prep station assignment for SnackOS to not be enabled for the time being due to a blocker.
                            We want to unblock the rollout of new prep station features for printers. */}
                        {prepStationEnabled && false && (
                            <>
                                <br />
                                <span className="font-bold text-neutral-600">
                                    SnackOS:{" "}
                                </span>
                                <span className="italic text-neutral-600">
                                    {!kioskNames?.length ? "N/A" : kioskNames}
                                </span>
                            </>
                        )}
                    </Form.Item>
                )}
                {prepStationChannelFulfillmentEnabled && (
                    <>
                        <Form.Item
                            label="Allowed Channels"
                            name="allowChannelList"
                        >
                            <Paragraph>
                                Select which channels to accept for this Prep
                                Station. None selected will accept all channels.
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={allowedChannels}
                                filterOption={onFilterOption}
                                onChange={onAllowChannelListChange}
                            >
                                {ChannelOptionsElements}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Allowed Fulfillments"
                            name="allowFulfillmentList"
                        >
                            <Paragraph>
                                Select which fulfillments to accept for this
                                Prep Station. None selected will accept all
                                fulfillments.
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={allowedFulfillments}
                                filterOption={onFilterOption}
                                onChange={onAllowFulfillmentListChange}
                            >
                                {FulfillmentOptionsElements}
                            </Select>
                        </Form.Item>
                    </>
                )}
                <Form.Item
                    label="Prep Station Configuration Mode"
                    name="isInclusive"
                    required
                >
                    <Paragraph>
                        By default, prep-stations are inclusive, meaning that
                        you specify everything that the printer SHOULD print. If
                        you want to instead specify everything that the printer
                        SHOULD NOT print, change this dropdown to exclusive.
                    </Paragraph>

                    <Select
                        showArrow
                        style={{ width: "100%" }}
                        filterOption={onFilterOption}
                        onChange={(value: string) =>
                            updateInclusivity(value === "Inclusive")
                        }
                        value={isInclusive ? "Inclusive" : "Exclusive"}
                    >
                        <Option key={"Inclusive"} value={"Inclusive"}>
                            Inclusive
                        </Option>
                        <Option key={"Exclusive"} value={"Exclusive"}>
                            Exclusive
                        </Option>
                    </Select>
                </Form.Item>

                {isInclusive ? (
                    <>
                        <Form.Item
                            label="Allowed Categories"
                            name="allowCategoryList"
                        >
                            <Paragraph>
                                Allowed categories specify menu categories that
                                will print to the specified printers.
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={allowedCategories}
                                filterOption={onFilterOption}
                                onChange={onAllowCategoryListChange}
                            >
                                {CategoryOptionsElements}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Allowed Products"
                            name="allowProductList"
                        >
                            <Paragraph>
                                Allowed products specify individual products
                                that will print to the specified printers. You
                                should not add items here whose categories you
                                already listed above.
                            </Paragraph>
                            <Paragraph style={{ color: "red" }}>
                                {hasAllowProductListError}
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={allowedProducts}
                                filterOption={onFilterOption}
                                onChange={onAllowProductListChange}
                            >
                                {AllowPublicOptionsElements}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Reject Product Override"
                            name="rejectProductList"
                        >
                            <Paragraph>
                                Reject product override lets you exclude
                                individual products from categories that you
                                specified in the Allowed Categories section. For
                                example, if you wanted to print all sandwiches
                                except for the B.L.T, you could specify the
                                sandwiches category in Allowed Categories, and
                                then add the B.L.T. in Reject Product Override.
                            </Paragraph>
                            <Paragraph style={{ color: "red" }}>
                                {hasRejectProductListError}
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={rejectedProducts}
                                filterOption={onFilterOption}
                                onChange={onRejectProductListChange}
                            >
                                {RejectProductOptionsElements}
                            </Select>
                        </Form.Item>
                    </>
                ) : (
                    <>
                        <Form.Item
                            label="Rejected Categories"
                            name="rejectCategoryList"
                        >
                            <Paragraph>
                                Rejected categories specify menu categories that
                                will NOT print to the specified printers.
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={rejectedCategories}
                                filterOption={onFilterOption}
                                onChange={onRejectCategoryListChange}
                            >
                                {CategoryOptionsElements}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Rejected Products"
                            name="rejectProductList"
                        >
                            <Paragraph>
                                Rejected products specify individual products
                                that will NOT print to the specified printers.
                                You should not add items here whose categories
                                you already listed above.
                            </Paragraph>
                            <Paragraph style={{ color: "red" }}>
                                {hasRejectProductListError}
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={rejectedProducts}
                                filterOption={onFilterOption}
                                onChange={onRejectProductListChange}
                            >
                                {RejectProductOptionsElements}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Allow Product Override"
                            name="allowProductList"
                        >
                            <Paragraph>
                                Allow product override lets you include
                                individual products from categories that you
                                specified in the Rejected Categories section.
                                For example, if you wanted to not print any
                                sandwiches except for the B.L.T, you could
                                specify the sandwiches category in Rejected
                                Categories, and then add the B.L.T. in Allow
                                Product Override.
                            </Paragraph>
                            <Paragraph style={{ color: "red" }}>
                                {hasAllowProductListError}
                            </Paragraph>
                            <Select
                                allowClear
                                showArrow
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                value={allowedProducts}
                                filterOption={onFilterOption}
                                onChange={onAllowProductListChange}
                            >
                                {AllowPublicOptionsElements}
                            </Select>
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Paragraph style={{ color: "red" }}>
                        {hasSubmitError}
                    </Paragraph>
                    <Row justify="space-between">
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            disabled={hasError}
                        >
                            {isCreate
                                ? "Create Prep Station"
                                : "Update Prep Station"}
                        </Button>
                        {!isCreate && (
                            <Button
                                onClick={() => setIsDeleteModalOpen(true)}
                                danger
                                size="large"
                            >
                                Delete
                            </Button>
                        )}
                    </Row>
                </Form.Item>
            </Form>
            <ConfirmDeleteAlert
                message={`This action cannot be undone and will remove the ${activePrepStation?.name} prep station from all devices.`}
                open={isDeleteModalOpen}
                close={() => setIsDeleteModalOpen(false)}
                onContinue={onDeleteClick}
                deleteText="Delete"
            />
        </div>
    );
};

const bodyStyle = css`
    .ant-select-multiple .ant-select-selection-item-remove svg {
        margin-bottom: 4px;
    }
    h3 {
        font-weight: bold;
    }
    label {
        font-weight: bold;
    }
`;

export default PrepStationDetail;
