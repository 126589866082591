import {
    IPurchase,
    IStore,
    POSIntegration,
    READABLE_THIRD_PARTY_MAPPING,
    SnackpassTimezoneEnum,
    ThirdPartySource,
    ThirdPartyUser
} from "@snackpass/snackpass-types";
import split from "graphemesplit";
import {
    CountryCode,
    formatNumber,
    ParsedNumber,
    parseNumber
} from "libphonenumber-js";
import { capitalize } from "lodash";
import _ from "lodash/fp";
import { toast } from "sonner";

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

export function normalizePhoneNumber(
    phoneNumber: string,
    countryCode?: CountryCode
): string | null {
    if (!phoneNumber) {
        return null;
    }
    const parsed = parseNumber(
        phoneNumber,
        countryCode ?? "US"
    ) as ParsedNumber;
    if (parsed.phone) {
        if (countryCode && countryCode !== "US") {
            return formatNumber(parsed, "INTERNATIONAL");
        } else {
            return formatNumber(parsed, "NATIONAL");
        }
    }
    return null;
}

/**
 * @param {number} maxValue - maxValue to calculate chart width with
 * @param {boolean} isNumber - true type of label for y-axis is a number, false if not (e.g. store name)
 * @return {number} width - calculated y axis width
 */
export function calculateChartWidth(
    maxValue: number,
    isNumber: boolean
): number {
    const widthIncrement = isNumber ? 10 : 8;

    if (isNumber) {
        const baseWidth = 52; // base width for 2 digits
        const maxValString = maxValue.toString();
        const netSalesLength = maxValString.split(".")[0].length;
        let additionalDigits = Math.max(netSalesLength - 2, 0); // number of digits added
        if (Number(maxValString.charAt(0)) >= 8) {
            //  top y axis digit increases by 1 if first digit is greater than 8.
            additionalDigits++;
        }
        return baseWidth + additionalDigits * widthIncrement;
    }

    return maxValue * widthIncrement;
}

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const replaceZeros = (
    value: any,
    showZero = false,
    inPlaceOfZero = ""
) => {
    if (!showZero && !value) {
        return inPlaceOfZero;
    }
    return value;
};

/**
 * capitalize the first letter of a string
 * @param str
 */

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const capitalizeFirstChar = (str?: string) => {
    if (str === undefined) return "";

    const regex = /^(.*?)(?=[a-zA-Z])/g;
    const match = str.match(regex);
    const i = _.propOr("", 0, match).length; // index of first char

    return str.slice(0, i) + str.charAt(i).toUpperCase() + str.slice(i + 1);
};

/**
 * capitalizes every word of a given string
 * @param str
 */

export const getStoreTimezone = (store: IStore | null) => {
    if (!store || !store.hours) {
        return SnackpassTimezoneEnum.newYork;
    }

    return store.hours.zone as SnackpassTimezoneEnum;
};

// example: Kevin Tan -> Kevin T

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const nameToFirstNameLastInitial = (name?: string | null) => {
    const names = (name || "").split(" ");
    let updatedName = "";
    if (names[0]) {
        updatedName += names[0];
    }
    if (names[1]) {
        // We use graphemesplit here because user names can be any Unicode
        // character (e.g. emoji).
        updatedName += " " + split(names[1])[0];
    }
    return updatedName;
};

/**
 * Given a IPurchase, checks if it is from an integration and if so returns its user info
 * @param purchase
 * @returns information of the user to which it belongs or undefined
 */
export const getPOSIntegrationUserInfo = (
    purchase: IPurchase | null
):
    | {
          user?: ThirdPartyUser;
      }
    | undefined => {
    if (!purchase || !purchase.thirdPartyOrderInfo) return undefined;

    const isIntegration = (key: string) =>
        (Object.values(POSIntegration) as string[]).includes(key);
    const integration = Object.keys(purchase.thirdPartyOrderInfo).find((key) =>
        isIntegration(key)
    );

    const integrationInfo = integration
        ? purchase.thirdPartyOrderInfo[integration as POSIntegration]
        : undefined;
    if (!integrationInfo || !integrationInfo?.user) return undefined;
    else {
        const { address, ...userInfo } = integrationInfo.user;
        if (!userInfo.name) userInfo.name = "Anonymous";
        return { user: userInfo };
    }
};

export const copyToClipboard = (textToCopy: string) => {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = textToCopy;
    input.select();
    document.execCommand("copy");
    input.remove();
};

export const copyAndNotify = ({
    field,
    value
}: {
    field: string;
    value: string;
}) => {
    copyToClipboard(value);
    value
        ? toast.success(`Copied Device ${field}`, {
              description: value
          })
        : null;
};

export const formatTransactionSource = (
    transactionSource?: string,
    isPOSMode: boolean = false
) => {
    if (!transactionSource) return "";
    if (transactionSource === "kiosk" && isPOSMode === true) return "Register";
    if (transactionSource === "thirdParty") return "Integration";
    return capitalize(transactionSource);
};

export const formatPurchaseChannel = (purchase?: IPurchase) => {
    if (purchase?.thirdPartyOrderInfo?.deliverect?.channel)
        return purchase?.thirdPartyOrderInfo?.deliverect?.channel;
    return READABLE_THIRD_PARTY_MAPPING[
        purchase?.thirdPartySource || ThirdPartySource.SNACKPASS
    ];
};

export const purchaseIsThirdPartyDeliverect = (purchase: IPurchase): boolean =>
    !!purchase.thirdPartyOrderInfo?.deliverect;
