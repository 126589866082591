import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import {
    RDBPermissions,
    RDBPermissionsProps
} from "#settings/settings-permissions/admin-view-edit-dashboard-permissions";
import {
    SnackOSPermissions,
    SnackOSPermissionsProps
} from "#settings/settings-permissions/admin-view-edit-snackos-permissions";
import {
    PermissionsPresets,
    RDBUserRole,
    PermissionSets
} from "#settings/settings-permissions/types";
import AdminRoleSelectItem from "#settings/settings-permissions/select-item";

interface PermissionsControls {
    rdbToggles: RDBPermissionsProps["toggles"];
    setRDBToggles: RDBPermissionsProps["setToggles"];
    snackOSToggles: SnackOSPermissionsProps["toggles"];
    setSnackOSToggles: SnackOSPermissionsProps["setToggles"];
    hasEmail: boolean;
    hidePayouts: boolean;
}

export const PermissionsControls = ({
    rdbToggles,
    setRDBToggles,
    snackOSToggles,
    setSnackOSToggles,
    hasEmail,
    hidePayouts
}: PermissionsControls) => {
    const handleRolePresetSelection = (role: string) => {
        const permissions = PermissionSets[role as RDBUserRole];
        setRDBToggles(permissions.rdb);
        setSnackOSToggles(permissions.snackos);
    };

    return (
        <>
            <div className="my-4 text-large font-semibold">Permissions</div>
            <div className="pt-4 font-medium">Presets</div>
            <div className="flex flex-col justify-center border-b border-neutral-400 pb-6">
                <div className="mb-4 text-small text-neutral-500">
                    Assign a permissions preset and/or you can adjust individual
                    permissions below
                </div>
                <Select onValueChange={handleRolePresetSelection}>
                    <SelectTrigger className="border-neutral-400">
                        <SelectValue placeholder="Choose Preset" />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                        <SelectGroup>
                            <SelectLabel>Presets</SelectLabel>
                            {Object.entries(PermissionsPresets).map(
                                ([key, { name, desc }]) => (
                                    <AdminRoleSelectItem
                                        key={key}
                                        value={key}
                                        name={name}
                                        description={desc}
                                    />
                                )
                            )}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            {!hasEmail && (
                <Alert variant="warning">
                    <AlertTitle className="text-base">
                        <ExclamationTriangleIcon className="mr-2" />
                        Security Notice
                    </AlertTitle>
                    <AlertDescription className="text-sm">
                        Users must have an email address to be assigned
                        permissions
                    </AlertDescription>
                </Alert>
            )}
            <RDBPermissions
                toggles={rdbToggles}
                setToggles={setRDBToggles}
                hidePayouts={hidePayouts}
            />
            <div className="py-4 font-medium">SnackOS - Register</div>
            <SnackOSPermissions
                toggles={snackOSToggles}
                setToggles={setSnackOSToggles}
            />
        </>
    );
};
