import { ColumnDef } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { Link, LinkProps, generatePath, useHistory } from "react-router-dom";
import { Merge } from "type-fest";
import Skeleton from "react-loading-skeleton";

import {
    DeliveryStatusPill,
    campaignDeliveryStatusDisplay
} from "#guestbook/shared-components/delivery-status-pill";
import GuestbookTooltip from "#guestbook/shared-components/GuestbookTooltip";
import { Routes } from "#navigation/routes";
import { datetimeFormat } from "#utils/datetime-format";
import { cn } from "src/@/lib/utils";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableDateFilterValue } from "src/@/components/ui/data-table/table-date-filter";
import {
    Campaign,
    CampaignMetricsChannelCluster,
    CampaignMetricsEventTypeCluster,
    CampaignWithMetricsFieldsFragment,
    useGetStoreCampaignsWithMetricsQuery
} from "src/api/graphql/generated/types";
import { getActiveStore, useActiveUser } from "src/redux/selectors";
import { ApolloErrorCard } from "#guestbook/screens/Campaigns/ApolloErrorCard";
import { useAppSelector } from "src/redux/hooks";

export function campaignTitleDisplay({
    message,
    id
}: Pick<Campaign, "message" | "id">) {
    return _.capitalize(message.title || `#${id}`);
}

export function campaignSegmentDisplay({
    audienceType
}: Pick<Campaign, "audienceType">) {
    return audienceType.split("_").map(_.capitalize).join(" ");
}

const toRowModel =
    (campaignType: "sms" | "push") =>
    (campaign: CampaignWithMetricsFieldsFragment) => ({
        ...campaign,
        href: generatePath(
            campaignType === "sms"
                ? Routes.GuestbookCampaignsSMSDetails
                : Routes.GuestbookCampaignsPushDetails,
            {
                campaignId: campaign.id
            }
        )
    });

type RowModel = ReturnType<ReturnType<typeof toRowModel>>;

type PastCampaignsProps = {
    campaignType: "sms" | "push";
};

export function PastCampaigns({ campaignType }: PastCampaignsProps) {
    const activeStore = useAppSelector(getActiveStore);
    const user = useActiveUser();

    const {
        data,
        error,
        loading: campaignsLoading
    } = useGetStoreCampaignsWithMetricsQuery({
        variables: {
            storeId: activeStore?._id ?? "",
            newStyle: user?.snackpassPermissions.isSnackpassEmployee
                ? undefined
                : true
        },
        skip: !activeStore,
        fetchPolicy: "no-cache"
    });

    const history = useHistory();

    const campaigns = data?.campaigns.map(toRowModel(campaignType)) ?? [];

    if (campaignsLoading) {
        return (
            <div className="flex flex-col gap-1 py-1 sm:p-4">
                {_.range(10).map((index) => (
                    <Skeleton
                        key={index}
                        className="h-8 rounded-none sm:rounded"
                    />
                ))}
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center p-4 sm:p-8">
                <ApolloErrorCard
                    error={error}
                    title="Campaign list load error:"
                />
            </div>
        );
    }

    return (
        <div className="flex-1 bg-white p-4 sm:px-12">
            <DataTable
                showPagination
                getRowClickListener={(row) => (event) => {
                    if (
                        row.original.href !== history.location.pathname &&
                        !event.ctrlKey &&
                        !event.metaKey
                    ) {
                        history.push(row.original.href);
                    }
                }}
                columns={
                    [
                        {
                            id: "campaign",
                            header() {
                                return (
                                    <div className="px-4 py-[11px]">
                                        Campaign Name
                                    </div>
                                );
                            },
                            accessorFn: campaignTitleDisplay,
                            cell(props) {
                                return (
                                    <CampaignLink
                                        className="block px-4 py-[11px] no-underline"
                                        campaign={props.row.original}
                                    >
                                        {props.cell.getValue()}
                                    </CampaignLink>
                                );
                            },
                            filterFn: "includesString"
                        },
                        {
                            id: "status",
                            header() {
                                // padding aligns the text with status pills
                                return <div className="pl-2">Status</div>;
                            },
                            accessorFn(campaign) {
                                return campaign.status;
                            },
                            cell(props) {
                                return (
                                    <CampaignLink campaign={props.row.original}>
                                        <DeliveryStatusPill
                                            deliveryStatus={
                                                props.row.original
                                                    .deliveryStatus
                                            }
                                        />
                                    </CampaignLink>
                                );
                            },
                            filterFn: "arrIncludesSome"
                        },
                        {
                            id: "segment",
                            header: "Segment",
                            accessorFn(campaign) {
                                return campaign.audienceType;
                            },
                            cell(props) {
                                return (
                                    <CampaignLink campaign={props.row.original}>
                                        {campaignSegmentDisplay(
                                            props.row.original
                                        )}
                                    </CampaignLink>
                                );
                            },
                            filterFn: "arrIncludesSome"
                        },
                        {
                            id: "createdAt",
                            header: "Created Date",
                            cell(props) {
                                return (
                                    <CampaignLink campaign={props.row.original}>
                                        <GuestbookTooltip
                                            tooltip={`Created ${datetimeFormat(
                                                props.row.original.createdAt,
                                                "short-medium"
                                            )}`}
                                        >
                                            <span>
                                                {format(
                                                    parseISO(
                                                        props.row.original
                                                            .createdAt
                                                    ),
                                                    "MMM d"
                                                )}
                                            </span>
                                        </GuestbookTooltip>
                                    </CampaignLink>
                                );
                            },
                            filterFn(
                                row,
                                _columnId,
                                filterValue: DataTableDateFilterValue
                            ) {
                                return filterValue.match(
                                    parseISO(row.original.createdAt)
                                );
                            }
                        },
                        {
                            id: "messagesSent",
                            header: "Messages Sent",
                            accessorFn(campaign) {
                                return campaign.metrics.sendCount;
                            },
                            cell(props) {
                                return (
                                    <CampaignLink campaign={props.row.original}>
                                        {props.cell.getValue()}
                                    </CampaignLink>
                                );
                            }
                        },
                        {
                            id: "attributedSales",
                            header: "Attributed Sales",
                            accessorFn(campaign) {
                                return campaign.metrics.attributedSales;
                            },
                            cell(props) {
                                const value = props.cell.getValue() as number;
                                return (
                                    <CampaignLink campaign={props.row.original}>
                                        ${(value ?? 0).toFixed(2)}
                                    </CampaignLink>
                                );
                            }
                        }
                    ] satisfies ColumnDef<RowModel, string | number>[]
                }
                data={campaigns}
                toolbar={{
                    className: "px-2 sm:px-0",
                    filters: [
                        {
                            key: "status",
                            title: "Status",
                            options: _.uniqBy(campaigns, "status").map(
                                (campaign) => ({
                                    label: campaignDeliveryStatusDisplay(
                                        campaign
                                    ),
                                    value: campaign.status
                                })
                            )
                        },
                        {
                            key: "segment",
                            title: "Segment",
                            options: _.uniqBy(campaigns, "audienceType").map(
                                (campaign) => ({
                                    label: campaignSegmentDisplay(campaign),
                                    value: campaign.audienceType
                                })
                            )
                        },
                        {
                            key: "createdAt",
                            title: "Created",
                            localStorage: {
                                key: "campaigns.createdAt",
                                storeScoped: true
                            },
                            defaultDateComparison: "after",
                            valueFormatString: "MMM d",
                            prompt: "pick a date"
                        }
                    ],
                    search: {
                        key: "campaign",
                        inputClassName: "w-30"
                    },
                    showColumnFilter: true
                }}
            />
        </div>
    );
}

export function metricsClusterSum(cluster: CampaignMetricsChannelCluster) {
    return cluster.none + cluster.push + cluster.sms;
}

export function metricsOptOutRate(numberOf: CampaignMetricsEventTypeCluster) {
    const optoutCount = metricsClusterSum(numberOf.optout);
    const deliveredCount = Math.max(
        metricsClusterSum(numberOf.delivered),
        optoutCount
    );
    if (!optoutCount || !deliveredCount) {
        return 0;
    }
    return optoutCount / deliveredCount;
}

type CampaignLinkProps = Merge<
    Omit<LinkProps, "to">,
    {
        campaign: Pick<RowModel, "href">;
    }
>;

function CampaignLink({ campaign, className, ...props }: CampaignLinkProps) {
    return (
        <Link
            className={cn("no-underline text-black", className)}
            to={campaign.href}
            {...props}
        />
    );
}
