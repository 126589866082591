import React from "react";
import { toast } from "sonner";

import CheckBox from "#settings/settings-permissions/checkbox";
import { SnackpassPermissions } from "#settings/settings-permissions/types";
import { ToggleReadWriteNone } from "#settings/settings-permissions/toggle-read-write-none";
import { useHasEditAdminForActiveStore } from "#hooks/use-has-edit-admin-for-active-store";
import { useAppSelector } from "src/redux/hooks";
import { getUserSeniorSupportPermission } from "src/redux/selectors";

export type RDBPermissionsProps = {
    toggles: SnackpassPermissions;
    setToggles: React.Dispatch<React.SetStateAction<SnackpassPermissions>>;
    hidePayouts?: boolean;
};

export const RDBPermissions = ({
    toggles,
    setToggles,
    hidePayouts = false
}: RDBPermissionsProps) => {
    const canEdit = useHasEditAdminForActiveStore();
    const canEditPayouts = useAppSelector(getUserSeniorSupportPermission);

    const handleToggle = (permissionKey: keyof typeof toggles) => {
        setToggles((toggles) => ({
            ...toggles,
            [permissionKey]: !toggles[permissionKey]
        }));
    };

    const handle3WayToggleChange = (
        readPermKey: string,
        writePermKey: string,
        read: boolean,
        write: boolean
    ) => {
        setToggles((toggles) => ({
            ...toggles,
            [readPermKey]: read,
            [writePermKey]: write
        }));
    };

    return (
        <>
            <div className="py-4 font-medium">Dashboard</div>
            <CheckBox
                title="Dashboard Page"
                onClick={() => handleToggle("hasDashboard")}
                isChecked={!!toggles.hasDashboard}
            />
            <CheckBox
                title="Guestbook"
                onClick={() => handleToggle("hasGuestbook")}
                isChecked={!!toggles.hasGuestbook}
            />
            <CheckBox
                title="Reports → Locations"
                onClick={() => handleToggle("hasReportsLocations")}
                isChecked={!!toggles.hasReportsLocations}
            />
            <CheckBox
                title="Reports → Sales"
                onClick={() => handleToggle("hasSalesReports")}
                isChecked={!!toggles.hasSalesReports}
            />
            <CheckBox
                title="Reports → Menu"
                onClick={() => handleToggle("hasReportsMenu")}
                isChecked={!!toggles.hasReportsMenu}
            />
            <CheckBox
                title="Reports → Customers"
                onClick={() => handleToggle("hasReportsCustomers")}
                isChecked={!!toggles.hasReportsCustomers}
            />
            <CheckBox
                title="Reports → Promotions"
                onClick={() => handleToggle("hasReportsPromotions")}
                isChecked={!!toggles.hasReportsPromotions}
            />
            <CheckBox
                title="Reports → Financial"
                onClick={() => handleToggle("hasFinancialReports")}
                isChecked={!!toggles.hasFinancialReports}
            />
            <CheckBox
                title="Reports → Labor"
                onClick={() => handleToggle("hasReportsLabor")}
                isChecked={!!toggles.hasReportsLabor}
            />
            <CheckBox
                title="Reports → Gift Cards"
                onClick={() => handleToggle("hasReportsGiftCards")}
                isChecked={!!toggles.hasReportsGiftCards}
            />
            <CheckBox
                title="Orders"
                onClick={() => handleToggle("hasOrders")}
                isChecked={!!toggles.hasOrders}
            />
            <CheckBox
                title="Menu"
                onClick={() => handleToggle("hasMenuEditing")}
                isChecked={!!toggles.hasMenuEditing}
            />
            <CheckBox
                title="Catering"
                onClick={() => handleToggle("hasCatering")}
                isChecked={!!toggles.hasCatering}
            />
            <CheckBox
                title="Promos"
                onClick={() => handleToggle("hasPromos")}
                isChecked={!!toggles.hasPromos}
            />
            <CheckBox
                title="Team"
                onClick={() => handleToggle("hasEmployees")}
                isChecked={!!toggles.hasEmployees}
            />
            <CheckBox
                title="Devices"
                onClick={() => handleToggle("hasDevices")}
                isChecked={!!toggles.hasDevices}
            />
            <CheckBox
                title="Create, Edit, and Change Permissions for All Users"
                onClick={() => handleToggle("hasEditAdmin")}
                isChecked={toggles.hasEditAdmin ?? false}
            />
            {!hidePayouts && (
                <CheckBox
                    title="Payouts"
                    isChecked={!!toggles.hasPayouts}
                    disabled={!canEditPayouts}
                    tooltip={
                        canEditPayouts
                            ? undefined
                            : "Please contact partners@snackpass.co to change this setting."
                    }
                    onClick={() => {
                        if (!canEditPayouts) {
                            toast.warning(
                                "Please contact partners@snackpass.co to change this setting."
                            );
                            return;
                        }
                        handleToggle("hasPayouts");
                    }}
                />
            )}
            <ToggleReadWriteNone
                title="Billing"
                readPerm={!!toggles.hasBilling}
                writePerm={!!toggles.hasBillingWrite}
                onSetNone={() =>
                    handle3WayToggleChange(
                        "hasBilling",
                        "hasBillingWrite",
                        false,
                        false
                    )
                }
                onSetRead={() =>
                    handle3WayToggleChange(
                        "hasBilling",
                        "hasBillingWrite",
                        true,
                        false
                    )
                }
                onSetWrite={() =>
                    handle3WayToggleChange(
                        "hasBilling",
                        "hasBillingWrite",
                        true,
                        true
                    )
                }
                disabled={!canEdit}
            />
            <ToggleReadWriteNone
                title="Store Settings"
                readPerm={!!toggles.hasSettings}
                writePerm={!!toggles.hasSettingsWrite}
                onSetNone={() =>
                    handle3WayToggleChange(
                        "hasSettings",
                        "hasSettingsWrite",
                        false,
                        false
                    )
                }
                onSetRead={() =>
                    handle3WayToggleChange(
                        "hasSettings",
                        "hasSettingsWrite",
                        true,
                        false
                    )
                }
                onSetWrite={() =>
                    handle3WayToggleChange(
                        "hasSettings",
                        "hasSettingsWrite",
                        true,
                        true
                    )
                }
                disabled={!canEdit}
            />
        </>
    );
};
