/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Addon, AddonGroup, ScreenState } from "@snackpass/snackpass-types";
import _ from "lodash";
import styled from "styled-components";

import "antd/dist/antd.css";
import { Alert, Button, Col, Divider, Drawer, Form, Input } from "antd";
import { SystemColors } from "@snackpass/design-system";
import { PartialDeep } from "type-fest";
import fp from "lodash/fp";
import classNames from "classnames";
import { toast } from "sonner";

import constants from "#core/constants";
import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
// @ts-ignore
import Switch from "#reusable/input/toggle-input";
import DropDownSelect from "#reusable/select/dropdown";
import { ReactComponent as Duplicate } from "src/assets/icons/duplicate.svg";
import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import ModifierOptions from "#menu-editor/mobile-friendly/items/modifier-options/modifiers-options";
import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import { isServerGeneratedId } from "#menu-editor/mobile-friendly/helpers/utils";
import colors from "#reusable/colors/colors.json";
import { getActiveStoreIs3PIntegrated } from "src/redux/selectors";

import {
    ProductEditSharedContext,
    ProductNewSharedContext
} from "../helpers/context";

type ModifierGroupType = {
    isProductInEditMode: boolean;
    menuAutomation: MenuAutomation;
};

const isAddonsHaveUniqueNames = (data: PartialDeep<Addon>[]) => {
    const uniqueAddonNames = _.uniqBy(data, "name");
    if (data.length === uniqueAddonNames.length) {
        return true;
    }
    return false;
};

const isAddonGroupNameTaken = (
    checkList: (PartialDeep<AddonGroup> | PartialDeep<Addon>)[],
    newName: string,
    editMode: boolean,
    nameBeforeEdit?: string
) => {
    const occurrenceMap = _.countBy(checkList, "name");
    let nameTaken = false;
    if (editMode) {
        if (newName !== nameBeforeEdit && occurrenceMap[newName]) {
            nameTaken = true;
        } else if (occurrenceMap[newName] > 1) {
            // to fix legacy same name issue
            nameTaken = true;
        }
    }

    if (!editMode && occurrenceMap[newName]) {
        nameTaken = true;
    }

    return nameTaken;
};

const ModifierGroup: React.FC<ModifierGroupType> = ({
    isProductInEditMode,
    menuAutomation
}) => {
    const {
        setShowNewModifierForm,
        isModifierGroupInEdit,
        modifierGroupInEdit,
        modifierGroups,
        showBottomDrawer,
        setShowBottomDrawer,
        setModifierGroups,
        setDeletedAddonGroups,
        addDeletedAddons,
        setIsModifierGroupInEdit
    } = useContext(
        isProductInEditMode ? ProductEditSharedContext : ProductNewSharedContext
    );
    const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);
    const { width } = useWindowDimensions();
    const [form] = Form.useForm();
    const [name, setName] = useState<string | undefined>("");
    const [minSelection, setMinSelection] = useState<{
        label: string;
        value: number;
    } | null>(null);
    const [addons, setAddons] = useState<Addon[]>([]);
    const [maxSelection, setMaxSelection] = useState<{
        label: string;
        value: number;
    } | null>(null);

    const [required, setRequired] = useState<boolean>(false);
    const [supportsMultiple, setSupportsMultiple] = useState<boolean>(false);
    const [limit, setLimit] = useState<number | null>(0);
    const [deletedAddonIds, setDeletedAddonIds] = useState<string[]>([]);

    const maxSelectionOption = useMemo(() => {
        const length = Math.max(limit ?? 12, 12);
        const array: number[] = Array.from({ length: length }, (_, i) => i + 1);
        const options = _.map(array, (item) => ({
            label: item.toString(),
            value: item
        }));
        options.unshift({ label: "No Maximum", value: 0 });
        return options;
    }, [limit]);

    const menuIssues =
        modifierGroupInEdit && modifierGroupInEdit._id
            ? menuAutomation.issuesByAddonGroupId(modifierGroupInEdit._id)
            : null;

    useEffect(() => {
        if (modifierGroupInEdit && isModifierGroupInEdit) {
            setName(modifierGroupInEdit.name);
            modifierGroupInEdit.required
                ? setMinSelection({ label: "1", value: 1 })
                : setMinSelection({ label: "No Minimum", value: 0 });
            setRequired(
                modifierGroupInEdit.required
                    ? modifierGroupInEdit.required
                    : false
            );
            setLimit(
                !modifierGroupInEdit.limit ? 0 : modifierGroupInEdit.limit
            );
            modifierGroupInEdit.limit
                ? setMaxSelection({
                      label: modifierGroupInEdit.limit.toString(),
                      value: modifierGroupInEdit.limit
                  })
                : { label: "No Maximum", value: 0 };

            modifierGroupInEdit.supportsMultiple
                ? setSupportsMultiple(true)
                : setSupportsMultiple(false);

            const addons = modifierGroupInEdit.addons ?? [];
            modifierGroupInEdit.addons ? setAddons(addons) : null;
            setDeletedAddonIds([]);
        }
    }, [isModifierGroupInEdit, modifierGroupInEdit]);

    const handleCreateAddonGroup = () => {
        const newAddOnGroup: PartialDeep<AddonGroup> = {
            name: name,
            required: required,
            limit: limit,
            supportsMultiple: supportsMultiple,
            addons: addons,
            _id: Date.now().toString()
        };

        setModifierGroups([...modifierGroups, newAddOnGroup]);
    };

    const handlePressDuplicate = () => {
        if (modifierGroupInEdit) {
            const modifierGroupsClone: PartialDeep<AddonGroup>[] =
                fp.cloneDeep(modifierGroups);
            modifierGroupsClone.push({
                ...modifierGroupInEdit,
                addons: modifierGroupInEdit.addons?.map((addon) => ({
                    ...addon,
                    _id: Date.now().toString()
                })),
                name: `${modifierGroupInEdit.name}-copy`,
                _id: Date.now().toString()
            });
            setModifierGroups(modifierGroupsClone);
            setShowNewModifierForm(false);
        }
    };
    const handleDeleteItem = () => {
        if (modifierGroupInEdit) {
            const modifierGroupsClone: PartialDeep<AddonGroup>[] = JSON.parse(
                JSON.stringify(modifierGroups)
            );
            const idx = modifierGroupsClone.findIndex(
                (modifierGroup: PartialDeep<AddonGroup>) =>
                    modifierGroup?._id === modifierGroupInEdit?._id
            );
            modifierGroupsClone.splice(idx, 1);
            if (
                modifierGroupInEdit._id &&
                !isServerGeneratedId(modifierGroupInEdit._id)
            )
                setDeletedAddonGroups((prev) => [
                    ...prev,
                    modifierGroupInEdit._id as string
                ]);

            setModifierGroups(modifierGroupsClone);
            setShowNewModifierForm(false);
        }
    };

    const onFinish = () => {
        if (isModifierGroupInEdit && modifierGroupInEdit) {
            const updatedModifierGroups = modifierGroups.map(
                (modifierGroup) => {
                    if (modifierGroup._id === modifierGroupInEdit._id) {
                        return {
                            ...modifierGroup,
                            name,
                            required,
                            limit,
                            supportsMultiple,
                            addons
                        };
                    }
                    return modifierGroup;
                }
            );
            setModifierGroups(updatedModifierGroups);
            if (
                modifierGroupInEdit._id &&
                !isServerGeneratedId(modifierGroupInEdit._id)
            )
                addDeletedAddons(deletedAddonIds, modifierGroupInEdit._id);
            setShowNewModifierForm(false);
            setIsModifierGroupInEdit(false);
        } else if (!isModifierGroupInEdit) {
            handleCreateAddonGroup();
            setShowNewModifierForm(false);
            setShowBottomDrawer(false);
        }
    };
    const onFinishFailed = () => {
        toast.error("Failed to create new modifier group");
    };
    const validateMessages = {
        required: "${label} is required!",
        types: {
            name: "${label} is not valid",
            number: "${label} is not a valid number!"
        },
        number: {
            min: "${label} cannot be less than ${min}"
        }
    };

    return (
        <Body>
            <HeaderRow>
                <span className="header-text">
                    <Chevron
                        fill={SystemColors.v1.sesame}
                        className="clickable-icon"
                        onClick={() => {
                            setShowNewModifierForm(false);
                        }}
                    />
                    {!thirdPartyEnabled && (
                        <>
                            <span className="head-text-span">
                                {isModifierGroupInEdit
                                    ? "Edit Modifier"
                                    : "Create New Modifier"}
                            </span>
                            {isModifierGroupInEdit ? (
                                <span className="iconWrapper">
                                    <Duplicate
                                        className="duplicate-icon"
                                        onClick={() => handlePressDuplicate()}
                                    />
                                </span>
                            ) : null}
                            {isModifierGroupInEdit ? (
                                <span className="iconWrapper">
                                    <Delete
                                        className="delete-icon"
                                        onClick={handleDeleteItem}
                                    />
                                </span>
                            ) : null}
                        </>
                    )}
                </span>
            </HeaderRow>
            <Divider />
            {menuIssues?.map((issue, i) => (
                <Alert
                    key={i}
                    className={classNames({
                        banner: true,
                        warning: issue.severity !== "critical",
                        error: issue.severity === "critical"
                    })}
                    message={issue.description}
                    type={issue.severity === "critical" ? "error" : "warning"}
                    showIcon
                    closable
                />
            ))}
            <Form
                form={form}
                layout="vertical"
                className="mt12"
                name="nest-messages"
                requiredMark={true}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                validateMessages={validateMessages}
                fields={[
                    {
                        name: ["name"],
                        value: name
                    }
                ]}
            >
                {/* Different terminologies between IProduct property & form fields here: 
                min selection === required === true/false; 
                max selection === limit === null/number 
                rules: 
                1. Any required add on group should have at least one free add on.
                2. Multiple selection cannot be enabled if addon group limit is 1. 
                */}
                <Form.Item label="Modifier Details">
                    <Form.Item
                        name="name"
                        noStyle
                        rules={[
                            {
                                required: true
                            },
                            {
                                async validator(_, value) {
                                    if (
                                        isAddonGroupNameTaken(
                                            modifierGroups,
                                            value,
                                            isModifierGroupInEdit,
                                            modifierGroupInEdit?.name
                                        )
                                    ) {
                                        return Promise.reject(
                                            "This modifier group name is already taken in this product"
                                        );
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Input
                            disabled={thirdPartyEnabled}
                            placeholder="Modifier Group Name"
                            size="large"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Requirements">
                    <Extra>
                        Set Min Selection to 1 to make this modifier required.
                    </Extra>
                    <Row>
                        <Col span={11}>
                            <Form.Item name="required" label="Min Selection">
                                <DropDownSelect
                                    disabled={thirdPartyEnabled}
                                    square
                                    options={[
                                        { label: "No Minimum", value: 0 },
                                        { label: "1", value: 1 }
                                    ]}
                                    value={minSelection}
                                    onChange={(selectedOption: {
                                        label: string;
                                        value: number;
                                    }) => {
                                        setMinSelection(selectedOption);
                                        if (selectedOption.value === 0) {
                                            setRequired(false);
                                        } else {
                                            setRequired(true);
                                        }
                                    }}
                                    defaultValue={
                                        isModifierGroupInEdit
                                            ? modifierGroupInEdit?.required
                                                ? { label: "1", value: 1 }
                                                : {
                                                      label: "No Minimum",
                                                      value: 0
                                                  }
                                            : {
                                                  label: "No Minimum",
                                                  value: 0
                                              }
                                    }
                                ></DropDownSelect>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="limit"
                                label="Max Selection"
                                rules={[
                                    {
                                        async validator(_, value) {
                                            if (
                                                value &&
                                                !Number.isInteger(
                                                    Number(value.value)
                                                )
                                            ) {
                                                return Promise.reject(
                                                    "Max selection must be a integer"
                                                );
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <DropDownSelect
                                    disabled={thirdPartyEnabled}
                                    isCreatable
                                    square
                                    options={maxSelectionOption}
                                    value={maxSelection}
                                    onChange={(selectedOption: {
                                        label: string;
                                        value: number;
                                    }) => {
                                        setMaxSelection(selectedOption);
                                        setLimit(selectedOption?.value || 0);
                                    }}
                                    defaultValue={
                                        isModifierGroupInEdit &&
                                        modifierGroupInEdit?.limit
                                            ? {
                                                  label: modifierGroupInEdit?.limit.toString(),
                                                  value: modifierGroupInEdit?.limit
                                              }
                                            : {
                                                  label: "No Maximum",
                                                  value: 0
                                              }
                                    }
                                ></DropDownSelect>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label="Additional Options">
                    <RowForSwitch>
                        <Label>Multi-Select</Label>
                        <Form.Item
                            name="supportsMultiple"
                            noStyle
                            rules={[
                                {
                                    async validator() {
                                        if (
                                            limit &&
                                            supportsMultiple &&
                                            limit === 1
                                        ) {
                                            return Promise.reject(
                                                " Multiple selection cannot be enabled if maximum selectionOption is 1 "
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Switch
                                disabled={thirdPartyEnabled}
                                id="supportsMultiple"
                                onChange={() => {
                                    setSupportsMultiple(!supportsMultiple);
                                }}
                                isOn={supportsMultiple}
                            ></Switch>
                        </Form.Item>
                    </RowForSwitch>
                    <span className="tooltip-description">
                        Customer can select single option more than once
                    </span>
                </Form.Item>
                <Form.Item
                    name="modifiers"
                    label="Modifiers"
                    className="modifiers"
                    rules={[
                        {
                            async validator() {
                                if (!isAddonsHaveUniqueNames(addons)) {
                                    return Promise.reject(
                                        "Modifier options can not have duplicate names"
                                    );
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                ></Form.Item>
                <ModifierOptions
                    addons={addons}
                    setAddons={setAddons}
                    modifierOptions={addons}
                    setDeletedAddonIds={setDeletedAddonIds}
                />
                {!thirdPartyEnabled && (
                    <>
                        <NewModifierOption
                            onClick={() => {
                                const newAddon: Addon = {
                                    name: "New Option",
                                    price: 0,
                                    _id: Date.now().toString(),
                                    soldOut: false,
                                    soldOutToday: false,
                                    soldOutDates: {
                                        from: null,
                                        until: null
                                    }
                                };
                                setAddons([...addons, newAddon]);
                            }}
                        >
                            <span className="new-option">+ New Option</span>
                        </NewModifierOption>

                        <Row className="flexWrap f1 mt6">
                            <Button
                                type="primary"
                                className="save-btn"
                                htmlType="submit"
                                shape="round"
                                size="large"
                            >
                                Save Modifiers
                            </Button>
                        </Row>
                    </>
                )}
            </Form>

            <Drawer
                width={width < constants.MOBILE_MAX_WIDTH ? width : width / 2}
                placement="right"
                visible={showBottomDrawer}
                closable={false}
                destroyOnClose={true}
                forceRender={true}
            ></Drawer>
        </Body>
    );
};

const Body = styled.div`
    width: 100%;
    overflow-x: "auto";
    padding-bottom: 10rem;
    position: relative;
    z-index: 5;
    font-family: "Inter" !important;

    input {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    @media ${ScreenState.MOBILE} {
        margin-bottom: 10rem;
    }

    .banner {
        margin-top: 10px;
        border-left: 6px solid ${SystemColors.v1.candy80};
        border-radius: 8px;
    }
    .banner.error {
        margin-top: 25px;
        color: ${SystemColors.v1.melon50};
        border-left: 6px solid ${SystemColors.v1.melon50};
        border-radius: 8px;
    }
    .banner.warning {
        margin-top: 25px;
        color: ${SystemColors.v1.macaroni40};
        border-left: 6px solid ${SystemColors.v1.macaroni40};
        border-radius: 8px;
    }

    .clickable-icon {
        cursor: pointer;
        margin-right: 8px;
    }

    .mt6 {
        margin-top: 12px;
    }

    .mt12 {
        margin-top: 12px;
    }

    .mt18 {
        margin-top: 18px;
    }

    .ml24 {
        margin-left: 24px;
    }

    .align-start {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .w100 {
        width: 100%;
    }

    .input-number {
        border-radius: 8px;
        width: 100%;
    }
    .ant-form-item {
        margin-bottom: 12px;
    }
    .ant-input-number-handler-wrap {
        display: none;
    }

    input {
        border-radius: 8px;
        width: 100%;
    }
    .f2 {
        flex: 2;
    }
    .f1 {
        flex: 1 !important;
    }

    .flexWrap {
        flex-wrap: wrap;
    }
    .ant-form label {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #282d32;
        font-size: 16px;
    }
    .ant-col.ant-form-item-label {
        padding-bottom: 1px;
        padding-top: 1px;
    }
    .ant-row .ant-form-item {
        font-size: 16px;
    }

    .ant-input {
        border-radius: 8px;
        ::placeholder {
            font-size: 16px;
        }
    }

    .save-btn {
        flex: auto;
        background: ${SystemColors.v1.candy50};
        color: ${SystemColors.v1.white};
        padding: 2px 14px;
        cursor: pointer;
        height: 39px;
        white-space: nowrap;
        display: inline-block;
        margin-top: 5rem;
    }

    .ant-btn-primary {
        &:hover {
            color: ${SystemColors.v1.white} !important;
            background: ${SystemColors.v1.candy50} !important;
        }
    }
    .add {
        font-size: 2rem;
        grid-column: 3;
        padding-left: 1.5rem;
        grid-row: 1;
    }
    .new-modifier-group {
        grid-column: 1;
        margin-left: 1.4rem;
        grid-row: 1;
    }
    .new-modifier-description {
        grid-column: 1/2;
        grid-row: 1;
        margin-left: 1.4rem;
        font-size: 16px;
        padding-top: 3rem;
        color: #606c76;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .tooltip-description {
        color: #606c76;
        font-size: 16px;
    }
    .ant-row .ant-form-item {
        margin-bottom: 0;
    }

    .modifiers {
        margin-bottom: 2px;
    }

    .modifiers .ant-form-item-control-input {
        display: none;
    }
`;

const Row = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const HeaderRow = styled.div`
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
    }

    .head-text-span {
        align-self: center;
        flex: 1;
    }
    .iconWrapper {
        display: flex;
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 50%;
        text-align: center;
        justify-content: center;
        height: 1.8rem;
        width: 1.8rem;
        margin-left: 5px;
    }

    .delete-icon {
        height: 1rem;
        width: 1rem;
        align-self: center;
    }
    .duplicate-icon {
        height: 1rem;
        width: 1rem;
        align-self: center;
    }

    .delete-icon path {
        fill: #ff3929;
    }
    .duplicate-icon path {
        fill: #282d32;
    }
`;

const Label = styled.div`
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #282d32;
`;

const RowForSwitch = styled(Row)`
    justify-content: space-between;
    padding-top: 0.8rem;
`;

const NewModifierOption = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: auto repeat(6, minmax(1.3rem, 1fr)) auto auto auto;
    grid-auto-rows: minmax(1.5rem, auto);
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 12px;
    padding: 10px 10px;
    cursor: pointer;
    position: relative;
    .new-option {
        grid-column: 1;
        grid-row: 1/2;
        color: #a0adba;
    }
    .modifier-option-price {
        position: absolute;
        top: 25%;
        left: 82%;
        color: #a0adba;
        font-size: 14px;
        @media ${ScreenState.TABLET} {
            left: 70%;
        }
        @media ${ScreenState.MOBILE} {
            left: 80%;
        }
    }

    .duplicate-icon {
        grid-column: 8;
        grid-row: 1/2;
        margin-top: 3px;
        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }

    .duplicate-icon path {
        fill: #a0adba;
    }

    .delete-icon {
        grid-column: 9;
        grid-row: 1/2;
        margin-top: 3px;
        margin-left: 8px;
        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }

    .delete-icon path {
        fill: #a0adba;
    }
`;

const Extra = styled.span`
    color: ${SystemColors.v2.salt60.light};
    font-family: Inter;
`;

export default ModifierGroup;
