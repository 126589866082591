import { useMemo } from "react";
import { GuardedRoute } from "react-router-guards";

import EditMultiMenus from "#menu-editor/multi-menus/screens/select-items";
import { Menus } from "#menu-editor/multi-menus/screens/menus-desktop";
import {
    CreateMultiMenus,
    MultiMenuSettings
} from "#menu-editor/multi-menus/screens/multi-menu-settings";
import { MultiMenuOverview } from "#menu-editor/multi-menus/screens/menu-overview-desktop";
import { EditOverridesMobile } from "#menu-editor/multi-menus/screens/edit-overrides-mobile";
import {
    useInvoicesEnabled,
    useMenusV3Enabled,
    useMultiMenusEnabled,
    useTablesideEnabled
} from "#navigation/utils";
import { Routes } from "#navigation/routes";
import GiftCardSettings from "#settings/settings-gift-card";
import { Invoices } from "#invoices";
import { InternalSettings } from "#settings/settings-internal";
import { FeeSettings } from "#settings/settings-fees";
import { useAppSelector } from "src/redux/hooks";
import { getUserTeamPermission } from "src/redux/selectors";
import { SeniorSupportSettings } from "#settings/settings-senior-support";
import { Tables } from "#table-definitions";
import { MenuManager } from "#menu-manager";
import { menuManagerRoutes } from "#menu-manager/routes";

export function useConditionalGuardedRoutes() {
    const multiMenusEnabled = useMultiMenusEnabled();
    const invoicesEnabled = useInvoicesEnabled();
    const isSPEmployee = useAppSelector(getUserTeamPermission);
    const tablesideEnabled = useTablesideEnabled();
    const menusV3Enabled = useMenusV3Enabled();

    return useMemo(() => {
        const routes: JSX.Element[] = [];

        if (isSPEmployee) {
            routes.push(
                <GuardedRoute
                    exact
                    path={Routes.InternalSettings}
                    component={InternalSettings}
                />,
                <GuardedRoute
                    exact
                    path={Routes.InternalFees}
                    component={FeeSettings}
                />,
                <GuardedRoute
                    exact
                    path={Routes.InternalSeniorSupport}
                    component={SeniorSupportSettings}
                />
            );
        }

        if (multiMenusEnabled) {
            routes.push(
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenusCreate]}
                    component={CreateMultiMenus}
                />,
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenusSettings]}
                    component={MultiMenuSettings}
                />,
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenus]}
                    component={Menus}
                />,
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenusOutline]}
                    component={MultiMenuOverview}
                />,
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenusEdit]}
                    component={EditMultiMenus}
                />,
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenusProductOverridesEdit]}
                    component={EditOverridesMobile}
                />,
                <GuardedRoute
                    exact
                    path={[Routes.Menu, Routes.MultiMenusAddonOverridesEdit]}
                    component={EditOverridesMobile}
                />
            );
        }

        routes.push(
            <GuardedRoute
                exact
                path={Routes.SettingsGiftCard}
                component={GiftCardSettings}
            />
        );

        if (invoicesEnabled) {
            routes.push(
                <GuardedRoute
                    exact
                    path={Routes.Invoices}
                    component={Invoices}
                />
            );
        }

        if (menusV3Enabled)
            routes.push(
                <GuardedRoute
                    exact
                    path={[Routes.MenuManager, ...menuManagerRoutes]}
                    component={MenuManager}
                />
            );

        if (tablesideEnabled) {
            routes.push(
                <GuardedRoute
                    exact
                    path={Routes.SettingsStoreTables}
                    component={Tables}
                />
            );
        }

        return routes;
    }, [isSPEmployee, multiMenusEnabled, invoicesEnabled, tablesideEnabled]);
}
