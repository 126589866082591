import { useCallback } from "react";

import {
    CreateMenuMutationVariables,
    StoreMenuFieldsFragmentDoc,
    useCreateMenuMutation
} from "src/api/graphql/generated/types";

export function useCreateMenu() {
    const [createMenuMutation, { loading, error, reset, data }] =
        useCreateMenuMutation();
    const createMenu = useCallback(
        async (variables: CreateMenuMutationVariables) => {
            const result = await createMenuMutation({
                variables,
                // https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
                update(cache, { data }) {
                    if (!data?.createStoreMenu) return;
                    cache.modify({
                        fields: {
                            storeMenus(existingMenus = []) {
                                const newRef = cache.writeFragment({
                                    data: data.createStoreMenu,
                                    fragment: StoreMenuFieldsFragmentDoc
                                });
                                return [...existingMenus, newRef];
                            }
                        }
                    });
                }
            }).finally(reset);
            return result;
        },
        [createMenuMutation, reset]
    );
    return {
        createMenu,
        loading,
        error,
        data
    };
}
