import {
    EmployeePermissions,
    IUserWithEmployee
} from "@snackpass/snackpass-types";
import { Formik, Form } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";
import _ from "lodash";
import { toast } from "sonner";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import clsx from "clsx";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { useAdminData } from "#settings/settings-permissions/hooks/use-admin-data";
import { useHasEditAdminForActiveStore } from "#hooks/use-has-edit-admin-for-active-store";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Routes } from "#navigation/routes";
import { Button } from "src/@/components/ui/button";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { Input } from "src/@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import AddAdminAlertDialog from "#settings/settings-permissions/add-admin-alert-dialog";
import { PermissionsControls } from "#settings/settings-permissions/components/PermissionsControls";

import {
    AdminFields,
    PendingAdminInfo,
    PermissionSets,
    SnackpassPermissions,
    SnackOSRole
} from "./types";

type Props = {
    isVisible: boolean;
    creating: boolean;
    onSubmit: (
        admin: AdminFields,
        permissionUpdates: Record<string, boolean>,
        employeePermissions?: EmployeePermissions
    ) => void;
    onClose?: () => void;
    admin: IUserWithEmployee | null;
};

const AdminViewEditPopover = ({
    isVisible,
    creating,
    onClose,
    onSubmit,
    admin
}: Props) => {
    const viewOnly = !useHasEditAdminForActiveStore();

    const [rdbToggles, setRDBToggles] = useState<Partial<SnackpassPermissions>>(
        PermissionSets.employee.rdb
    );
    const [snackOSToggles, setSnackOSToggles] = useState<EmployeePermissions>({
        discounts: false
    });
    const [showAddAdminAlert, setShowAddAdminAlert] = useState(false);
    const [pendingAdminInfo, setPendingAdminInfo] = useState<PendingAdminInfo>({
        adminFields: {},
        permissionUpdates: {},
        employeePermissions: {
            discounts: false
        }
    });

    const clearPendingInfo = () => {
        setPendingAdminInfo({
            adminFields: {},
            permissionUpdates: {},
            employeePermissions: {
                discounts: false
            }
        });
    };

    const { isMobile } = useWindowDimensions();
    const activeStore = useSelector(getActiveStore);
    const { adminData, resetAdmin } = useAdminData(activeStore?._id);

    const widthHeightStyle = isMobile
        ? "min-w-full h-full pb-20"
        : "min-w-[50%] h-[95%]";

    const _onClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const hasPermissionForStore = useCallback(
        (permission: string[] | undefined) => {
            if (!activeStore) return false;
            if (!permission) return false;
            return permission.includes(activeStore._id);
        },
        [activeStore?._id]
    );

    const _resetForm = () => {
        clearPendingInfo();
        resetAdmin(admin);
        if (admin?.permissions) {
            const perms = admin.permissions;
            setRDBToggles({
                hasDashboard: hasPermissionForStore(perms.hasDashboard),
                hasOrders: hasPermissionForStore(perms.hasOrders),
                hasFinancialReports: hasPermissionForStore(
                    perms.hasFinancialReports
                ),
                hasMenuEditing: hasPermissionForStore(perms.hasMenuEditing),
                hasPromos: hasPermissionForStore(perms.hasPromos),
                hasGuestbook: hasPermissionForStore(perms.hasGuestbook),
                hasBilling: hasPermissionForStore(perms.hasBilling),
                hasBillingWrite: hasPermissionForStore(perms.hasBillingWrite),
                hasEmployees: hasPermissionForStore(perms.hasEmployees),
                hasSettings: hasPermissionForStore(perms.hasSettings),
                hasSalesReports: hasPermissionForStore(perms.hasSalesReports),
                hasSettingsWrite: hasPermissionForStore(perms.hasSettingsWrite),
                hasReportsMenu: hasPermissionForStore(perms.hasReportsMenu),
                hasReportsCustomers: hasPermissionForStore(
                    perms.hasReportsCustomers
                ),
                hasReportsLocations: hasPermissionForStore(
                    perms.hasReportsLocations
                ),
                hasReportsLabor: hasPermissionForStore(perms.hasReportsLabor),
                hasReportsPromotions: hasPermissionForStore(
                    perms.hasReportsPromotions
                ),
                hasReportsGiftCards: hasPermissionForStore(
                    perms.hasReportsGiftCards
                ),
                hasCatering: hasPermissionForStore(perms.hasCatering),
                hasDevices: hasPermissionForStore(perms.hasDevices),
                hasEditAdmin: hasPermissionForStore(perms.hasEditAdmin),
                hasPayouts: hasPermissionForStore(perms.hasPayouts)
            });
        } else {
            setRDBToggles(PermissionSets.employee.rdb);
        }
        if (admin?.employee?.permissions) {
            setSnackOSToggles(admin.employee.permissions);
        } else {
            setSnackOSToggles(PermissionSets.employee.snackos);
        }
    };

    useEffect(() => {
        _resetForm();
    }, [admin?._id]);

    return (
        <Dialog
            open={isVisible}
            onOpenChange={(open: boolean) => {
                if (!open) {
                    _onClose();
                }
            }}
        >
            <DialogContent
                className={`bg-white ${widthHeightStyle} overflow-y-scroll`}
            >
                <DialogHeader className="w-full border-b border-neutral-400">
                    <DialogTitle className="mb-6 text-body">
                        {creating ? "New" : "Edit"} User
                    </DialogTitle>
                </DialogHeader>
                <Formik
                    initialValues={adminData}
                    onSubmit={(values) => {
                        // if we are creating then show the warning and THEN submit
                        if (creating) {
                            setPendingAdminInfo({
                                adminFields: values,
                                permissionUpdates: rdbToggles,
                                employeePermissions: snackOSToggles
                            });
                            setShowAddAdminAlert(true);
                        } else {
                            // if just editing we can submit directly
                            onSubmit(values, rdbToggles, snackOSToggles);
                        }
                    }}
                    validationSchema={adminValidationSchema(creating)}
                    enableReinitialize
                    validateOnChange
                >
                    {({
                        errors,
                        values,
                        setFieldValue,
                        isSubmitting,
                        resetForm,
                        setSubmitting
                    }) => (
                        <Form className="mb-6">
                            <div className="text-large font-semibold">
                                Basic Info
                            </div>
                            <div className="py-4 font-medium">Name</div>
                            <div className="flex justify-between border-b border-neutral-400 pb-6">
                                <div className="mr-1 flex-1 flex-col">
                                    <InputWithErrorDisplay
                                        value={values.firstName}
                                        setFieldValue={setFieldValue}
                                        valueName="firstName"
                                        error={errors.firstName}
                                        placeHolder="First Name"
                                        errorDescription="First Name Required"
                                        disabled={viewOnly || !creating}
                                    />
                                </div>
                                <div className="ml-1 flex-1 flex-col">
                                    <InputWithErrorDisplay
                                        value={values.lastName}
                                        setFieldValue={setFieldValue}
                                        valueName="lastName"
                                        error={errors.lastName}
                                        placeHolder="Last Name"
                                        errorDescription="Last Name Required"
                                        disabled={viewOnly || !creating}
                                    />
                                </div>
                            </div>
                            <div className="py-4 font-medium">Identifier</div>
                            <div className="flex justify-between border-b border-neutral-400 pb-6">
                                <div className="mr-1 flex-1 flex-col">
                                    <InputWithErrorDisplay
                                        value={values.identifier}
                                        setFieldValue={setFieldValue}
                                        valueName="identifier"
                                        error={errors.identifier}
                                        placeHolder="Identifier"
                                        disabled={viewOnly}
                                    />
                                </div>
                            </div>
                            <div className="py-4 font-medium">Contact Info</div>
                            <div className="flex justify-center pb-6">
                                <div className="mr-1 flex-1 flex-col">
                                    <InputWithErrorDisplay
                                        value={values.number}
                                        setFieldValue={setFieldValue}
                                        valueName="number"
                                        error={errors.number}
                                        placeHolder="Phone Number"
                                        errorDescription={errors.number}
                                        disabled={viewOnly || !creating}
                                    />
                                </div>
                                <div className="ml-1 flex-1 flex-col">
                                    <InputWithErrorDisplay
                                        value={values.email}
                                        setFieldValue={setFieldValue}
                                        valueName="email"
                                        error={errors.email}
                                        placeHolder="Email"
                                        errorDescription={errors.email}
                                        disabled={viewOnly || !creating}
                                    />
                                </div>
                            </div>
                            {!creating && (
                                <Alert variant="warning">
                                    <AlertTitle className="text-base">
                                        <ExclamationTriangleIcon className="mr-2" />
                                        Safety And Privacy
                                    </AlertTitle>
                                    <AlertDescription className="text-sm">
                                        Only the user can edit their
                                        information. This is to ensure the
                                        safety and privacy of account details.
                                        To edit this info the user must log in
                                        to their account and go to{" "}
                                        <Link to={Routes.SettingsAccount}>
                                            My Account
                                        </Link>
                                        .
                                    </AlertDescription>
                                </Alert>
                            )}
                            <div className="border-b border-neutral-400 pt-4" />
                            {!viewOnly && (
                                <>
                                    <div className="pt-4 font-medium">
                                        Job Title
                                    </div>
                                    <div className="flex flex-col justify-center border-b border-neutral-400 pb-6">
                                        <Select
                                            onValueChange={(value: string) => {
                                                void setFieldValue(
                                                    "role",
                                                    value
                                                );
                                            }}
                                            value={values.role}
                                        >
                                            <SelectTrigger className="border-neutral-400">
                                                <SelectValue placeholder="--" />
                                            </SelectTrigger>
                                            <SelectContent className="bg-white">
                                                {Object.entries(
                                                    SnackOSRole
                                                ).map(
                                                    ([
                                                        groupKey,
                                                        { name, roles }
                                                    ]) => (
                                                        <SelectGroup
                                                            key={groupKey}
                                                        >
                                                            <SelectLabel>
                                                                {name}
                                                            </SelectLabel>
                                                            {Object.entries(
                                                                roles
                                                            ).map(
                                                                ([
                                                                    roleKey,
                                                                    roleValue
                                                                ]) => (
                                                                    <SelectItem
                                                                        value={
                                                                            roleValue
                                                                        }
                                                                        key={
                                                                            roleKey
                                                                        }
                                                                    >
                                                                        {
                                                                            roleValue
                                                                        }
                                                                    </SelectItem>
                                                                )
                                                            )}
                                                        </SelectGroup>
                                                    )
                                                )}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </>
                            )}
                            <div className="py-4 text-large font-semibold">
                                Settings
                            </div>
                            <div className="flex flex-col">
                                <div className="flex items-center border-b border-neutral-400 pb-4">
                                    <div className="flex flex-1 flex-col justify-center font-medium">
                                        Wage
                                        <div className="mb-4 text-small font-normal text-neutral-500">
                                            Used for time clocking
                                        </div>
                                    </div>
                                    <div className="flex-1 flex-col">
                                        <div className="flex items-center">
                                            $
                                            <InputWithErrorDisplay
                                                value={values.wage}
                                                setFieldValue={setFieldValue}
                                                valueName="wage"
                                                placeHolder="0.00"
                                                disabled={viewOnly}
                                            />
                                        </div>
                                        {errors.wage && (
                                            <div className="ml-4 text-critical-light">
                                                Wage must be a positive number
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-4 font-medium">PIN</div>
                                <div className="mb-4 text-small text-neutral-500">
                                    Use this PIN to perform secure actions on
                                    the SnackOS register
                                </div>
                                <div className="mb-6 border-b border-neutral-400 pb-6">
                                    <InputWithErrorDisplay
                                        value={values.pin}
                                        setFieldValue={setFieldValue}
                                        valueName="pin"
                                        maxLength={4}
                                        error={errors.pin}
                                        errorDescription="PIN Should Be 4 Numbers"
                                        disabled={viewOnly}
                                    />
                                </div>
                            </div>
                            <PermissionsControls
                                rdbToggles={rdbToggles}
                                setRDBToggles={setRDBToggles}
                                snackOSToggles={snackOSToggles}
                                setSnackOSToggles={setSnackOSToggles}
                                hasEmail={!!admin?.email}
                                hidePayouts={false}
                            />
                            {!viewOnly && (
                                <div
                                    className={clsx(
                                        "sticky flex flex-col bg-white py-6",
                                        isMobile
                                            ? "-bottom-[80px]"
                                            : "-bottom-6"
                                    )}
                                >
                                    <div className="flex justify-around">
                                        <Button
                                            className="mr-2 flex-1 border border-neutral-400"
                                            variant="outline"
                                            type="button"
                                            onClick={() => {
                                                resetForm();
                                                _resetForm();
                                                toast.success(
                                                    "Reset to previous settings"
                                                );
                                            }}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            className={`${
                                                _.isEmpty(errors)
                                                    ? "bg-black"
                                                    : "bg-gray-muted"
                                            }  flex-1 text-white`}
                                            type="submit"
                                            disabled={!_.isEmpty(errors)}
                                        >
                                            {isSubmitting ? (
                                                <Spinner size={10} />
                                            ) : (
                                                "Save"
                                            )}
                                        </Button>
                                    </div>
                                    {!_.isEmpty(errors) && (
                                        <div className="pt-3 text-center text-critical-light">
                                            Errors are preventing you from
                                            saving
                                        </div>
                                    )}
                                </div>
                            )}
                            <AddAdminAlertDialog
                                open={showAddAdminAlert}
                                onConfirm={() => {
                                    setShowAddAdminAlert(false);
                                    onSubmit(
                                        pendingAdminInfo.adminFields,
                                        pendingAdminInfo.permissionUpdates,
                                        pendingAdminInfo.employeePermissions
                                    );
                                }}
                                closeAlert={() => {
                                    clearPendingInfo();
                                    setShowAddAdminAlert(false);
                                }}
                                onCancel={() => {
                                    setSubmitting(false);
                                    clearPendingInfo();
                                    setShowAddAdminAlert(false);
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

type InputWithErrorDisplayProps = {
    value: string | undefined;
    setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
    ) => void;
    valueName: string;
    placeHolder?: string;
    error?: string | undefined;
    errorDescription?: string;
    maxLength?: number;
    disabled?: boolean;
};

const InputWithErrorDisplay = ({
    value,
    setFieldValue,
    valueName,
    placeHolder,
    error,
    errorDescription,
    maxLength,
    disabled
}: InputWithErrorDisplayProps) => (
    <>
        <Input
            className="m-1 border-neutral-300"
            value={value}
            onChange={(e) => {
                setFieldValue(valueName, e.target.value);
            }}
            placeholder={placeHolder}
            maxLength={maxLength}
            disabled={disabled}
        />
        {error && (
            <div className="ml-4 text-critical-light">{errorDescription}</div>
        )}
    </>
);

const adminValidationSchema = (namesRequired: boolean) =>
    yup.object().shape(
        {
            firstName: namesRequired
                ? yup.string().required()
                : yup.string().optional(),
            lastName: namesRequired
                ? yup.string().required()
                : yup.string().optional(),
            email: yup
                .string()
                .email()
                .when("number", {
                    is: (number: string) => !number || number.length === 0,
                    then: yup
                        .string()
                        .email()
                        .required("Valid Phone Or Email Required"),
                    otherwise: yup.string()
                }),
            number: yup.string().when("email", {
                is: (email: string) => !email || email.length === 0,
                then: yup.string().required("Valid Phone Or Email Required"),
                otherwise: yup.string()
            }),
            pin: yup.string().matches(/^\d{4}$/), // 4 digits exactly
            wage: yup.number().min(0),
            role: yup.string()
        },
        [["email", "number"]]
    );

export default AdminViewEditPopover;
