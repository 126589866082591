import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getUser } from "src/redux/selectors";

const SCRIPT_ID = "rf-script";
const WIDGET_ID = "rf-widget";
const BUTTON_ID = "rf-launcher";

export const ReferralButton = () => {
    const user = useSelector(getUser);

    useEffect(() => {
        if (!user) return;

        window.RF = {
            user: {
                first_name: user.firstName,
                email: user.email
            }
        };

        const script = document.createElement("script");
        script.id = SCRIPT_ID;
        script.src =
            "https://referral-factory.com/assets/js/widget.js?code=cLqZW5gW";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            const widget = document.getElementById(WIDGET_ID);
            if (widget) document.body.removeChild(widget);
            const script = document.getElementById(SCRIPT_ID);
            if (script) document.body.removeChild(script);
            const button = document.getElementById(BUTTON_ID);
            if (button) document.body.removeChild(button);
        };
    }, [user?.uid]);

    return null;
};
