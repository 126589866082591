/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import DownloadPurchasesCSVButton from "#download-purchase-button";
import TransactionSourcePicker from "#pickers/transaction-source-picker";
import PaymentTypePicker from "#payment-type-picker";
import { useGlobalDate } from "#hooks";
import DateRangeSelector from "#date-picker/date-range-selector";
import TransactionChannelPicker from "#pickers/transaction-channel-picker";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Routes } from "#navigation/routes";
import api from "src/api/rest";
import { getVerificationWarningStatus } from "#payouts/utils/get-verification-warning-status";
import { getActiveStore } from "src/redux/selectors";

import { DateAddressHeader } from "./components/date-address-header";
import { Sales } from "./components/sales";
import { ReferralButton } from "./components/referral";

const Dashboard = () => {
    const { startDate, endDate } = useGlobalDate();
    const store = useSelector(getActiveStore);

    const [payoutsWarning, setPayoutsWarning] = useState<
        null | "warning" | "destructive"
    >(null);

    useEffect(() => {
        const checkIfVerified = async (storeId: string) =>
            api.verifications
                .retrieve(storeId)
                .then(({ data }) => {
                    const status = getVerificationWarningStatus(
                        data.verification
                    );
                    switch (status) {
                        case "none":
                            return null;
                        case "error":
                        case "error_support":
                            return "destructive";
                        case "warning_future_requirements":
                        case "warning_future_requirements_support":
                        case "warning_future_eventual_requirements":
                        case "warning_future_eventual_requirements_support":
                        case "warning_admin_pause":
                        case "warning_pending":
                        case "warning_current_requirements":
                        case "warning_current_requirements_support":
                        case "warning_eventual_requirements":
                        case "warning_eventual_requirements_support":
                            return "warning";
                    }
                })
                .catch(() => null);

        const checkAndSetPayoutsWarnState = async () => {
            if (store?._id) {
                const warning = await checkIfVerified(store._id);
                setPayoutsWarning(warning);
            }
        };

        void checkAndSetPayoutsWarnState();
    }, [store?._id]);

    const PayoutsAlert = () =>
        payoutsWarning === null ? (
            <></>
        ) : (
            <Alert variant={payoutsWarning} className="mb-6">
                <AlertTitle className="text-base">
                    {payoutsWarning === "warning" ? (
                        <span>Attention Required Soon</span>
                    ) : (
                        <span>Attention Required</span>
                    )}
                </AlertTitle>
                <AlertDescription>
                    Please review the Payouts Settings page{" "}
                    <Link to={Routes.Payouts}>here</Link> for more information.
                </AlertDescription>
            </Alert>
        );

    return (
        <div className="p-6 sm:p-12">
            <ReferralButton />
            <PayoutsAlert />
            <DateAddressHeader />
            <div className="relative mb-2 flex items-center space-x-2 whitespace-nowrap md:mb-5">
                <DateRangeSelector subtitle={"Showing payments from "} />
                <TransactionSourcePicker />
                <TransactionChannelPicker />
                <PaymentTypePicker />
                {!isTablet && (
                    <div className="flex flex-1 justify-end">
                        <DownloadPurchasesCSVButton
                            startDate={startDate}
                            endDate={endDate}
                            showTotals={true}
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-col space-y-6">
                <Sales />
            </div>
        </div>
    );
};

export default Dashboard;
