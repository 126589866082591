import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import api, { BaseStoreDeviceWithNetworkStatus } from "src/api/rest";
import { sendError } from "src/utils/errors";
import { getActiveStore } from "src/redux/selectors";
import { useAppSelector } from "src/redux/hooks";

type DevicesQuery = [
    {
        label: string;
        storeId: string;
    }
];

const fetchStoreDevices = async ({
    queryKey: [{ storeId }]
}: QueryFunctionContext<DevicesQuery>): Promise<
    BaseStoreDeviceWithNetworkStatus[]
> => api.storeDevices.listStoreDevices(storeId).then((e) => e.data.devices);

export const useStoreDevices = () => {
    const store = useAppSelector(getActiveStore);

    const key = {
        storeId: store?._id || "",
        label: "devices"
    };

    const query = useQuery({
        queryKey: [key],
        queryFn: fetchStoreDevices
    });

    useEffect(() => {
        if (query.isError) {
            sendError(query.error);
        }
    }, [query.isError, query.error]);

    return useMemo(
        () => ({
            ...query
        }),
        [query]
    );
};
