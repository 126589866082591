import { RefObject } from "react";
import { useLocation } from "react-router-dom";

import { Routes } from "#navigation/routes";
import { ReactComponent as SmilingFace } from "src/assets/icons/smiling-face.svg";
import { SidebarItemDesktop } from "#navigation/sidebar-item-desktop";

export const WelcomeItem = ({
    complete = true,
    scrollRootRef
}: {
    complete: boolean;
    scrollRootRef: RefObject<HTMLElement>;
}) => {
    const location = useLocation();
    const isHighlighted = Routes.Onboarding === location.pathname;
    return complete ? null : (
        <SidebarItemDesktop
            link={Routes.Onboarding}
            title={"Welcome"}
            icon={<SmilingFace />}
            isActive={isHighlighted}
            scrollRootRef={scrollRootRef}
            hasChildren={false}
        />
    );
};
