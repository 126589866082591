import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { Button } from "src/@/components/ui/button";

type ConfirmDialogProps = {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onAccept: () => void;
};

export const ConfirmDialog = ({
    isOpen,
    onOpenChange,
    onAccept
}: ConfirmDialogProps) => {
    const handleClose = () => {
        onOpenChange(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogHeader className="h-[40px] w-full items-center justify-center  border-b border-neutral-400 ">
                    <DialogTitle className="mb-6 text-body font-semibold">
                        Snackpass Invoices Terms
                    </DialogTitle>
                </DialogHeader>
                <div className="text-small leading-5">
                    Invoices will be emailed to customers, who can conveniently
                    pay online using a credit card, debit card, or ACH bank
                    transfer. You will receive payment for the invoice once the
                    customer’s payment is processed. A 5% fee of the invoice
                    subtotal will be deducted from your invoice payout to cover
                    processing costs and the Snackpass Invoicing service.
                    Invoices should not be used for orders that include alcohol.
                    Refunds for invoices are not supported at this time.
                    <br />
                    See full terms{" "}
                    <a
                        href={
                            "https://snackpass.notion.site/Snackpass-Invoice-Terms-196ee37487f843bea79811d2c3d836ab"
                        }
                        className="text-blue-500 no-underline"
                        target="_blank"
                    >
                        here
                    </a>
                    .
                </div>
                <DialogFooter className="items-center gap-1 border-t py-3 sm:self-end md:mb-0 md:pb-0 md:pt-4">
                    <Button
                        className="w-full sm:w-fit"
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button className="w-full" onClick={onAccept}>
                        Accept & Confirm
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
