import { z } from "zod";

import { PriceAdjustmentType } from "src/api/graphql/generated/types";

export const MenuNameSchema = z.object({
    name: z.string().min(1, "Required").max(500)
});
export type MenuName = z.infer<typeof MenuNameSchema>;

export const CategoryItemsSchema = z.object({
    categories: z.string().array(),
    productExclusions: z.string().array()
});
export type CategoryItems = z.infer<typeof CategoryItemsSchema>;

export enum PriceAdjustmentPolarity {
    MARKDOWN = "markdown",
    MARKUP = "markup"
}
export const PriceAdjustmentPolaritySchema = z.nativeEnum(
    PriceAdjustmentPolarity
);
export const PriceAdjustmentSchema = z.object({
    priceAdjustment: z.object({
        /**
         * "enabled" not a value that exists on our actual input object, but reflects our form values more accurately.
         * if the actual "priceAdjustment" property on the menu is null, then our price adjustment is disabled..
         */
        enabled: z.boolean(),

        /**
         * Whether the price adjustment is a markup or markdown.
         * This value in the data is actually represented by the sign of the "value" property, but we mark it explicitly on the form.
         */
        polarity: PriceAdjustmentPolaritySchema,

        adjustModifiers: z.boolean(),
        type: z.nativeEnum(PriceAdjustmentType),
        roundTo: z
            .number()
            .int()
            .min(0, "Please enter a positive number.")
            .nullable()
            .optional(),
        value: z
            .number({ message: "Required" })
            .int()
            .min(0, 'For negative adjustments, please use "Markdown"')
    })
});
export type PriceAdjustment = z.infer<typeof PriceAdjustmentSchema>;

export const MenuFormValuesSchema = MenuNameSchema.and(CategoryItemsSchema).and(
    PriceAdjustmentSchema
);

export type MenuFormValues = z.infer<typeof MenuFormValuesSchema>;
