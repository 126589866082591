import { useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { NavLink, useHistory } from "react-router-dom";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { ReactNode, useCallback } from "react";

import {
    MenuFormValues,
    MenuFormValuesSchema
} from "#menu-manager/components/menu-form/schema";
import { Form } from "src/@/components/ui/form";
import { CategoryItemsCard } from "#menu-manager/components/menu-form/CategoryItemsInput";
import { PriceAdjustmentCard } from "#menu-manager/components/menu-form/PriceAdjustmentCard";
import { MenuNameCard } from "#menu-manager/components/menu-form/MenuNameCard";
import { MenuManagerRoutes } from "#menu-manager/routes";
import { Button } from "src/@/components/ui/button";
import { useCategories, useItems } from "#menu-manager/hooks";
import { DEFAULT_MENU_VALUES } from "#menu-manager/components/menu-form/lib";

export type MenuFormProps = {
    onSubmit: (values: MenuFormValues) => Promise<unknown>;
    submitting?: boolean;
    defaultValues?: Partial<MenuFormValues>;
    headerText?: string;
    additionalActionButtons?: ReactNode;
};

export function MenuForm({ defaultValues, ...props }: MenuFormProps) {
    const form = useForm<MenuFormValues>({
        resolver: zodResolver(MenuFormValuesSchema),
        defaultValues: {
            ...DEFAULT_MENU_VALUES,
            ...defaultValues
        }
    });

    const categories = useCategories();
    const items = useItems();

    return (
        <Form {...form}>
            <FormHeader {...props} />
            <div className="flex flex-1 justify-center  p-3 md:p-12">
                <div className="max-w-4xl flex-1 space-y-8">
                    <MenuNameCard />
                    <PriceAdjustmentCard />
                    <CategoryItemsCard items={items} categories={categories} />
                </div>
            </div>
        </Form>
    );
}

function FormHeader({
    onSubmit,
    additionalActionButtons,
    headerText = "New Menu",
    submitting
}: Omit<MenuFormProps, "defaultValues">) {
    const form = useFormContext<MenuFormValues>();
    const history = useHistory();
    const closeForm = useCallback(
        () => history.push(MenuManagerRoutes.INDEX),
        [history]
    );

    const onSave = useCallback(() => {
        void form.handleSubmit(async (values, _event) => onSubmit(values))();
    }, [form, onSubmit]);

    return (
        <div className="relative h-16 w-full max-w-full">
            <div className="fixed z-10 flex h-16 w-full items-center border-b border-neutral-400 bg-neutral-50 p-4 md:px-12">
                <div className="hidden items-center space-x-2 md:flex">
                    <NavLink
                        to={MenuManagerRoutes.INDEX}
                        className="text-large font-bold text-neutral-500 no-underline"
                    >
                        Menus
                    </NavLink>
                    <ChevronRightIcon className="fill-neutral-500" />
                    <div className="text-large font-bold">{headerText}</div>
                </div>

                <div className="fixed right-4 flex items-center justify-end space-x-1">
                    {additionalActionButtons}
                    <Button
                        onClick={closeForm}
                        variant="outline"
                        size="sm"
                        disabled={submitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onSave}
                        aria-label="save menu form"
                        size="sm"
                        loading={submitting}
                        disabled={submitting}
                    >
                        Save & Exit
                    </Button>
                </div>
            </div>
        </div>
    );
}
