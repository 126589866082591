import { useCallback, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "react-activity";
import moment from "moment";

import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import GuestbookTooltip from "#guestbook/shared-components/GuestbookTooltip";
import {
    CampaignEventType,
    CampaignUserMetric,
    CampaignWithMetricsAndUserBreakdownFieldsFragment
} from "src/api/graphql/generated/types";
import { DataTable } from "src/@/components/ui/data-table";

type EngagementTableProps = {
    campaign: Pick<
        CampaignWithMetricsAndUserBreakdownFieldsFragment,
        "id" | "userBreakdown" | "store" | "createdAt"
    >;
};

export function EngagementTable({ campaign }: EngagementTableProps) {
    const isCampaignOlderThanOneDay = useMemo(() => {
        const campaignDate = moment(campaign.createdAt);
        return moment().subtract(1, "days") > campaignDate;
    }, [campaign.createdAt]);
    const columns = useEngagementTableColumns(isCampaignOlderThanOneDay);
    const onPaginationChange = useCallback(() => {
        trackSegmentEvent(
            SegmentEvents.Guestbook.Campaigns.PAGED_RECIPIENTS_LIST,
            {
                store_id: campaign.store.id,
                store_name: campaign.store.name,
                campaign_id: campaign.id
            }
        );
    }, [campaign.id, campaign.store.id, campaign.store.name]);

    return (
        <DataTable
            showPagination
            onPaginationChange={onPaginationChange}
            columns={columns}
            data={campaign.userBreakdown.users}
            className="text-small font-medium"
            toolbar={{
                search: {
                    key: "name",
                    inputClassName: "w-1/3"
                }
            }}
        />
    );
}

function hasEventOfType(user: CampaignUserMetric, type: CampaignEventType) {
    return !!user.events.find((x) => x.type === type);
}

function useEngagementTableColumns(
    isCampaignOlderThanOneDay: boolean
): ColumnDef<CampaignUserMetric>[] {
    return useMemo(
        () => [
            {
                header() {
                    return <div className="px-4 py-[11px]">Name</div>;
                },
                accessorKey: "name",
                cell(props) {
                    return (
                        <div className="whitespace-nowrap px-4 py-[11px]">
                            {props.cell.getValue() as string}
                        </div>
                    );
                }
            },
            {
                header: "Delivered",
                accessorFn: (row) =>
                    hasEventOfType(row, CampaignEventType.Delivered),
                cell({ getValue, row }) {
                    if (!getValue()) {
                        const failedEvent = row.original.events.find(
                            (e) => e.type === CampaignEventType.Failed
                        );
                        if (
                            failedEvent == null &&
                            isCampaignOlderThanOneDay === false
                        ) {
                            return (
                                <GuestbookTooltip
                                    tooltip={
                                        "Message not delivered yet, check again later"
                                    }
                                >
                                    <span>
                                        <Spinner size={16} />
                                    </span>
                                </GuestbookTooltip>
                            );
                        }
                        return (
                            <GuestbookTooltip
                                tooltip={
                                    failedEvent?.errorMessage ??
                                    (isCampaignOlderThanOneDay
                                        ? "Message undelivered"
                                        : null)
                                }
                            >
                                <span>
                                    <CheckmarkCell getValue={getValue} />
                                </span>
                            </GuestbookTooltip>
                        );
                    }
                    return <CheckmarkCell getValue={getValue} />;
                }
            },
            {
                header: "Opt-out",
                accessorFn: (row) =>
                    hasEventOfType(row, CampaignEventType.Optout),
                cell: CheckmarkCell
            }
        ],
        []
    );
}

type CheckmarkCellProps = {
    getValue: () => unknown;
};

function CheckmarkCell(props: CheckmarkCellProps) {
    return (
        <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {props.getValue() ? (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.5 17.25C14.0563 17.25 17.75 13.5563 17.75 9C17.75 4.44365 14.0563 0.75 9.5 0.75C4.94365 0.75 1.25 4.44365 1.25 9C1.25 13.5563 4.94365 17.25 9.5 17.25ZM13.0228 8.03775C13.3198 7.74901 13.3265 7.27418 13.0377 6.97719C12.749 6.6802 12.2742 6.67351 11.9772 6.96225L8.64286 10.204L7.02281 8.62892C6.72582 8.34018 6.25099 8.34687 5.96225 8.64386C5.67351 8.94085 5.6802 9.41567 5.97719 9.70441L8.12005 11.7877C8.41114 12.0708 8.87457 12.0708 9.16567 11.7877L13.0228 8.03775Z"
                    fill="#0F0F0F"
                />
            ) : (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.75 9C2.75 5.27208 5.77208 2.25 9.5 2.25C13.2279 2.25 16.25 5.27208 16.25 9C16.25 12.7279 13.2279 15.75 9.5 15.75C5.77208 15.75 2.75 12.7279 2.75 9ZM9.5 0.75C4.94365 0.75 1.25 4.44365 1.25 9C1.25 13.5563 4.94365 17.25 9.5 17.25C14.0563 17.25 17.75 13.5563 17.75 9C17.75 4.44365 14.0563 0.75 9.5 0.75ZM7.78033 6.21967C7.48744 5.92678 7.01256 5.92678 6.71967 6.21967C6.42678 6.51256 6.42678 6.98744 6.71967 7.28033L8.43934 9L6.71967 10.7197C6.42678 11.0126 6.42678 11.4874 6.71967 11.7803C7.01256 12.0732 7.48744 12.0732 7.78033 11.7803L9.5 10.0607L11.2197 11.7803C11.5126 12.0732 11.9874 12.0732 12.2803 11.7803C12.5732 11.4874 12.5732 11.0126 12.2803 10.7197L10.5607 9L12.2803 7.28033C12.5732 6.98744 12.5732 6.51256 12.2803 6.21967C11.9874 5.92678 11.5126 5.92678 11.2197 6.21967L9.5 7.93934L7.78033 6.21967Z"
                    fill="#999DA3"
                />
            )}
        </svg>
    );
}
