import { PlusIcon } from "@radix-ui/react-icons";
import { useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { toast } from "sonner";
import Skeleton from "react-loading-skeleton";

import { Sheet, SheetContent } from "src/@/components/ui/sheet";
import { DataTable } from "src/@/components/ui/data-table";
import { Button } from "src/@/components/ui/button";
import { getActiveStoreId } from "src/redux/slices";
import { TableDetail } from "#table-definitions/components/table-detail";
import { NewTable } from "#table-definitions/components/table-new";
import { useColumns } from "#table-definitions/components/columns";
import { CurrentTable } from "#table-definitions/types";
import {
    useDeleteTableMutation,
    useGetTablesQuery
} from "src/api/graphql/generated/types";

export const Tables = () => {
    const [currentTable, setCurrentTable] = useState<
        CurrentTable | undefined
    >();
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [loadingTableId, setLoadingTableId] = useState<string | undefined>();
    const [defaultEditOnOpen, setDefaultEditOnOpen] = useState(false);

    const activeStoreId = useSelector(getActiveStoreId);
    const { data, error, refetch, loading } = useGetTablesQuery({
        variables: { storeId: activeStoreId }
    });

    const [deleteTableMutation] = useDeleteTableMutation();

    useEffect(() => {
        if (error) {
            toast.error(error.message);
        }
    }, [error]);

    const handleTableCreateOrEdit = useCallback(async () => {
        setIsSheetOpen(false);
        await refetch();
    }, [refetch]);

    const handleTableChange = (open: boolean) => {
        setIsSheetOpen(open);
        if (!open) {
            setCurrentTable(undefined);
            setDefaultEditOnOpen(false);
        }
    };

    const handleNewTable = () => {
        setIsSheetOpen(true);
    };

    const handleCellSelected = (table: CurrentTable) => {
        setCurrentTable(table);
        setIsSheetOpen(true);
    };

    const handleDeleteTable = useCallback(
        async (tableId: string) => {
            setLoadingTableId(tableId);
            try {
                await deleteTableMutation({
                    variables: { tableId, storeId: activeStoreId }
                });
            } catch (e: unknown) {
                if (e instanceof Error) {
                    toast.error(e.message);
                } else {
                    toast.error("Table deletion failed");
                }
            }
            await refetch();
            setLoadingTableId(undefined);
        },
        [refetch, activeStoreId, deleteTableMutation]
    );

    /**
     * This helper looks for a "last" table number so we can autofill the next one
     * @returns -1 if no numbers found, otherwise the highest number found
     */
    const getHighestTableNum = () => {
        if (data?.storeTables) {
            const tableNumbers = data.storeTables
                .map((table) => parseInt(table.name, 10))
                .filter((num) => !isNaN(num));
            if (data.storeTables.length > 0) {
                return tableNumbers.length > 0 ? Math.max(...tableNumbers) : -1;
            } else {
                return 0;
            }
        }
        return 0;
    };

    const columns = useColumns(
        handleCellSelected,
        handleDeleteTable,
        setDefaultEditOnOpen,
        loadingTableId
    );

    return (
        <div className="p-6 md:px-12">
            <Sheet open={isSheetOpen} onOpenChange={handleTableChange}>
                <div className="mb-4 flex items-center justify-between">
                    <div className="text-large font-semibold">Tables</div>
                    <Button
                        onClick={handleNewTable}
                        className="flex items-center space-x-1"
                    >
                        <PlusIcon className="h-4 w-4 fill-neutral-950" />
                        <span className="hidden lg:block">Table</span>
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <Skeleton className="h-80 w-full rounded-xl" />
                    ) : (
                        <DataTable
                            emptyText="No Tables"
                            columns={columns}
                            data={data?.storeTables || []}
                            customPageSize={150}
                            getRowClickListener={(row) => () =>
                                handleCellSelected(row.original)
                            }
                        />
                    )}
                </div>
                <SheetContent
                    side="right"
                    overlayClassName="z-[9998] bg-black/40"
                    className="z-[9999] max-h-screen w-full overflow-y-scroll sm:min-w-[750px]"
                >
                    {currentTable ? (
                        <TableDetail
                            table={currentTable}
                            onEdit={handleTableCreateOrEdit}
                            openToEdit={defaultEditOnOpen}
                        />
                    ) : (
                        <NewTable
                            onCreate={handleTableCreateOrEdit}
                            highestTableNum={getHighestTableNum()}
                        />
                    )}
                </SheetContent>
            </Sheet>
        </div>
    );
};
