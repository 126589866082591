import {
    toDollar,
    buildCommonSummaryLineItems,
    ReceiptLineItem
} from "@snackpass/accounting";
import { SystemColors } from "@snackpass/design-system";
import {
    CartAdjustmentType,
    IPurchase,
    PaymentProvider
} from "@snackpass/snackpass-types";
import moment from "moment";
import React from "react";
import { styled } from "styled-components";

import { usePurchaseFromReport } from "src/api/rest/usePurchaseReports";
import { formatPurchaseChannel, formatTransactionSource } from "#utils/helpers";

import { Card, Header, Text } from "./ui";

type Props = {
    purchase: IPurchase;
};

const _getIntendedCartAdjustmentPosition = (type: CartAdjustmentType) => {
    if (type === CartAdjustmentType.SurchargeFlat) {
        return 0;
    }
    if (type === CartAdjustmentType.DiscountPercent) {
        return 1;
    }
    if (type === CartAdjustmentType.DiscountFlat) {
        return 2;
    }
    return 3;
};

export const PurchaseInfo = ({ purchase }: Props) => {
    const { data: report } = usePurchaseFromReport(purchase?._id);
    const receiptLineItems = buildCommonSummaryLineItems(purchase);
    if (!report) return null;
    const isUnpaid = purchase.paymentProviderId === PaymentProvider.unpaid;
    return (
        <Card>
            <MarginHeader>Order Info</MarginHeader>
            <InfoContainer>
                <InfoItem
                    title="Created"
                    subtitle={moment(report.dateReceived).format(
                        "MMM DD, YYYY"
                    )}
                />
                <InfoItem
                    title="Time"
                    subtitle={moment(report.dateReceived).format("hh:mm a")}
                />
                <InfoItem
                    title="Source"
                    subtitle={formatTransactionSource(
                        purchase.transactionSource,
                        purchase.isPOSMode
                    )}
                />
                <InfoItem
                    title="Channel"
                    subtitle={formatPurchaseChannel(purchase)}
                />
                {purchase.tableNumber ? (
                    <InfoItem
                        title="Table Number"
                        subtitle={purchase.tableNumber}
                    />
                ) : null}
                {purchase.catering?.isCatering &&
                purchase.catering?.headcount ? (
                    <InfoItem
                        title="Headcount"
                        subtitle={`${purchase.catering.headcount}`}
                    />
                ) : null}
            </InfoContainer>
            <div>
                {report?.refundedBy ? (
                    <TotalRow>
                        <Text>Refunded By</Text>
                        <BlackText>{report.refundedBy}</BlackText>
                    </TotalRow>
                ) : null}
                {report?.upchargedBy ? (
                    <TotalRow>
                        <Text>Up Charged By</Text>
                        <BlackText>{report.upchargedBy}</BlackText>
                    </TotalRow>
                ) : null}
                {report?.cashAcceptedBy ? (
                    <TotalRow>
                        <Text>Cash Accepted By</Text>
                        <BlackText>{report.cashAcceptedBy}</BlackText>
                    </TotalRow>
                ) : null}
                {report?.orderCreatedBy ? (
                    <TotalRow>
                        <Text>Employee Entered Order</Text>
                        <BlackText>{report.orderCreatedBy}</BlackText>
                    </TotalRow>
                ) : null}
                {purchase?.cartAdjustments &&
                purchase.cartAdjustments.length ? (
                    <>
                        <TotalRow>
                            <Text>Employee Custom Adjustments</Text>
                        </TotalRow>
                        {purchase.cartAdjustments
                            .sort(
                                (a, b) =>
                                    _getIntendedCartAdjustmentPosition(a.type) -
                                    _getIntendedCartAdjustmentPosition(b.type)
                            )
                            .map((adjustment) => (
                                <AdjustmentRow>
                                    {adjustment.type ===
                                    CartAdjustmentType.DiscountFlat ? (
                                        <GreenText>Flat Discount</GreenText>
                                    ) : adjustment.type ===
                                      CartAdjustmentType.DiscountPercent ? (
                                        <GreenText>
                                            Percent Discount (
                                            {adjustment.percent || 0}%)
                                        </GreenText>
                                    ) : (
                                        <Text>Charge</Text>
                                    )}
                                    {adjustment.type ===
                                    CartAdjustmentType.SurchargeFlat ? (
                                        <SmallerText>
                                            {toDollar(adjustment.flat || 0)}
                                        </SmallerText>
                                    ) : (
                                        <SmallerText>
                                            {adjustment.type ===
                                            CartAdjustmentType.DiscountFlat
                                                ? toDollar(adjustment.flat || 0)
                                                : toDollar(
                                                      adjustment.flat ??
                                                          // KC: note using subtotal is wrong since subtotal is computed after discounts
                                                          // but hopefully all adjustments will have a flat value making this fallback irrelevant
                                                          (((purchase?.subtotal ||
                                                              0) +
                                                              (report.customSurchargeTotal ||
                                                                  0)) *
                                                              (adjustment.percent ||
                                                                  0)) /
                                                              100
                                                  )}
                                        </SmallerText>
                                    )}
                                </AdjustmentRow>
                            ))}
                    </>
                ) : null}
            </div>
            <Divider />
            {isUnpaid ? <RedSpan>UNPAID</RedSpan> : null}
            {receiptLineItems.map((lineItem: ReceiptLineItem, i) => (
                <TotalRow key={i}>
                    <LineItemText
                        color={lineItem.textProps?.color}
                        bold={lineItem.textProps?.bold}
                    >
                        {lineItem.label}
                    </LineItemText>
                    <LineItemText
                        color={lineItem.textProps?.color}
                        bold={lineItem.textProps?.bold}
                    >
                        {lineItem.isDiscount ? "-" : ""}
                        {lineItem.value.toFixed(2)}
                    </LineItemText>
                </TotalRow>
            ))}
        </Card>
    );
};

const InfoItem = ({ title, subtitle }: { title: string; subtitle: string }) => (
    <InfoItemContainer>
        <Text>{title}</Text>
        <Header>{subtitle}</Header>
    </InfoItemContainer>
);

const RedSpan = styled.span`
    color: ${SystemColors.v1.melon50};
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    flex-flow: wrap;
    place-content: space-between;
    gap: 10px;
`;

const InfoItemContainer = styled.div`
    margin-right: 48px;
`;

const TotalRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
`;

const AdjustmentRow = styled(TotalRow)`
    margin-left: 20px;
    justify-content: start;
    gap: 8px;
`;

const Divider = styled.div`
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    margin: 16px 0px;
`;

const GreenText = styled(Text)`
    color: ${SystemColors.v2.parsley50.light};
`;

const BlackText = styled(Text)`
    color: ${SystemColors.v1.sesame};
`;

export const SmallerText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${SystemColors.v1.gray30};
`;

const LineItemText = styled(Text)<{ color?: string; bold?: boolean }>`
    color: ${({ color }) => (color ? `${color}` : `${SystemColors.v1.sesame}`)};
    font-weight: ${({ bold }) => (bold ? `600` : `300`)};
`;

const MarginHeader = styled(Header)`
    margin-bottom: 16px;
`;
