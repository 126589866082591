import { PrepStation } from "@snackpass/snackpass-types";
import { useCallback, useState } from "react";
import { FormInstance } from "antd";
/**
 * A hook to manage the lists of allowed and rejected categories and products for a `PrepStation`.
 */
export function usePrepStationLists(form: FormInstance<unknown>) {
    const [allowedCategories, setAllowedCategories] = useState<string[]>([]);
    const [allowedProducts, setAllowedProducts] = useState<string[]>([]);
    const [rejectedCategories, setRejectedCategories] = useState<string[]>([]);
    const [rejectedProducts, setRejectedProducts] = useState<string[]>([]);
    const [allowedChannels, setAllowedChannels] = useState<string[]>([]);
    const [allowedFulfillments, setAllowedFulfillments] = useState<string[]>(
        []
    );

    /**
     * Resets (clears) all lists.
     */
    const reset = useCallback(() => {
        setAllowedCategories([]);
        setAllowedProducts([]);
        setRejectedCategories([]);
        setRejectedProducts([]);
        setAllowedChannels([]);
        setAllowedFulfillments([]);
    }, []);

    /**
     * Sets the form fields and state from a `PrepStation` object.
     */
    const setFromPrepStation = useCallback(
        (prepStation: PrepStation | undefined) => {
            const {
                allowCategoryList = [],
                allowProductList = [],
                rejectCategoryList = [],
                rejectProductList = [],
                allowChannelList = [],
                allowFulfillmentList = []
            } = prepStation ?? {};

            setAllowedCategories(allowCategoryList);
            setAllowedProducts(allowProductList);
            setRejectedCategories(rejectCategoryList);
            setRejectedProducts(rejectProductList);
            setAllowedChannels(allowChannelList);
            setAllowedFulfillments(allowFulfillmentList);

            form.setFieldsValue({
                allowCategoryList,
                allowProductList,
                rejectCategoryList,
                rejectProductList,
                allowChannelList,
                allowFulfillmentList
            });
        },
        [form]
    );

    const _setAllowedChannels = useCallback((list: string[]) => {
        setAllowedChannels(list);
    }, []);

    const _setAllowedFulfillments = useCallback((list: string[]) => {
        setAllowedFulfillments(list);
    }, []);

    /**
     * Sets the allowed categories list for the current menu view.
     * NB: The non-visible menu view is not affected.
     */
    const _setAllowedCategories = useCallback((list: string[]) => {
        setAllowedCategories(list);
    }, []);

    /**
     * Sets the allowed products list for the current menu view.
     * NB: The non-visible menu view is not affected.
     */
    const _setAllowedProducts = useCallback((list: string[]) => {
        setAllowedProducts(list);
    }, []);

    /**
     * Sets the rejected categories list for the current menu view.
     * NB: The non-visible menu view is not affected.
     */
    const _setRejectedCategories = useCallback((list: string[]) => {
        setRejectedCategories(list);
    }, []);

    /**
     * Sets the rejected products list for the current menu view.
     * NB: The non-visible menu view is not affected.
     */
    const _setRejectedProducts = useCallback((list: string[]) => {
        setRejectedProducts(list);
    }, []);

    return {
        reset,
        setFromPrepStation,
        allowedCategories,
        allowedProducts,
        rejectedCategories,
        rejectedProducts,
        allowedChannels,
        allowedFulfillments,
        setAllowedCategories: _setAllowedCategories,
        setAllowedProducts: _setAllowedProducts,
        setRejectedCategories: _setRejectedCategories,
        setRejectedProducts: _setRejectedProducts,
        setAllowedChannels: _setAllowedChannels,
        setAllowedFulfillments: _setAllowedFulfillments
    };
}
