import { EmployeePermissions } from "@snackpass/snackpass-types";
import { z } from "zod";

export type RoleEntry = {
    storeId: string;
    role: string;
};

export enum CheckboxState {
    checked = "checked",
    unchecked = "unchecked",
    indeterminate = "indeterminate"
}

export const permissionsSchema = z.object({
    hasDashboard: z.array(z.string()).optional(),
    hasOrders: z.array(z.string()).optional(),
    hasFinancialReports: z.array(z.string()).optional(),
    hasMenuEditing: z.array(z.string()).optional(),
    hasPromos: z.array(z.string()).optional(),
    hasGuestbook: z.array(z.string()).optional(),
    hasBilling: z.array(z.string()).optional(),
    hasBillingWrite: z.array(z.string()).optional(),
    hasEmployees: z.array(z.string()).optional(),
    hasSettings: z.array(z.string()).optional(),
    hasSalesReports: z.array(z.string()).optional(),
    hasSettingsWrite: z.array(z.string()).optional(),
    hasReportsMenu: z.array(z.string()).optional(),
    hasReportsCustomers: z.array(z.string()).optional(),
    hasReportsLocations: z.array(z.string()).optional(),
    hasReportsPromotions: z.array(z.string()).optional(),
    hasReportsLabor: z.array(z.string()).optional(),
    hasReportsGiftCards: z.array(z.string()).optional(),
    hasCatering: z.array(z.string()).optional(),
    hasDevices: z.array(z.string()).optional(),
    hasEditAdmin: z.array(z.string()).optional(),
    isOwner: z.array(z.string()).optional(),
    hasPayouts: z.array(z.string()).optional()
});

export const snackpassPermissionsSchema = z.object({
    storeRoles: z.array(
        z.object({
            role: z.string(),
            storeId: z.string()
        })
    )
});

export const adminColSchema = z.object({
    _id: z.string(),
    name: z.string().nullish(),
    number: z.string().nullish(),
    email: z.string().nullish(),
    role: z.string().nullish(),
    employee: z
        .object({
            pin: z.string().nullish(),
            wage: z.number().nullish(),
            identifier: z.string().nullish()
        })
        .optional(),
    permissions: permissionsSchema.optional(),
    snackpassPermissions: snackpassPermissionsSchema
});

export type AdminColumn = z.infer<typeof adminColSchema>;

export const pendingInviteColSchema = z.object({
    email: z.string(),
    jobTitle: z.string(),
    status: z.string(),
    createdAt: z.string(),
    identifier: z.string(),
    _id: z.string().optional()
});

export type PendingInviteColumn = z.infer<typeof pendingInviteColSchema>;

export type SnackpassPermissions = {
    hasDashboard?: boolean;
    hasOrders?: boolean;
    hasFinancialReports?: boolean;
    hasMenuEditing?: boolean;
    hasPromos?: boolean;
    hasGuestbook?: boolean;
    hasBilling?: boolean;
    hasBillingWrite?: boolean;
    hasEmployees?: boolean;
    hasSettings?: boolean;
    hasSalesReports?: boolean;
    hasSettingsWrite?: boolean;
    hasReportsMenu?: boolean;
    hasReportsCustomers?: boolean;
    hasReportsLocations?: boolean;
    hasReportsPromotions?: boolean;
    hasReportsLabor?: boolean;
    hasReportsGiftCards?: boolean;
    hasCatering?: boolean;
    hasDevices?: boolean;
    hasEditAdmin?: boolean;
    hasPayouts?: boolean;
};

export type AdminFields = {
    firstName?: string;
    lastName?: string;
    identifier?: string;
    email?: string;
    number?: string;
    pin?: string;
    role?: string;
    wage?: string;
    snackpassPermissions?: SnackpassPermissions;
};

export type InviteFields = {
    email: string;
    role: string;
    wage: string;
    pin?: string;
    identifier: string;
};

/** restaurant dashboard user "roles", used when selecting the default permission set for a user */
export type RDBUserRole =
    | "admin"
    | "manager"
    | "staff"
    | "marketer"
    | "accountant"
    | "employee";

// is this casing significant? Would be nice to have consistency
type SnackOSUserRole =
    | "EXEC"
    | "BACK_OF_HOUSE"
    | "FRONT_OF_HOUSE"
    | "SUPPORT"
    | "ADDDITIONAL";

/**
 * mapping of RDBUserRoles to the full set of default permissions for said role (rdb and snackos)
 *
 * A visualization of these permissions can be found here: https://www.notion.so/snackpass/Permissions-7fbaa5de4774424fa01b43ec5fa722f6?pvs=4
 *
 * XXX: Please keep these permissions sorted alphabetically!
 */
export const PermissionSets: Record<
    RDBUserRole,
    {
        /** permissions for functionality within restaurant dashboard */
        rdb: Required<SnackpassPermissions>;
        /** permissions for functionality within snackos devices */
        snackos: Required<EmployeePermissions>;
    }
> = {
    admin: {
        rdb: {
            hasBilling: true,
            hasBillingWrite: true,
            hasCatering: true,
            hasDashboard: true,
            hasDevices: true,
            hasEditAdmin: true,
            hasEmployees: true,
            hasFinancialReports: true,
            hasGuestbook: true,
            hasMenuEditing: true,
            hasOrders: true,
            hasPayouts: false,
            hasPromos: true,
            hasReportsCustomers: true,
            hasReportsGiftCards: true,
            hasReportsLabor: true,
            hasReportsLocations: true,
            hasReportsMenu: true,
            hasReportsPromotions: true,
            hasSalesReports: true,
            hasSettings: true,
            hasSettingsWrite: true
        },
        snackos: {
            cashDrawer: true,
            discounts: true,
            orders: true,
            sales: true
        }
    },
    manager: {
        rdb: {
            hasBilling: true,
            hasBillingWrite: false,
            hasCatering: true,
            hasDashboard: true,
            hasDevices: true,
            hasEditAdmin: false,
            hasEmployees: true,
            hasFinancialReports: false,
            hasGuestbook: true,
            hasMenuEditing: true,
            hasOrders: true,
            hasPayouts: false,
            hasPromos: true,
            hasReportsCustomers: true,
            hasReportsGiftCards: true,
            hasReportsLabor: true,
            hasReportsLocations: true,
            hasReportsMenu: true,
            hasReportsPromotions: true,
            hasSalesReports: true,
            hasSettings: true,
            hasSettingsWrite: true
        },
        snackos: {
            cashDrawer: true,
            discounts: true,
            orders: true,
            sales: true
        }
    },
    staff: {
        rdb: {
            hasBilling: false,
            hasBillingWrite: false,
            hasCatering: true,
            hasDashboard: false,
            hasDevices: false,
            hasEditAdmin: false,
            hasEmployees: false,
            hasFinancialReports: false,
            hasGuestbook: false,
            hasMenuEditing: false,
            hasOrders: true,
            hasPayouts: false,
            hasPromos: false,
            hasReportsCustomers: false,
            hasReportsGiftCards: false,
            hasReportsLabor: false,
            hasReportsLocations: false,
            hasReportsMenu: false,
            hasReportsPromotions: false,
            hasSalesReports: false,
            hasSettings: false,
            hasSettingsWrite: false
        },
        snackos: {
            cashDrawer: true,
            discounts: false,
            orders: true,
            sales: false
        }
    },
    marketer: {
        rdb: {
            hasBilling: false,
            hasBillingWrite: false,
            hasCatering: false,
            hasDashboard: false,
            hasDevices: false,
            hasEditAdmin: false,
            hasEmployees: false,
            hasFinancialReports: false,
            hasGuestbook: true,
            hasMenuEditing: false,
            hasOrders: true,
            hasPayouts: false,
            hasPromos: true,
            hasReportsCustomers: true,
            hasReportsGiftCards: false,
            hasReportsLabor: false,
            hasReportsLocations: true,
            hasReportsMenu: true,
            hasReportsPromotions: true,
            hasSalesReports: false,
            hasSettings: false,
            hasSettingsWrite: false
        },
        snackos: {
            cashDrawer: false,
            discounts: false,
            orders: true,
            sales: false
        }
    },
    accountant: {
        rdb: {
            hasBilling: true,
            hasBillingWrite: false,
            hasCatering: false,
            hasDashboard: false,
            hasDevices: false,
            hasEditAdmin: false,
            hasEmployees: false,
            hasFinancialReports: true,
            hasGuestbook: false,
            hasMenuEditing: false,
            hasOrders: true,
            hasPayouts: false,
            hasPromos: false,
            hasReportsCustomers: true,
            hasReportsGiftCards: true,
            hasReportsLabor: true,
            hasReportsLocations: true,
            hasReportsMenu: true,
            hasReportsPromotions: true,
            hasSalesReports: true,
            hasSettings: true,
            hasSettingsWrite: false
        },
        snackos: {
            cashDrawer: false,
            discounts: false,
            orders: false,
            sales: true
        }
    },
    /** lowest permission level */
    employee: {
        rdb: {
            hasBilling: false,
            hasBillingWrite: false,
            hasCatering: false,
            hasDashboard: false,
            hasDevices: false,
            hasEditAdmin: false,
            hasEmployees: false,
            hasFinancialReports: false,
            hasGuestbook: false,
            hasMenuEditing: false,
            hasOrders: false,
            hasPayouts: false,
            hasPromos: false,
            hasReportsCustomers: false,
            hasReportsGiftCards: false,
            hasReportsLabor: false,
            hasReportsLocations: false,
            hasReportsMenu: false,
            hasReportsPromotions: false,
            hasSalesReports: false,
            hasSettings: false,
            hasSettingsWrite: false
        },
        snackos: {
            cashDrawer: false,
            discounts: false,
            orders: false,
            sales: false
        }
    }
};

// These are used to report user type to Segment (and they show up in the UI for searching etc)
export const SnackOSRole: Record<
    SnackOSUserRole,
    { name: string; roles: Record<string, string> }
> = {
    EXEC: {
        name: "Executive and Management",
        roles: {
            PROPRIETOR: "Proprietor",
            OPERATOR: "Operator",
            GENERAL_MANAGER: "General Manager",
            STORE_MANAGER: "Store Manager",
            SHIFT_LEADER: "Shift Leader",
            SUPERVISOR: "Supervisor"
        }
    },
    BACK_OF_HOUSE: {
        name: "Kitchen and Food Preparation",
        roles: {
            CHEF: "Chef",
            COOK: "Cook",
            BAKER: "Baker",
            KITCHEN_ASSISTANT: "Kitchen Assistant",
            PREP_COOK: "Prep Cook"
        }
    },
    FRONT_OF_HOUSE: {
        name: "Service and Front-of-House",
        roles: {
            SERVER: "Server",
            BARISTA: "Barista",
            CASHIER: "Cashier",
            BARTENDER: "Bartender",
            HOST: "Host/Hostess"
        }
    },
    SUPPORT: {
        name: "Support and Maintenance",
        roles: {
            MAINTENANCE: "Maintenance/Janitorial Staff",
            DISHWASHER: "Dishwasher",
            ADMIN_ASSIST: "Administrative Assistant"
        }
    },
    ADDDITIONAL: {
        name: "Additional",
        roles: {
            PART_TIME: "Part-Time Employee",
            TRAINEE: "Trainee/Intern",
            STAFF: "Staff",
            OTHER: "Other"
        }
    }
};

/** mapping of RDBUserRoles to display friendly name and description */
export const PermissionsPresets: Record<
    RDBUserRole,
    { name: string; desc: string }
> = {
    admin: {
        name: "Admin",
        desc: "Access full dashboard, manage team members, and perform SnackOS actions."
    },
    manager: {
        name: "Manager",
        desc: "Access orders, reports, catering, guestbook, promos, team, devices, and settings."
    },
    staff: {
        name: "Staff",
        desc: "View-only access to orders, catering, devices, and store settings."
    },
    marketer: {
        name: "Marketer",
        desc: "View-only access to orders, customers, promos, guestbook, and store settings."
    },
    accountant: {
        name: "Accountant",
        desc: "View-only access to orders, reports, billing & payouts, and store settings."
    },
    employee: {
        name: "Time Clocking Only",
        desc: "Can use time card system but no access to this site."
    }
};

export interface PendingAdminInfo {
    adminFields: AdminFields;
    permissionUpdates: Record<string, boolean>;
    employeePermissions?: EmployeePermissions;
}

// currently only used to populate a filter on the admin table, maybe consolidate?
export const PermissionsList: {
    label: string;
    value: keyof SnackpassPermissions;
}[] = [
    { label: "Dashboard Page", value: "hasDashboard" },
    { label: "Guestbook", value: "hasGuestbook" },
    { label: "Reports → Locations", value: "hasReportsLocations" },
    { label: "Reports → Sales", value: "hasSalesReports" },
    { label: "Reports → Menu", value: "hasReportsMenu" },
    { label: "Reports → Customers", value: "hasReportsCustomers" },
    { label: "Reports → Promotions", value: "hasReportsPromotions" },
    { label: "Reports → Financial", value: "hasFinancialReports" },
    { label: "Reports → Labor", value: "hasReportsLabor" },
    { label: "Reports → Gift Cards", value: "hasReportsGiftCards" },
    { label: "Orders", value: "hasOrders" },
    { label: "Menu", value: "hasMenuEditing" },
    { label: "Catering", value: "hasCatering" },
    { label: "Promos", value: "hasPromos" },
    { label: "Team", value: "hasEmployees" },
    { label: "Devices", value: "hasDevices" },
    {
        label: "Create, Edit, and Change Permissions for All Users",
        value: "hasEditAdmin"
    },
    { label: "Payouts", value: "hasPayouts" },
    { label: "Billing (Read)", value: "hasBilling" },
    { label: "Billing (Write)", value: "hasBillingWrite" },
    { label: "Store Settings (Read)", value: "hasSettings" },
    { label: "Store Settings (Write)", value: "hasSettingsWrite" }
];
