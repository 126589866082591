import { Reference } from "@apollo/client";
import { useCallback } from "react";

import {
    UpdateMenuMutationVariables,
    StoreMenuFieldsFragmentDoc,
    useUpdateMenuMutation
} from "src/api/graphql/generated/types";

export function useUpdateMenu() {
    const [updateMenuMutation, { loading, error, reset, data }] =
        useUpdateMenuMutation();
    const updateMenu = useCallback(
        async (variables: UpdateMenuMutationVariables) => {
            const result = await updateMenuMutation({
                variables,
                // https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
                update(cache, { data }) {
                    if (!data?.updateStoreMenu) return;
                    cache.modify({
                        fields: {
                            storeMenus(
                                existingMenus: Reference[] = [],
                                { readField }
                            ) {
                                const newRef = cache.writeFragment({
                                    data: data.updateStoreMenu,
                                    fragment: StoreMenuFieldsFragmentDoc
                                });
                                return existingMenus.map((ref) =>
                                    readField("id", ref) ===
                                    data.updateStoreMenu.id
                                        ? newRef
                                        : ref
                                );
                            }
                        }
                    });
                }
            }).finally(reset);
            return result;
        },
        [updateMenuMutation, reset]
    );
    return {
        updateMenu,
        loading,
        error,
        data
    };
}
