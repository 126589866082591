import { useCallback, useState } from "react";
import { Column } from "@tanstack/react-table";
import { format, isAfter, isBefore, isEqual, startOfDay } from "date-fns";
import { CalendarIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { useLocalStorage } from "@uidotdev/usehooks";
import { SelectSingleEventHandler } from "react-day-picker";

import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from "src/@/components/ui/popover";
import { Calendar } from "src/@/components/ui/calendar";
import { Command, CommandItem, CommandList } from "src/@/components/ui/command";
import { Separator } from "src/@/components/ui/separator";
import { cn } from "src/@/lib/utils";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStore } from "src/redux/selectors";

type DataTableDateFilterMatcherFn = (
    selectedDate: Date
) => (rowDate: Date) => boolean;

const DataTableDateFilterMatchers = {
    on(selectedDate) {
        const selectedDateTruncated = startOfDay(selectedDate);
        return (rowDate) => {
            const rowDateTruncated = startOfDay(rowDate);
            return isEqual(rowDateTruncated, selectedDateTruncated);
        };
    },

    after: (selectedDate) => (rowDate) =>
        isEqual(rowDate, selectedDate) || isAfter(rowDate, selectedDate),

    before: (selectedDate) => (rowDate) =>
        isEqual(rowDate, selectedDate) || isBefore(rowDate, selectedDate)
} satisfies Record<string, DataTableDateFilterMatcherFn>;

type DataTableDateFilterComparison = keyof typeof DataTableDateFilterMatchers;

export interface DataTableDateFilterOptions {
    title: string;
    prompt: string;
    localStorage?: {
        key: string;
        storeScoped: boolean;
    };
    defaultDateComparison: DataTableDateFilterComparison;
    valueFormatString: string;
}

export interface DataTableDateFilterProps<TData, TValue>
    extends DataTableDateFilterOptions {
    column: Column<TData, TValue>;
}

export interface DataTableDateFilterValue {
    /**
     * Helper to check if a date matches the filter.
     *
     * @example
     * filterValue.match(row.original.createdAt)
     */
    match(date: Date): boolean;

    /**
     * Private in the context of `DataTableDateFilter`.
     */
    _comparison: DataTableDateFilterComparison;
    _value: Date;
}

const NOOP_LOCALSTORAGE_KEY = "__useOptionalLocalStorage__noop__";

function useOptionalLocalStorage<T>(
    options: DataTableDateFilterOptions["localStorage"],
    defaultValue: T
) {
    const storeId = useAppSelector(getActiveStore)?._id ?? "";
    const storeScopePrefix = options?.storeScoped ? `$${storeId}.` : "";
    const localStorageKey = options?.key
        ? storeScopePrefix + options.key
        : NOOP_LOCALSTORAGE_KEY;

    const localStorageReturn = useLocalStorage(localStorageKey, defaultValue);
    const stateReturn = useState(defaultValue);

    return options ? localStorageReturn : stateReturn;
}

export function DataTableDateFilter<TData, TValue>({
    column,
    title,
    prompt,
    defaultDateComparison,
    localStorage: localStorageOptions,
    valueFormatString
}: DataTableDateFilterProps<TData, TValue>) {
    const [currentComparison, setCurrentComparison] = useOptionalLocalStorage(
        localStorageOptions,
        defaultDateComparison
    );

    const [isCalendarDropdownOpen, setCalendarDropdownOpen] = useState(false);
    const [isComparisonDropdownOpen, setComparisonDropdownOpen] =
        useState(false);

    const currentFilter = column.getFilterValue() as
        | DataTableDateFilterValue
        | undefined;

    /**
     * not using `useCallback` because it depends on `currentFilter`
     */
    function handleComparisonSelect(
        newComparison: DataTableDateFilterComparison
    ) {
        return () => {
            if (currentFilter) {
                column.setFilterValue({
                    _value: currentFilter._value,
                    _comparison: newComparison,
                    match: DataTableDateFilterMatchers[newComparison](
                        currentFilter._value
                    )
                } satisfies DataTableDateFilterValue);
            }
            setCurrentComparison(newComparison);
            setComparisonDropdownOpen(false);
        };
    }

    const segmentButtonClassName = cn(`
        inline-flex items-center justify-center px-2 hover:bg-accent
        hover:text-accent-foreground cursor-pointer user-select-none
        h-full whitespace-nowrap
    `);

    const onCalendarSelect: SelectSingleEventHandler = useCallback(
        (date) => {
            if (!date) {
                column.setFilterValue(undefined);
                return;
            }
            column.setFilterValue({
                _value: date,
                _comparison: currentComparison,
                match: DataTableDateFilterMatchers[currentComparison](date)
            } satisfies DataTableDateFilterValue);
            setCalendarDropdownOpen(false);
        },
        [column, currentComparison]
    );

    return (
        <Popover
            open={isCalendarDropdownOpen}
            onOpenChange={setCalendarDropdownOpen}
        >
            <div
                className={`
                    flex h-8 items-center rounded-md border border-dashed
                    border-input pl-3 text-xs font-medium shadow-sm
                `}
            >
                <div className="inline-flex cursor-default items-center justify-center pr-2">
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {title}
                </div>
                <Separator orientation="vertical" className="h-4" />
                <Popover
                    open={isComparisonDropdownOpen}
                    onOpenChange={setComparisonDropdownOpen}
                >
                    <PopoverTrigger asChild>
                        <div className={segmentButtonClassName}>
                            {currentComparison}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-2">
                        <Command>
                            <CommandList>
                                {(
                                    Object.keys(
                                        DataTableDateFilterMatchers
                                    ) as DataTableDateFilterComparison[]
                                ).map(
                                    (value) =>
                                        currentComparison !== value && (
                                            <CommandItem
                                                key={value}
                                                className="cursor-pointer text-xs"
                                                onSelect={handleComparisonSelect(
                                                    value
                                                )}
                                            >
                                                {value}
                                            </CommandItem>
                                        )
                                )}
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
                <Separator orientation="vertical" className="h-4" />
                <PopoverTrigger asChild>
                    <div className={segmentButtonClassName}>
                        {currentFilter?._value
                            ? format(currentFilter._value, valueFormatString)
                            : prompt}
                    </div>
                </PopoverTrigger>
                {!!currentFilter && (
                    <>
                        <Separator orientation="vertical" className="h-4" />
                        <div
                            className={segmentButtonClassName}
                            onClick={() => {
                                column.setFilterValue(undefined);
                            }}
                        >
                            <CrossCircledIcon />
                        </div>
                    </>
                )}
            </div>

            <PopoverContent className="w-auto p-0" align="start">
                <div className="flex w-full rounded-md bg-transparent p-2 text-sm font-semibold outline-none">
                    {title} {currentComparison}
                </div>
                <Calendar
                    mode="single"
                    selected={currentFilter?._value}
                    onSelect={onCalendarSelect}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}
