import { getActiveStoreId } from "@snackpass/accounting";
import { useMemo } from "react";

import { StoreMenu, useGetMenusQuery } from "src/api/graphql/generated/types";
import { useAppSelector } from "src/redux/hooks";

export function useMenus() {
    const storeId = useAppSelector(getActiveStoreId);
    const { data, loading, error } = useGetMenusQuery({
        variables: { storeId }
    });

    // NB: sifting the data here because the codegen'd type says the elements can potentially be null
    const typedData: StoreMenu[] = useMemo(
        () => data?.storeMenus.filter((e): e is StoreMenu => !!e) ?? [],
        [data]
    );

    return { data: typedData, loading, error };
}
