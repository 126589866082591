import { useRecoilValue } from "recoil";

import {
    CampaignScreenHeader,
    NewCampaignButton
} from "#guestbook/screens/Campaigns/CampaignScreenHeader";
import { newCampaignStepAtom } from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";
import NewCampaignForm from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignForm";
import { PastCampaigns } from "#guestbook/screens/Campaigns/PastCampaigns";
import { ReachableSMSBanner } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/ReachableSMSBanner";
import RequiresPaymentMethod from "#guestbook/shared-components/RequiresPaymentMethod";

function ApprovedCampaignIndex() {
    const newCampaignStep = useRecoilValue(newCampaignStepAtom);
    if (newCampaignStep != null) {
        return <NewCampaignForm />;
    }
    return (
        <>
            <CampaignScreenHeader
                button={
                    <RequiresPaymentMethod
                        billingDisabledChild={
                            <NewCampaignButton billingDisabled />
                        }
                        billingEnabledChild={<NewCampaignButton />}
                    />
                }
            />
            <div className="mx-auto flex max-w-screen-md justify-center p-4">
                <ReachableSMSBanner />
            </div>
            <PastCampaigns campaignType="sms" />
        </>
    );
}

export default ApprovedCampaignIndex;
