import React, { useEffect, useState, useCallback } from "react";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import { captureException } from "@sentry/react";
import { IStore } from "@snackpass/snackpass-types";

import { getActiveStore } from "src/redux/selectors";
import { ScreenLayout } from "#reusable/layout";
import api from "src/api/rest";
import { LiveIntegrations } from "#settings-integrations/components/live-integrations";
import { IntegrationOverviewInformation } from "#settings-integrations/components/integration-overview-information";
import { ErrorWithCause } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { ChannelLink } from "#settings-integrations/modules/deliverect";
import { Alert, AlertTitle, AlertDescription } from "src/@/components/ui/alert";
import { FeatureBadge } from "#navigation/FeatureBadge";

const DELIVERECT_SUPPORT_URL =
    "https://support.snackpass.co/en/articles/7437684-deliverect-delivery-integration";

const PageHeader = () => (
    <div className="flex items-center space-x-2">
        <div className="text-xlarge font-semibold text-neutral-950">
            Integrations
        </div>
        <FeatureBadge variant="beta" />
    </div>
);

const PageDescription = () => (
    <div className="text-body font-normal text-neutral-600">
        Connect third party delivery platforms to see all of your orders in one
        place.
    </div>
);

const SupportInfoAlert = () => (
    <Alert variant="info" className="my-6">
        <AlertTitle>Integration Support</AlertTitle>
        <AlertDescription>
            Please read this article for important guidelines on how to set up
            and use integrations &mdash;&ensp;
            <a href={DELIVERECT_SUPPORT_URL} target="_blank">
                support page
            </a>
            .
        </AlertDescription>
    </Alert>
);

const ErrorAlert = () => (
    <Alert variant="warning" className="my-4">
        <AlertTitle>An error occurred</AlertTitle>
        <AlertDescription>
            We were unable to process your request. Please try again, or contact
            support if the issue persists.
        </AlertDescription>
    </Alert>
);

export const Integrations = () => {
    const canEdit = useHasEditSettingsForActiveStore();
    const activeStore = useSelector(getActiveStore);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreatingIntegrationView, setShowCreatingIntegrationView] =
        useState<boolean>(false);
    const [channels, setChannels] = useState<ChannelLink[]>([]);

    useEffect(() => {
        setIsLoading(true);
        if (activeStore && activeStore._id) {
            const getIntegrations = async () => {
                try {
                    const res = await api.deliverect.getChannelLinks({
                        storeId: activeStore._id
                    });

                    if (res.data.success) {
                        const nonTestChannels = res.data.channels.filter(
                            ({ name }) => !name.toLowerCase().includes("test")
                        );

                        setChannels(nonTestChannels);
                    } else {
                        throw new Error("Failed to load enabled integrations.");
                    }
                } catch (err) {
                    if (err instanceof ErrorWithCause) {
                        // Handle GET Deliverect Channel Request not working
                        toast.error("Failed to load enabled integrations.");
                    } else {
                        toast.error("An error has occurred.");
                        // Only log to Sentry for non-handled errors
                        captureException(Error(JSON.stringify(err)));
                    }
                    setChannels([]);
                } finally {
                    setIsLoading(false);
                }
            };
            void getIntegrations();
        }
    }, [activeStore, activeStore?._id]);

    const renderOverview = useCallback(
        () => (
            <div className="max-w-3xl">
                <SupportInfoAlert />
                <IntegrationOverviewInformation
                    canEdit={canEdit}
                    showCreatingIntegrationView={showCreatingIntegrationView}
                    setShowCreatingIntegrationView={
                        setShowCreatingIntegrationView
                    }
                />
            </div>
        ),
        [canEdit, showCreatingIntegrationView, setShowCreatingIntegrationView]
    );

    const renderLiveIntegrations = useCallback(
        ({ _id, name }: IStore) => (
            <>
                <SupportInfoAlert />
                <LiveIntegrations
                    channels={channels}
                    storeId={_id}
                    storeName={name}
                    showCreatingIntegrationView={showCreatingIntegrationView}
                    setShowCreatingIntegrationView={
                        setShowCreatingIntegrationView
                    }
                />
            </>
        ),
        [channels, showCreatingIntegrationView]
    );

    const renderContent = () => {
        if (!activeStore) {
            return <ErrorAlert />;
        }

        if (channels.length === 0) {
            return renderOverview();
        }

        return renderLiveIntegrations(activeStore);
    };

    return (
        <div className="h-full">
            <ScreenLayout
                header={<PageHeader />}
                isLoading={isLoading || !channels}
                description={<PageDescription />}
                content={renderContent()}
            />
        </div>
    );
};
