import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo } from "react";
import { Close } from "@radix-ui/react-dialog";
import { match } from "ts-pattern";

import { Checkbox } from "src/@/components/ui/checkbox";
import { DataTableComponent } from "src/@/components/ui/data-table/data-table-component";
import { Dialog, DialogContent } from "src/@/components/ui/dialog";
import { Button } from "src/@/components/ui/button";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { useDataTable } from "src/@/components/ui/data-table";
import { Category } from "#menu-manager/hooks";

type Props = {
    selectedCategoryIDs: string[];
    categories: Category[];
    onChange: (e: string[]) => void;
    open?: boolean;
    close: () => void;
    description?: string;
};

export function SelectCategoriesDialog({
    selectedCategoryIDs,
    categories,
    onChange,
    open,
    close,
    description
}: Props) {
    const data = useMemo(
        () =>
            categories?.filter((e) => !selectedCategoryIDs?.includes(e.id)) ??
            [],
        [selectedCategoryIDs, categories]
    );

    const table = useDataTable({
        data,
        columns,
        customPageSize: 9999999 // react table doesn't have an infinite scroll prop by default, so setting large page size
    });

    useEffect(() => table.resetRowSelection(), [open, table]);

    const handleChange = useCallback(() => {
        onChange(table.getSelectedRowModel().rows.map((e) => e.original.id));
    }, [onChange, table]);

    return (
        <Dialog open={open} onOpenChange={close}>
            <DialogContent className="w-full md:max-w-[640px] [&_th:has([role=checkbox])]:w-10">
                <div className="text-center font-semibold">
                    Select Categories
                </div>
                {description ? <div>{description}</div> : null}
                <div className="max-h-[70vh] overflow-y-auto p-1">
                    <DataTableComponent
                        table={table}
                        toolbar={{
                            search: {
                                key: "name",
                                placeholder: "Search"
                            }
                        }}
                        className="rounded-none border-x-0"
                        getRowClickListener={(row) => () => {
                            row.toggleSelected(!row.getIsSelected());
                        }}
                        emptyText="No New Categories to Add"
                    />
                </div>
                <div className="flex space-x-2">
                    <Close asChild>
                        <Button variant="outline">Cancel</Button>
                    </Close>
                    <Close asChild>
                        <Button
                            onClick={handleChange}
                            disabled={
                                table.getSelectedRowModel().rows.length === 0
                            }
                            className="flex-1"
                        >
                            Add {table.getSelectedRowModel().rows.length}{" "}
                            {match({
                                length: table.getSelectedRowModel().rows.length
                            })
                                .with({ length: 1 }, () => "Category")
                                .otherwise(() => "Categories")}
                        </Button>
                    </Close>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const columns: ColumnDef<Category>[] = [
    {
        id: "checkbox",
        header: ({ table }) => (
            <Checkbox
                onCheckedChange={(checked) =>
                    table.toggleAllRowsSelected(!!checked)
                }
                checked={table.getIsAllRowsSelected()}
            />
        ),
        accessorFn: () => null,
        cell: ({ row }) => (
            <div>
                <Checkbox
                    name={row.original.name}
                    onCheckedChange={(checked) => {
                        row.toggleSelected(!!checked);
                    }}
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                />
            </div>
        ),
        size: 40
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title={"Category"} />
        ),
        id: "name",
        accessorKey: "name",
        cell: ({ row }) => (
            <div className="flex items-center space-x-5">
                <span>{row.original.name}</span>
            </div>
        )
    },
    {
        header: "Items",
        id: "Items",
        accessorKey: "items",
        cell: ({ row }) => <>{row.original.productIds.length} items</>
    }
];
