import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { Button, buttonVariants } from "src/@/components/ui/button";
import api from "src/api/rest";
import { RootState } from "src/redux/store";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import { readablePayoutPolicyType } from "#payouts/components/PolicyManagement/readable-policy";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import {
    Form,
    FormControl,
    FormField,
    FormItem
} from "src/@/components/ui/form";
import { setActiveStore } from "src/redux/slices";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";

const PAYOUT_POLICY_TYPES_ENUM = [
    "WeeklyPayoutPolicy",
    "DailyPayoutPolicy",
    "ExpeditedDailyPayoutPolicy"
] as const;

const FormSchema = z.object({
    type: z.enum(PAYOUT_POLICY_TYPES_ENUM).nullable()
});

type FormValues = z.infer<typeof FormSchema>;

const INITIAL_FORM_VALUES: FormValues = {
    type: null
};

export const PayoutPolicyManagement = () => {
    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: INITIAL_FORM_VALUES
    });

    const { _id, payoutPolicy, pendingPayoutPolicy } = useSelector(
        (state: RootState) =>
            state.activeStore || {
                _id: undefined,
                payoutPolicy: null,
                pendingPayoutPolicy: null
            }
    );

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    const dispatch = useDispatch();

    const resetForm = useCallback(() => {
        form.reset({ type: null });
    }, [form]);

    useEffect(() => {
        if (_id) {
            resetForm();
        }
    }, [_id, form.reset, resetForm]);

    const onSubmit = async (values: z.infer<typeof FormSchema>) => {
        if (!_id || values.type === undefined) return;

        const changeToType =
            values.type === payoutPolicy?.__t ? null : values.type;

        try {
            const response = await api.stores.updatePendingPayoutPolicy(_id, {
                type: changeToType
            });
            dispatch(setActiveStore(response.data.store));
            toast.success("Payout schedule updated");
        } catch (e) {
            console.error(e);
            toast.error("Failed to update payout schedule");
        } finally {
            resetForm();
        }
    };

    return (
        <div className="h-max min-h-screen overflow-auto bg-gray-50">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div className="flex h-full grow flex-col space-y-8 p-8 sm:mb-0 sm:p-16">
                        <div>
                            <CardTitle className="text-2xl font-bold">
                                Payout Schedule
                            </CardTitle>
                            <CardDescription className="pt-0.5">
                                Manage how frequently your store is paid
                            </CardDescription>

                            <hr className="border-gray-300" />
                        </div>

                        <Card className="max-w-4xl border-neutral-400">
                            <CardHeader className="flex flex-col justify-between pb-0">
                                <CardTitle className="my-auto text-large">
                                    Current Schedule
                                </CardTitle>
                                <CardDescription>
                                    <p>
                                        Changes to your schedule do not take
                                        place immediately. Please read the{" "}
                                        <a
                                            href="https://support.snackpass.co/en/articles/9160716-payouts#h_9885b3ee9a"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            payout schedule support page
                                        </a>{" "}
                                        prior to making changes.
                                    </p>
                                </CardDescription>
                            </CardHeader>
                            <CardContent className="mt-4">
                                <div>
                                    <b>Active:</b>
                                    {" " +
                                        readablePayoutPolicyType(
                                            payoutPolicy?.__t
                                        )}
                                </div>
                                <div>
                                    <b>Pending:</b>
                                    {" " +
                                        readablePayoutPolicyType(
                                            pendingPayoutPolicy?.__t
                                        )}
                                </div>
                            </CardContent>
                        </Card>

                        <Card className="max-w-4xl border-neutral-400">
                            <CardHeader className="flex flex-col justify-between">
                                <CardTitle className="my-auto text-large">
                                    Schedule Management
                                </CardTitle>
                                <CardDescription>
                                    Make changes to your active payout schedule
                                </CardDescription>
                            </CardHeader>
                            <CardContent>
                                <FormField
                                    control={form.control}
                                    name="type"
                                    render={({ field }) => (
                                        <FormItem>
                                            <CardTitle className="text-base font-medium">
                                                Schedule
                                            </CardTitle>
                                            <CardDescription>
                                                The selected payout schedule
                                                will become pending
                                            </CardDescription>
                                            <FormControl>
                                                <Select
                                                    onValueChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={
                                                        field.value || undefined
                                                    }
                                                >
                                                    <SelectTrigger className="max-w-60">
                                                        <SelectValue placeholder="Select a schedule" />
                                                    </SelectTrigger>

                                                    <SelectContent>
                                                        {PAYOUT_POLICY_TYPES_ENUM.map(
                                                            (type) => (
                                                                <SelectItem
                                                                    key={type}
                                                                    value={type}
                                                                >
                                                                    <div className="flex flex-row gap-1">
                                                                        <p>
                                                                            {readablePayoutPolicyType(
                                                                                type
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </SelectItem>
                                                            )
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                {form.formState.errors.type && (
                                    <div className="text-red-600">
                                        {JSON.stringify(
                                            form.formState.errors.type
                                        )}
                                    </div>
                                )}
                            </CardContent>

                            {form.formState.isDirty ? (
                                <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                                    <Button
                                        variant="secondary"
                                        onClick={resetForm}
                                        className={buttonVariants({
                                            variant: "outline"
                                        })}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={form.formState.isLoading}
                                        className={buttonVariants({
                                            variant: "default"
                                        })}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            ) : null}
                        </Card>
                    </div>
                </form>
            </Form>
        </div>
    );
};
