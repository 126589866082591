import { CashDrawerWithPrinter } from "@snackpass/snackpass-types";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { Button } from "src/@/components/ui/button";
import { Dialog, DialogContent } from "src/@/components/ui/dialog";
import api from "src/api/rest";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    DeviceType,
    KioskDevice,
    PrinterDevice
} from "#devices/utils/deviceTypes";
import { logAndSendError } from "src/utils/errors";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStore } from "src/redux/selectors";

type Props = {
    cashDrawer?: CashDrawerWithPrinter;
    open: boolean;
    setOpen: (open: boolean) => void;
};

const CashDrawerUpdateOrCreateModal = ({
    cashDrawer,
    open,
    setOpen
}: Props) => {
    const { isLoading, updateOrCreateCashDrawer, isSuccess, error } =
        useUpdateOrCreateCashDrawer(cashDrawer?.id);

    const [name, setName] = useState("");
    const isNewCashDrawer = !cashDrawer;

    useEffect(() => {
        if (open && !!cashDrawer) {
            setName(cashDrawer?.name);
        }
    }, [open, setName, cashDrawer]);

    useEffect(() => {
        if (isSuccess) {
            void toast.success(
                `Successfully ${
                    isNewCashDrawer ? "created" : "updated"
                } cash drawer.`
            );
            setOpen(false);
        }
        if (error) {
            void toast.error(
                `${
                    isNewCashDrawer ? "Creating" : "Updating"
                } cash drawer failed.`,
                { description: "Please try again." }
            );
            logAndSendError(error);
        }
    }, [isSuccess, error]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent overlayClassName="z-[1000]" className="z-[1001]">
                <h3>{isNewCashDrawer ? "New" : "Edit"} Cash Drawer</h3>
                <label>Name</label>
                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`block w-full rounded-md p-2
                        text-body leading-6
                        placeholder:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    placeholder="Cash Drawer Name"
                />

                <Button
                    variant="outline"
                    onClick={() => updateOrCreateCashDrawer({ name })}
                    disabled={
                        isLoading || name === cashDrawer?.name || name === ""
                    }
                >
                    {isLoading ? <Spinner speed={0.5} size={18} /> : "Submit"}
                </Button>
            </DialogContent>
        </Dialog>
    );
};

const useUpdateOrCreateCashDrawer = (cashDrawerId?: string) => {
    const activeStore = useAppSelector(getActiveStore);
    const { device } = useContext(DevicesPageContext);
    const { refetch } = useCashDrawers();

    const { isPending, mutate, isSuccess, error } = useMutation({
        onSuccess: () => {
            void refetch();
        },
        mutationFn: async (body: { name: string }) => {
            if (!activeStore?._id) {
                throw new Error("Store is not defined.");
            }

            const printerDevice =
                device?.deviceType === DeviceType.Printer
                    ? (device as PrinterDevice)
                    : null;

            const kioskDevice =
                device?.deviceType === DeviceType.Kiosk
                    ? (device as KioskDevice)
                    : null;

            // submit with the kiosk serial or the printer id, based on what type of device we are currently editing.
            // important to send null for the other device type to avoid connecting a drawer to multiple devices.
            const cashDrawer = {
                name: body.name,
                printer: printerDevice?.deviceDetails?.id ?? null,
                device: kioskDevice?.serial ?? null
            };
            return cashDrawerId
                ? api.cashDrawers.updateCashDrawer(cashDrawerId, cashDrawer)
                : api.cashDrawers.createCashDrawer(
                      activeStore?._id,
                      cashDrawer
                  );
        }
    });
    return {
        isLoading: isPending,
        updateOrCreateCashDrawer: mutate,
        isSuccess,
        error
    };
};

export default CashDrawerUpdateOrCreateModal;
