import { useCallback } from "react";

import { useProductToCategoryMap } from "#menu/hooks";

export type ProductToCategory = { [productId: string]: string };

/**
 * Provides a set of functions to check for conflicts between prep station settings.
 */
export function usePrepStationConflictsAPI() {
    const productToCategoriesMap = useProductToCategoryMap();

    /**
     * If allow product contains items whose categories belong to an allowed category (no need to specify, redundant).
     */
    const hasInclusiveProductConflict = useCallback(
        (allowCategoryList: string[], allowProductList: string[]): boolean => {
            const filterAllowProductsByCategory = allowProductList.filter(
                (product) =>
                    allowCategoryList.includes(productToCategoriesMap[product])
            );

            return filterAllowProductsByCategory.length !== 0;
        },
        [productToCategoriesMap]
    );

    /**
     * If reject product has items whose categories are not in allow category (required).
     */
    const hasInclusiveRejectConflict = useCallback(
        (allowCategoryList: string[], rejectProductList: string[]): boolean => {
            const filterRejectProductsByCategory = rejectProductList.filter(
                (product) =>
                    !allowCategoryList.includes(productToCategoriesMap[product])
            );

            return (
                rejectProductList.length !== 0 &&
                filterRejectProductsByCategory.length !== 0
            );
        },
        [productToCategoriesMap]
    );

    const hasChannelFulfillmentMinimum = useCallback(
        (
            allowChannelList: Array<string>,
            allowFulfillmentList: Array<string>
        ): boolean =>
            !!(allowChannelList.length || allowFulfillmentList.length),
        []
    );

    const hasInclusiveMinimum = useCallback(
        (
            allowCategoryList: Array<string>,
            allowProductList: Array<string>
        ): boolean => !!(allowCategoryList.length || allowProductList.length),
        []
    );

    /**
     * If reject product has items already covered by reject category.
     */
    const hasExclusiveProductConflict = useCallback(
        (
            rejectCategoryList: string[],
            rejectProductList: string[]
        ): boolean => {
            const filterRejectProductsByCategory = rejectProductList.filter(
                (product) =>
                    rejectCategoryList.includes(productToCategoriesMap[product])
            );

            return filterRejectProductsByCategory.length !== 0;
        },
        [productToCategoriesMap]
    );

    /**
     * If allow product has items not rejected by category.
     */
    const hasExclusiveAllowConflict = useCallback(
        (rejectCategoryList: string[], allowProductList: string[]): boolean => {
            const filterAllowProductsByCategory = allowProductList.filter(
                (product) =>
                    !rejectCategoryList.includes(
                        productToCategoriesMap[product]
                    )
            );

            return filterAllowProductsByCategory.length !== 0;
        },
        [productToCategoriesMap]
    );

    return {
        hasInclusiveProductConflict,
        hasInclusiveRejectConflict,
        hasInclusiveMinimum,
        hasExclusiveProductConflict,
        hasExclusiveAllowConflict,
        hasChannelFulfillmentMinimum
    };
}
