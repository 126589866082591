import { PlusIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

import { MenuTable } from "#menu-manager/components";
import { Button } from "src/@/components/ui/button";
import { MenuManagerRoutes } from "#menu-manager/routes";

export function MenuTableScreen() {
    return (
        <div>
            <div className="flex items-center justify-between px-8 py-4">
                <span className="text-large font-semibold">Menu Manager</span>
                <div className="flex items-center">
                    <Link to={MenuManagerRoutes.NEW_MENU}>
                        <Button size="sm">
                            <PlusIcon className="mr-1" />
                            New Menu
                        </Button>
                    </Link>
                </div>
            </div>
            <MenuTable />
        </div>
    );
}
