import { useCallback, useState } from "react";
import { z } from "zod";
import { Resolver, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { NumberInput } from "@tremor/react";

import { Label } from "src/@/components/ui/label";
import { Input } from "src/@/components/ui/input";
import { Button } from "src/@/components/ui/button";
import { useCreateTableMutation } from "src/api/graphql/generated/types";
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
    Form
} from "src/@/components/ui/form";
import { getActiveStoreId } from "src/redux/slices";
import { TableNameSchema } from "#table-definitions/types";

type NewTableProps = {
    onCreate: () => void;
    highestTableNum: number;
};

export const NewTable = ({ onCreate, highestTableNum }: NewTableProps) => {
    const activeStoreId = useSelector(getActiveStoreId);
    const [createTableMutation] = useCreateTableMutation();
    const [isLoading, setIsLoading] = useState(false);

    const resolver: Resolver<z.infer<typeof TableNameSchema>> = useCallback(
        async (data, context, options) =>
            zodResolver(TableNameSchema)(data, context, options),
        []
    );

    const form = useForm<z.infer<typeof TableNameSchema>>({
        resolver,
        defaultValues: {
            name: highestTableNum > -1 ? `${highestTableNum + 1}` : "",
            numSeats: undefined
        }
    });

    const handleSubmit = useCallback(
        async (formValues: z.infer<typeof TableNameSchema>) => {
            setIsLoading(true);

            try {
                await createTableMutation({
                    variables: {
                        storeId: activeStoreId,
                        input: {
                            ...formValues
                        }
                    }
                });

                toast.success("Table created");
                onCreate();
            } catch (err) {
                toast.error("Error creating table");
                form.reset({ ...formValues });
            }

            setIsLoading(false);
        },
        [activeStoreId, form, onCreate, createTableMutation]
    );

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <h1 className="text-2xl font-bold">Create Table</h1>
            </div>
            <Form {...form}>
                <form
                    className="space-y-6"
                    onSubmit={form.handleSubmit(handleSubmit)}
                >
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <Label htmlFor="name">Table Number</Label>
                            <FormField
                                disabled={isLoading}
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                data-1p-ignore
                                                id="name"
                                                placeholder="Enter table number"
                                                maxLength={4}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="number of seats">Number of Seats</Label>
                        <FormField
                            disabled={isLoading}
                            control={form.control}
                            name="numSeats"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <NumberInput
                                            min={0}
                                            placeholder=""
                                            className="flex-1 [&>input]:pl-2"
                                            value={field.value}
                                            onChange={(e) =>
                                                field.onChange(
                                                    Number.parseFloat(
                                                        e.target.value ?? 0
                                                    )
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        type="submit"
                        className="w-full"
                    >
                        Create Table
                    </Button>
                </form>
            </Form>
        </div>
    );
};
