import { Next } from "react-router-guards";
import { useCallback } from "react";

import { useAppSelector } from "src/redux/hooks";
import {
    getActiveStore,
    getUser,
    getUserTeamPermission
} from "src/redux/selectors";
import { Routes } from "#navigation/routes";

import { FromRouteProps, ToRouteProps } from "./types";

export function useRequireAuthForRoute() {
    const activeStore = useAppSelector(getActiveStore);
    const user = useAppSelector(getUser);
    const isSPEmployee = useAppSelector(getUserTeamPermission);

    return useCallback(
        (to: ToRouteProps, _from: FromRouteProps | null, next: Next) => {
            // User.permissions.samplePerm is an array of storeIds that the user has that permission for
            const _hasPermission = (
                storeIds: string[] | undefined
            ): boolean => {
                if (!storeIds || !storeIds.length || !activeStore?._id) {
                    return false;
                }
                return storeIds.includes(activeStore._id);
            };

            const perms = user?.snackpassPermissions ?? {
                isAdmin: false,
                storeIds: []
            };
            const newPerms = user?.permissions ?? {};
            const path = to.match.path as Routes;

            if (perms.isAdmin) {
                // isAdmin + SP Employee has access to everything
                if (perms.isSnackpassEmployee) {
                    next();
                }
                // isAdmin + owner of this store has access to everything
                if (
                    activeStore?._id &&
                    newPerms?.isOwner?.includes(activeStore?._id)
                ) {
                    next();
                }
            }

            let isPermitted = false;
            switch (path) {
                case Routes.Index:
                case Routes.Onboarding:
                case Routes.WhatsNew:
                    isPermitted = _hasPermission(newPerms.hasDashboard);
                    break;

                case Routes.Catering:
                    isPermitted = _hasPermission(newPerms.hasCatering);
                    break;

                case Routes.Invoices:
                case Routes.BetaOrders:
                case Routes.OrdersDetails:
                case Routes.PurchaseDetails:
                    isPermitted = _hasPermission(newPerms.hasOrders);
                    break;

                case Routes.ReportsSalesSummary:
                case Routes.ReportsSalesChannels:
                    isPermitted = _hasPermission(newPerms.hasSalesReports);
                    break;

                case Routes.ReportsMenuItemInsights:
                case Routes.ReportsMenuCategoryInsights:
                    isPermitted = _hasPermission(newPerms.hasReportsMenu);
                    break;

                case Routes.GuestbookCustomers:
                case Routes.ReportsCustomerDirectoryInsights:
                    isPermitted = _hasPermission(newPerms.hasReportsCustomers);
                    break;

                case Routes.ReportsPromotions:
                    isPermitted = _hasPermission(newPerms.hasReportsPromotions);
                    break;

                case Routes.ReportsGiftCardPurchases:
                case Routes.ReportsGiftCardBalances:
                case Routes.ReportsGiftCardTransactions:
                case Routes.ReportsGiftCardLiabilities:
                    isPermitted = _hasPermission(newPerms.hasReportsGiftCards);
                    break;

                case Routes.FinancialReports:
                case Routes.FinancialReportsPayoutHistory:
                case Routes.FinancialReportsCash:
                case Routes.FinancialReportsEssentials:
                    isPermitted = _hasPermission(newPerms.hasFinancialReports);
                    break;

                case Routes.ReportsLocationMenuCategories:
                case Routes.ReportsLocationSales:
                case Routes.ReportsLocationMenuItems:
                    isPermitted =
                        _hasPermission(newPerms.hasReportsLocations) &&
                        perms.storeIds.length > 1;
                    break;

                case Routes.EmployeeLaborCost:
                    isPermitted = _hasPermission(newPerms.hasReportsLabor);
                    break;

                case Routes.Promotion:
                case Routes.CreatePromotionAudience:
                case Routes.CreatePromotionGeneric:
                case Routes.CreatePromotionHappyHour:
                case Routes.CreatePromotionBogo:
                case Routes.CreatePromotionReward:
                case Routes.CreatePromotionStudents:
                case Routes.CreatePromotionFirstTime:
                case Routes.CreatePromotionDealDrop:
                case Routes.CreatePromotionPromoCode:
                case Routes.CreatePromotionGiftCard:
                    isPermitted = _hasPermission(newPerms.hasPromos);
                    break;

                case Routes.Inventory:
                case Routes.Menu:
                case Routes.MenuEditor:
                case Routes.MenuInventoryManager:
                case Routes.MultiMenus:
                case Routes.MultiMenusAddonOverridesEdit:
                case Routes.MultiMenusCreate:
                case Routes.MultiMenusEdit:
                case Routes.MultiMenusOutline:
                case Routes.MultiMenusSettings:
                case Routes.MultiMenusProductOverridesEdit:
                case Routes.MenuManager:
                    isPermitted = _hasPermission(newPerms.hasMenuEditing);
                    break;

                case Routes.Settings:
                case Routes.SettingsEmployees:
                case Routes.SettingsCatering:
                case Routes.SettingsGiftCard:
                case Routes.SettingsQrCode:
                case Routes.SettingsIntegrations:
                case Routes.SettingsBusinessInfo:
                case Routes.SettingsProducts:
                case Routes.SettingsBrandAndAppearance:
                case Routes.SettingsTipping:
                case Routes.SettingsPlatforms:
                case Routes.SettingsOrders:
                case Routes.SettingsOnlineOrders:
                case Routes.SettingsBackOfHouse:
                case Routes.SettingsAllOrders:
                case Routes.SettingsTax:
                case Routes.SettingsStoreTables:
                    isPermitted = _hasPermission(newPerms.hasSettings);
                    break;

                case Routes.EmployeeTimeCards:
                case Routes.SettingsPermissions:
                    isPermitted = _hasPermission(newPerms.hasEmployees);
                    break;

                case Routes.GuestbookCampaignsSMS:
                case Routes.GuestbookCampaignsSMSDetails:
                case Routes.GuestbookCampaignsPush:
                case Routes.GuestbookCampaignsPushDetails:
                case Routes.GuestbookAuditLog:
                case Routes.GuestbookConversations:
                    isPermitted = _hasPermission(newPerms.hasGuestbook);
                    break;

                case Routes.Billing:
                    isPermitted = _hasPermission(newPerms.hasBilling);
                    break;

                case Routes.Payouts:
                case Routes.PayoutsSchedule:
                case Routes.TaxForms:
                    isPermitted = _hasPermission(newPerms.hasPayouts);
                    break;

                case Routes.PrepStations:
                case Routes.Devices:
                    isPermitted = _hasPermission(newPerms.hasDevices);
                    break;

                // Internal Settings
                case Routes.InternalSettings:
                case Routes.InternalFees:
                case Routes.InternalSeniorSupport:
                    isPermitted = isSPEmployee;
                    break;

                // ----------- Defaults and special cases --------- //
                case Routes.SettingsLegal:
                case Routes.SettingsAccount: // This needs to be accessed by employees with no other permissions
                case Routes.MyAccountTeams: // This needs to be accessed by employees with no other permissions
                case Routes.NewUserAcceptInvite: // This needs to be accessed by employees with no other permissions
                case Routes.NotAllowed:
                case Routes.Logout:
                    isPermitted = true;
                    break;

                // Routes that were previously unchecked before adding assertUnreachable, and were redirecting to index.
                case Routes.Resources:
                    next.redirect(Routes.Index);
                    break;

                default:
                    assertUnreachable(path);
            }

            if (user?.snackpassPermissions.isAdmin && isPermitted) {
                next();
            } else {
                next.redirect(Routes.NotAllowed);
            }
        },
        [
            activeStore?._id,
            isSPEmployee,
            user?.permissions,
            user?.snackpassPermissions
        ]
    );
}

const assertUnreachable = (_: never): never => {
    throw new Error("Route was not handled properly.");
};
