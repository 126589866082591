import { useEffect, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { Routes } from "#navigation/routes";
import { useTrackSegmentEvent } from "#utils/segment";

export function useTrackPageViews() {
    const { pathname } = useLocation();
    const trackEvent = useTrackSegmentEvent();
    const matchedRoute: Routes | null = useMemo(
        () =>
            Object.entries(visitedRouteSegmentEvents).reduce<Routes | null>(
                (matchedRoute, [route, event]) => {
                    if (!event) return matchedRoute;
                    if (
                        matchPath(pathname, route) &&
                        (!matchedRoute || route.length > matchedRoute.length)
                    ) {
                        return route as Routes;
                    }
                    return matchedRoute;
                },
                null
            ),
        [pathname]
    );

    useEffect(() => {
        if (!matchedRoute) return;

        const event = visitedRouteSegmentEvents[matchedRoute];
        if (event) {
            trackEvent(event);
        }
    }, [matchedRoute]);
}

/**
 * This type ensures each route is accounted for.
 */
type RouteToSegmentEvent = {
    [route in Routes]: string | null;
};

/**
 * When adding a route to RDB, please add either a corresponding segment event upon visiting that route.
 * If visiting the page doesn't need to be tracked, you can input null to this dictionary.
 * If you need to attach more information to routes on page visit, input null to this dictionary and perform tracking on the individual component.
 */
const visitedRouteSegmentEvents: RouteToSegmentEvent = {
    [Routes.Index]: "Visited Index Screen",
    [Routes.WhatsNew]: "Visited What's New Screen",

    [Routes.BetaOrders]: "Visited Order Screen",
    [Routes.OrdersDetails]: null,
    [Routes.PurchaseDetails]: null, // this is an alias for the above
    [Routes.Catering]: "Visited Catering Screen",

    [Routes.Invoices]: "Visited Invoices Screen",

    [Routes.ReportsSalesSummary]: "User Viewed Sales Summary Report",
    [Routes.ReportsSalesChannels]: "User Viewed Sales Channels Report",
    [Routes.ReportsMenuItemInsights]: "User Viewed Menu Item Report",
    [Routes.ReportsMenuCategoryInsights]: "User Viewed Menu Category Report",
    [Routes.ReportsCustomerDirectoryInsights]:
        "User Viewed Customer Purchase Report",
    [Routes.GuestbookCustomers]: "User Viewed Customer Loyalty Report",
    [Routes.GuestbookAuditLog]: "User Viewed Customer Report Audit Log",

    [Routes.ReportsPromotions]: "User Viewed Menu Promotions Report",
    [Routes.ReportsGiftCardPurchases]: "User Viewed Gift Card Purchase Report",
    [Routes.ReportsGiftCardBalances]: "User Viewed Gift Card Balance Report",
    [Routes.ReportsGiftCardTransactions]:
        "User Viewed Gift Card Transaction Report",
    [Routes.ReportsGiftCardLiabilities]:
        "User Viewed Gift Card Liabilities Report",

    [Routes.ReportsLocationMenuCategories]:
        "User Viewed Menu Category by Location Report",
    [Routes.ReportsLocationSales]: "User Viewed Sales by Location Report",
    [Routes.ReportsLocationMenuItems]:
        "User Viewed Menu Item by Location Report",

    [Routes.FinancialReportsPayoutHistory]:
        "Visited Payout History Report Screen",
    [Routes.FinancialReportsCash]: "Visited Cash Screen",
    [Routes.FinancialReportsEssentials]:
        "Visited Financial Essentials Report Screen",

    [Routes.EmployeeLaborCost]: "Viewed Labor Cost Tab",

    [Routes.Promotion]: "Visited Promotions Screen",
    // promotion creations can be tracked by looking at actual promo objects
    [Routes.CreatePromotionAudience]: null,
    [Routes.CreatePromotionGeneric]: null,
    [Routes.CreatePromotionHappyHour]: null,
    [Routes.CreatePromotionBogo]: null,
    [Routes.CreatePromotionReward]: null,
    [Routes.CreatePromotionStudents]: null,
    [Routes.CreatePromotionFirstTime]: null,
    [Routes.CreatePromotionDealDrop]: null,
    [Routes.CreatePromotionPromoCode]: null,
    [Routes.CreatePromotionGiftCard]: null,

    [Routes.Menu]: null, // this route just immediately redirects to another menu route, so no need to track
    [Routes.MenuEditor]: "Visited Legacy Menu Editor",
    [Routes.MenuInventoryManager]: "Visited Inventory Screen",
    [Routes.MultiMenus]: "Visited Additional Menus Screen",

    [Routes.MenuManager]: "Visited Menus v3 Editor",

    // sub pages don't quite need to be tracked
    [Routes.MultiMenusAddonOverridesEdit]: null,
    [Routes.MultiMenusCreate]: null,
    [Routes.MultiMenusEdit]: null,
    [Routes.MultiMenusOutline]: null,
    [Routes.MultiMenusSettings]: null,
    [Routes.MultiMenusProductOverridesEdit]: null,

    // Settings pages currently under "growth suite"
    [Routes.SettingsIntegrations]: "Visited Integration Settings Screen",
    [Routes.SettingsQrCode]: "Visited QR Code Screen",

    [Routes.SettingsEmployees]: "Viewed Time and Attendance Settings Tab",
    [Routes.SettingsCatering]: "Visited Catering Settings Screen",
    [Routes.SettingsGiftCard]: "Visited Gift Card Settings Screen",
    [Routes.SettingsBusinessInfo]: "Visited Business Info Settings Screen",
    [Routes.SettingsProducts]: "Visited Product Settings Screen",
    [Routes.SettingsBrandAndAppearance]: "Visited Branding Settings Screen",
    [Routes.SettingsTipping]: "Visited Tipping Settings Screen",
    [Routes.SettingsPlatforms]: "Visited In-Store Orders Settings Screen",
    [Routes.SettingsOnlineOrders]: "Visited Online Orders Settings Screen",
    [Routes.SettingsBackOfHouse]: "Visited Back of House Settings Screen",
    [Routes.SettingsAllOrders]: "Visited All Orders Settings Screen",
    [Routes.SettingsTax]: "Visited Tax Settings Screen",
    [Routes.SettingsLegal]: "Visited Legal Settings Screen",
    [Routes.SettingsAccount]: "Visited Account Settings Screen",
    [Routes.SettingsStoreTables]: "Visited Tables Settings Screen",

    [Routes.EmployeeTimeCards]: "Viewed Time Cards Tab",
    [Routes.SettingsPermissions]: "Viewed Self Serve Permissions",

    // Guestbook has its own segment tracking with more specific details about campaigns, so leaving it out here.
    [Routes.GuestbookCampaignsSMS]: null,
    [Routes.GuestbookCampaignsSMSDetails]: null,
    [Routes.GuestbookCampaignsPush]: null,
    [Routes.GuestbookCampaignsPushDetails]: null,
    [Routes.GuestbookConversations]: null,

    [Routes.Billing]: "Visited Billing Screen",

    [Routes.Payouts]: "Visited Payouts Screen",
    [Routes.PayoutsSchedule]: "Visited Payouts Schedule Screen",
    [Routes.TaxForms]: "Visited Tax Forms Screen",

    [Routes.Devices]: "Visited Devices Screen",
    [Routes.PrepStations]: "Visited Prep Stations Screen",

    // user invite flow /onboarding routes
    [Routes.MyAccountTeams]: null,
    [Routes.NewUserAcceptInvite]: null,
    [Routes.Onboarding]: null,

    // internal routes
    [Routes.InternalSettings]: null,
    [Routes.InternalFees]: null,
    [Routes.InternalSeniorSupport]: null,

    // Auth related routes
    [Routes.NotAllowed]: null,
    [Routes.Logout]: null,

    // dead routes, will remove in another PR.
    // tested by visiting the route locally and verifying nothing rendered
    [Routes.Resources]: null,
    [Routes.Settings]: null, // old parent tab, doesn't render
    [Routes.FinancialReports]: null, // old parent tab, doesn't render
    [Routes.SettingsOrders]: null, // this screen renders, it's just deprecated
    [Routes.Inventory]: null // we don't have any navigation for this anywhere because we moved this under menu, but it still renders the inventory manager
};
