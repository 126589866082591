import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { PubNubPublisher, utils } from "@snackpass/conversations.client";
import { ChatChannelMetadata } from "@snackpass/conversations.types";
import { SystemColors } from "@snackpass/design-system";
import { truncate } from "lodash";
import moment from "moment";
import { ChannelMetadataObject, ObjectCustom } from "pubnub";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as StatusIndicatorBlue } from "src/assets/icons/status-indicator-blue.svg";
import {
    getActiveStoreNumUnreadChannels,
    removeUnreadChannel
} from "src/redux/slices";
import colors from "#reusable/colors/colors.json";

const ConversationComponent = ({
    channel,
    pub,
    setActiveChannelId,
    activeChannelId,
    setActiveChatChannel,
    setShowConversation
}: {
    channel: ChannelMetadataObject<ObjectCustom>;
    pub: PubNubPublisher;
    setActiveChannelId: React.Dispatch<React.SetStateAction<string>>;
    activeChannelId: string;
    setActiveChatChannel: React.Dispatch<
        React.SetStateAction<ChannelMetadataObject<ObjectCustom> | undefined>
    >;
    setShowConversation: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [_, storeUuid, userUuid] = channel.id.split(".");
    const [__, storeId] = storeUuid.split("_");
    const [___, userId] = userUuid.split("_");
    const dispatch = useDispatch();
    const isActive = activeChannelId === userId;

    const custom = channel.custom as ChatChannelMetadata;

    const unreadChannels = useSelector(getActiveStoreNumUnreadChannels);

    const numOrders = custom.userNumOrders;
    const lastUpdate = channel.updated;
    const lastUserChatTT = custom.userLastChatTT ?? 0;
    const mostRecentMessage = utils.getMostRecentMessage(
        channel.custom as ChatChannelMetadata
    );
    const [isUnread, setIsUnread] = useState<boolean>(
        unreadChannels.includes(channel.id)
    );

    const onClick = useCallback(() => {
        if (isUnread) {
            // Store our last-read timetoken in channel metadata...
            void pub.chat({ storeId, userId }).mergeMetadata({
                custom: {
                    storeLastReadTT: lastUserChatTT
                }
            });

            // Mark channel as read
            void pub.store(storeUuid).markChannelAsRead(channel.id);

            dispatch(removeUnreadChannel(channel.id));
            setIsUnread(false);
        }
        setActiveChannelId(userId);
        setActiveChatChannel(channel);
        setShowConversation(true);
    }, [pub, setIsUnread]);

    if (!custom) return null;

    return (
        <Container $isActive={isActive} className={styles} onClick={onClick}>
            <div className="conversation-channel-details">
                <div className="conversation-channel-header-container">
                    <ConversationTitle isUnread={isUnread}>
                        {isUnread && (
                            <span>
                                <StatusIndicatorBlue />
                            </span>
                        )}
                        {channel.name}
                    </ConversationTitle>
                    {lastUpdate && (
                        <div className="conversation-channel-date">
                            {moment(lastUpdate).format("M/DD/YY")}{" "}
                            <i
                                style={{ marginLeft: 3 }}
                                className="fas fa-chevron-right"
                            />{" "}
                        </div>
                    )}
                </div>
                {numOrders ? (
                    <div className="conversation-channel-orders">
                        {numOrders} {numOrders > 1 ? "orders" : "order"}
                    </div>
                ) : null}

                <div className="conversation-channel-message-preview">
                    {mostRecentMessage &&
                        truncate(mostRecentMessage, { length: 103 })}
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div<{ $isActive: boolean }>`
    background-color: ${(props) =>
        props.$isActive ? SystemColors.v1.candy90 : SystemColors.v1.white};
    &:hover {
        background-color: ${(props) =>
            props.$isActive ? SystemColors.v1.candy90 : SystemColors.v1.gray90};
    }
    cursor: pointer;
`;

const styles = css`
    text-decoration: none;

    padding: 10px 15px;
    border-bottom: 1px solid ${colors["neutral-400"]};

    .conversation-channel-details {
        display: flex;
        flex-direction: column;
    }

    .conversation-channel-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .conversation-channel-date {
        color: ${SystemColors.v1.gray30};
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .metrics-container {
        display: flex;
        margin: 3px 0;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .metrics-details {
        margin: 0;
        flex: 1;
        font-size: 16px;
        margin-right: 15px;
        color: ${SystemColors.v1.gray30};
    }

    .conversation-channel-message {
        color: ${SystemColors.v1.sesame};
        margin: 0;
        margin-top: 5px;
        font-size: 16px;
    }
    .conversation-channel-orders {
        color: ${SystemColors.v1.candy50};
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .conversation-channel-message-preview {
        color: ${SystemColors.v1.gray40};
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
`;

type ConversationTitleProps = {
    isUnread: boolean;
};

const ConversationTitle = styled.div<ConversationTitleProps>`
    font-size: 18px;
    margin-right: 15px;
    font-weight: ${({ isUnread }) => (isUnread ? "bold" : "regular")};
    flex: 1;
    color: ${SystemColors.v1.black};
    span {
        padding-right: 6px;
        vertical-align: text-bottom;
    }
`;

export const Conversation = React.memo(ConversationComponent);
