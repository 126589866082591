import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { z } from "zod";
import { Spinner } from "react-activity";
import {
    EmployeePermissions,
    IUserWithEmployee
} from "@snackpass/snackpass-types";
import { useMediaQuery } from "react-responsive";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import clsx from "clsx";

import constants from "#core/constants";
import RemoveAdminAlertDialog from "#settings/settings-permissions/remove-admin-alert-dialog";
import { getAdminColumns } from "#settings/settings-permissions/admin-table-columns";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { useHasEditAdminForActiveStore } from "#hooks/use-has-edit-admin-for-active-store";
import { DataTable } from "src/@/components/ui/data-table";
import { getActiveStore, useActiveUser } from "src/redux/selectors";
import api from "src/api/rest";
import { Text } from "#reusable/text/index";
import { Button } from "src/@/components/ui/button";
import AdminInviteUserPopover from "#settings/settings-permissions/admin-invite-user-popover";
import { PendingInvites } from "#settings/settings-permissions/pending-invites-table";
import { UserInvite } from "src/core/types";
import { TooltipProvider } from "src/@/components/ui/tooltip";
import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger
} from "src/@/components/ui/HybridTooltip";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import {
    UserEmployeePermission,
    useFilteredData
} from "#settings/settings-permissions/hooks/use-filters";
import { Input } from "src/@/components/ui/input";

import AdminViewEditPopover from "./admin-view-edit-popover";
import {
    AdminColumn,
    AdminFields,
    InviteFields,
    PermissionsList,
    RoleEntry,
    SnackpassPermissions,
    adminColSchema
} from "./types";

export const SettingsPermissionsScreen = () => {
    const canEditAdmin = useHasEditAdminForActiveStore();
    const [tableData, setTableData] = useState<AdminColumn[]>([]);
    const [allAdminData, setAllAdminData] = useState<IUserWithEmployee[]>([]);
    const [pendingInvites, setPendingInvites] = useState<UserInvite[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUserWithEmployee | null>(
        null
    );
    const [showAdminPopover, setShowAdminPopover] = useState(false);
    const [showInviteUserPopover, setShowInviteUserPopover] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [openAlertOnUserId, setOpenAlertOnUserId] = useState("");

    const permissionsOptions = PermissionsList;

    const user = useActiveUser();
    const activeStore = useSelector(getActiveStore);

    const hasEmployeeAuditTracking =
        activeStore && activeStore?.hasEmployeeAuditTracking;
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const padding = isMobile ? "px-4" : "px-24";

    const onClosePopover = () => {
        setSelectedUser(null);
        setShowAdminPopover(false);
    };

    const openAdminPopover = (userId: string) => {
        const admin = allAdminData.find((user) => user._id === userId) ?? null;
        if (admin) {
            setSelectedUser(admin);
            setShowAdminPopover(true);
        }
    };

    const columns = getAdminColumns(
        openAdminPopover,
        setOpenAlertOnUserId,
        canEditAdmin,
        activeStore?._id
    );

    const {
        jobTitleOptions,
        setJobTitleOptions,
        filteredJobTitle,
        setFilteredJobTitle,
        filteredPermissions,
        setFilteredPermissions,
        onFilteredJobTitleSelected,
        onFilteredPermissionsSelected
    } = useFilteredData(allAdminData, activeStore, setTableData);

    const saveAdmin = useCallback(
        (
            admin: AdminFields,
            permissionUpdates: SnackpassPermissions,
            employeePermissions?: EmployeePermissions
        ) => {
            if (isSubmitting) return;
            const theAdmin =
                allAdminData.find((user) => user._id === selectedUser?._id) ??
                null;
            if (!theAdmin || !activeStore?._id) {
                return;
            }
            const adminUpdate = {
                wage: admin.wage,
                role: admin.role,
                identifier: admin.identifier
            };
            setIsSubmitting(true);
            api.admins
                .updateAdmin(
                    theAdmin._id,
                    { user: adminUpdate },
                    activeStore._id,
                    permissionUpdates,
                    admin.pin,
                    employeePermissions
                )
                .then((result) => {
                    if (result.data.message) {
                        toast.info(result.data.message);
                    }
                    void fetchAdmins();
                })
                .catch((e) => {
                    toast.error("Error Updating Admin", {
                        description: findErrorMessage(e)
                    });
                    console.log(e);
                })
                .finally(() => {
                    trackSegmentEvent(
                        SegmentEvents.Permissions.UPDATED_A_USER,
                        {
                            store_id: activeStore._id,
                            store_name: activeStore.name,
                            user_id: user?._id
                        }
                    );
                    onClosePopover();
                    setIsSubmitting(false);
                });
        },
        [allAdminData, activeStore?._id, selectedUser?._id, isSubmitting]
    );

    const inviteAdmin = useCallback(
        (
            invite: InviteFields,
            permissionUpdates: SnackpassPermissions,
            employeePermissions: EmployeePermissions
        ) => {
            if (!activeStore?._id || isSubmitting || !canEditAdmin) {
                return;
            }
            setIsSubmitting(true);

            api.admins
                .sendInvite(
                    activeStore._id,
                    invite.email,
                    permissionUpdates,
                    employeePermissions,
                    invite.wage || "0",
                    invite.role,
                    invite.identifier || undefined,
                    invite.pin
                )
                .then((_) => {
                    toast.success(`Invite email sent to ${invite.email}`);
                    void fetchAdmins();
                })
                .catch((e) => {
                    toast.error("Error Inviting User", {
                        description: findErrorMessage(e)
                    });
                    console.log(e);
                })
                .finally(() => {
                    setIsSubmitting(false);
                    setShowInviteUserPopover(false);
                });
        },
        [activeStore?._id, canEditAdmin, isSubmitting]
    );

    const createAdmin = useCallback(
        (
            admin: AdminFields,
            permissionUpdates: SnackpassPermissions,
            employeePermissions?: EmployeePermissions
        ) => {
            if (!activeStore?._id || isSubmitting) {
                return;
            }
            if (admin.number?.length === 0) {
                delete admin.number;
            }
            if (admin.email?.length === 0) {
                delete admin.email;
            }

            setIsSubmitting(true);
            api.admins
                .create(
                    { user: admin },
                    activeStore._id,
                    permissionUpdates,
                    employeePermissions,
                    admin.pin
                )
                .then((result) => {
                    if (result.data.message) {
                        toast.info(result.data.message);
                    }
                    void fetchAdmins();
                })
                .catch((e) => {
                    toast.error("Error Creating Admin", {
                        description: findErrorMessage(e)
                    });
                    console.log(e);
                })
                .finally(() => {
                    trackSegmentEvent(
                        SegmentEvents.Permissions.CREATED_A_USER,
                        {
                            store_id: activeStore._id,
                            store_name: activeStore.name,
                            user_id: user?._id
                        }
                    );
                    setIsSubmitting(false);
                    onClosePopover();
                });
        },
        [activeStore?._id, isSubmitting]
    );

    const removeAdmin = useCallback(
        (userId?: string) => {
            if (isSubmitting) return;
            if (userId && activeStore?._id) {
                setIsSubmitting(true);
                api.admins
                    .removeAdminFromStore(userId, activeStore._id)
                    .catch((e) => {
                        toast.error("Error Removing Admin", {
                            description: findErrorMessage(e)
                        });
                        console.log(e);
                    })
                    .finally(() => {
                        trackSegmentEvent(
                            SegmentEvents.Permissions.REMOVED_A_USER,
                            {
                                store_id: activeStore._id,
                                store_name: activeStore.name,
                                user_id: user?._id
                            }
                        );
                        setIsSubmitting(false);
                        void fetchAdmins();
                    });
            }
        },
        [isSubmitting, activeStore?._id]
    );

    const saveOrUpdate = useCallback(
        (
            anAdmin: AdminFields,
            permissionUpdates: Record<string, boolean>,
            employeePermissions?: EmployeePermissions
        ) => {
            if (!canEditAdmin) return;

            if (selectedUser) {
                saveAdmin(anAdmin, permissionUpdates, employeePermissions);
            } else {
                createAdmin(anAdmin, permissionUpdates, employeePermissions);
            }
        },
        [canEditAdmin, selectedUser, saveAdmin, createAdmin]
    );

    const fetchAdmins = useCallback(async () => {
        if (!activeStore || isLoading) {
            return;
        }
        setIsLoading(true);
        const adminData = await api.stores.getAdmins(activeStore._id);
        setAllAdminData(adminData.data.users);
        setPendingInvites(adminData.data.pendingInvites);
        // roles are hidden down in User.snackpassPermissions.storeRoles
        // lets pull them up to the top level for the table
        const jobTitles: { label: string; value: string }[] = [];
        adminData.data.users.forEach(
            (user: IUserWithEmployee & { role: string }) => {
                if (user.snackpassPermissions.storeRoles.length > 0) {
                    const finalRoles: string[] = [];
                    user.snackpassPermissions.storeRoles.forEach(
                        (roleEntry: RoleEntry) => {
                            if (roleEntry.storeId === activeStore._id) {
                                finalRoles.push(roleEntry.role);
                                jobTitles.push({
                                    label: roleEntry.role,
                                    value: roleEntry.role
                                });
                            }
                        }
                    );
                    user.role = finalRoles.join(", ");
                }
            }
        );
        setJobTitleOptions(
            jobTitles.filter(
                (v, i, a) => a.map((t) => t.label).indexOf(v.label) === i
            )
        );
        setTableData(z.array(adminColSchema).parse(adminData.data.users));
        setIsLoading(false);
        setSearchTerm("");
    }, []);

    const takenPins = useMemo(() => {
        const pins = [
            ...allAdminData.map(({ employee }) => employee?.pin),
            // Watch out! pendingInvite PINs are typed as numbers, but are actually strings :(
            ...pendingInvites.map(({ pin }) => pin?.toString())
        ];
        return pins.filter((pin): pin is string => pin !== undefined);
    }, [allAdminData, pendingInvites]);

    useEffect(() => {
        if (activeStore) {
            void fetchAdmins();
        }
    }, [activeStore?._id]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            setTableData(z.array(adminColSchema).parse(allAdminData));
            return;
        } else {
            setFilteredJobTitle(new Set());
            setFilteredPermissions(new Set());
            const search = searchTerm.toLowerCase();
            const filteredData: IUserWithEmployee[] = [];
            allAdminData.forEach((admin) => {
                for (const roleEntry of admin.snackpassPermissions.storeRoles) {
                    if (
                        roleEntry.storeId === activeStore?._id &&
                        roleEntry.role.toLowerCase().includes(search)
                    ) {
                        filteredData.push(admin);
                        return;
                    }
                }
                if (admin.email?.toLowerCase().includes(search)) {
                    filteredData.push(admin);
                    return;
                }
                if (admin.number?.includes(search)) {
                    filteredData.push(admin);
                    return;
                }
                if (admin.name?.toLowerCase().includes(search)) {
                    filteredData.push(admin);
                    return;
                }
                if (
                    admin.employee &&
                    admin.employee.role.toLowerCase().includes(search)
                ) {
                    filteredData.push(admin);
                    return;
                }
                if (
                    admin.employee?.identifier &&
                    admin.employee.identifier.toLowerCase().includes(search)
                ) {
                    filteredData.push(admin);
                    return;
                }
                if (
                    admin.employee?.pin &&
                    admin.employee.pin.includes(search)
                ) {
                    filteredData.push(admin);
                    return;
                }
            });
            setTableData(z.array(adminColSchema).parse(filteredData));
        }
    }, [
        searchTerm,
        allAdminData,
        setFilteredJobTitle,
        setFilteredPermissions,
        activeStore?._id
    ]);

    return (
        <div className="my-12 flex flex-col justify-center overflow-y-scroll">
            {isLoading ? (
                <div className="mt-2 flex w-full items-center justify-center">
                    <Spinner speed={0.5} size={18} />
                </div>
            ) : (
                <div>
                    <div
                        className={clsx(
                            "mb-4 flex items-center justify-between border-b border-neutral-400 pb-4",
                            padding
                        )}
                    >
                        <Text size="l" weight="bold">
                            {`${tableData.length} ${tableData.length === 1 ? "Person" : "People"}`}
                        </Text>
                        <div className="flex items-center">
                            <TooltipProvider delayDuration={100}>
                                <HybridTooltip>
                                    <HybridTooltipTrigger asChild>
                                        <Button
                                            className="mr-4 rounded-full px-2"
                                            variant="outline"
                                        >
                                            <ReloadIcon
                                                className="h-5 w-5"
                                                onClick={fetchAdmins}
                                            />
                                        </Button>
                                    </HybridTooltipTrigger>

                                    <HybridTooltipContent
                                        className="w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                                        sideOffset={5}
                                        side="bottom"
                                    >
                                        <p>Refresh</p>
                                    </HybridTooltipContent>
                                </HybridTooltip>
                            </TooltipProvider>
                            <Button
                                onClick={() => setShowInviteUserPopover(true)}
                                disabled={!canEditAdmin}
                            >
                                Invite User
                            </Button>
                        </div>
                    </div>
                    <div className={padding}>
                        <div className="mb-2 flex items-center justify-between">
                            <Input
                                className="mr-2 max-w-96"
                                placeholder="Search Name, Phone, Email, Identifier, PIN..."
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setSearchTerm(e.target.value);
                                }}
                                value={searchTerm}
                            />
                            <div className="flex space-x-2">
                                <FilterSelect
                                    title={"Job Title"}
                                    selectedValues={filteredJobTitle}
                                    options={jobTitleOptions}
                                    customButton={
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            className={clsx(
                                                "flex items-center space-x-2",
                                                filteredJobTitle.size > 0 &&
                                                    "border-neutral-800"
                                            )}
                                        >
                                            <FilterIcon className="h-4 w-4" />
                                            <span>Job Title</span>
                                        </Button>
                                    }
                                    onOptionSelected={
                                        onFilteredJobTitleSelected
                                    }
                                />
                                <FilterSelect
                                    title={"Permissions"}
                                    selectedValues={filteredPermissions}
                                    options={permissionsOptions}
                                    customButton={
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            className={clsx(
                                                "flex items-center space-x-2",
                                                filteredJobTitle.size > 0 &&
                                                    "border-neutral-800"
                                            )}
                                        >
                                            <FilterIcon className="h-4 w-4" />
                                            <span>Permissions</span>
                                        </Button>
                                    }
                                    onOptionSelected={(value: string) =>
                                        onFilteredPermissionsSelected(
                                            value as UserEmployeePermission
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <DataTable
                            data={tableData}
                            columns={columns}
                            showPagination={false}
                            customPageSize={tableData.length}
                        />
                    </div>
                    <div className={padding}>
                        <PendingInvites
                            invites={pendingInvites}
                            refreshData={fetchAdmins}
                        />
                    </div>
                    <AdminInviteUserPopover
                        takenPins={takenPins}
                        isVisible={showInviteUserPopover}
                        onClose={() => {
                            setShowInviteUserPopover(false);
                        }}
                        onSubmit={inviteAdmin}
                    />
                    <AdminViewEditPopover
                        admin={selectedUser}
                        isVisible={showAdminPopover}
                        onClose={onClosePopover}
                        creating={selectedUser === null}
                        onSubmit={saveOrUpdate}
                    />
                </div>
            )}
            <RemoveAdminAlertDialog
                userId={openAlertOnUserId}
                onConfirm={removeAdmin}
                setOpenAlert={(open) => {
                    if (!open) setOpenAlertOnUserId("");
                }}
            />
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findErrorMessage = (e: any): string => {
    try {
        if (e.cause.response.data.message.details[0].message) {
            return e.cause.response.data.message.details[0].message;
        }
    } catch (ee) {
        console.log(ee);
    }
    try {
        if (e.cause.response.data.message) {
            return e.cause.response.data.message;
        }
    } catch (ee) {
        console.log(ee);
    }
    return "";
};
