import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "sonner";

import api from "src/api/rest";
import { PrepStationDeviceDetails } from "#devices/utils/deviceTypes/PrepStationDevice";
import { DeviceType } from "#devices/utils/deviceTypes";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStore } from "src/redux/selectors";

const fetchPrepStations = async (
    storeId: string
): Promise<PrepStationDeviceDetails[]> => {
    const data = (await api.storeDevices.listStoreDevices(storeId)).data
        .devices;
    const prepStations = data.filter(
        (d) => d.deviceType === DeviceType.PrepStation
    );

    const prepStationPromises = prepStations.map(async (station) => {
        try {
            const res = await api.storeDevices.getStoreDevice(station.id);
            return res.data.device.deviceDetails as PrepStationDeviceDetails;
        } catch (error) {
            toast.error(`Failed to fetch details for station: ${station.name}`);
            throw error;
        }
    });

    const prepDetailsResults = await Promise.allSettled(prepStationPromises);

    return prepDetailsResults
        .filter(
            (
                result
            ): result is PromiseFulfilledResult<PrepStationDeviceDetails> =>
                result.status === "fulfilled"
        )
        .map((result) => result.value);
};

export function usePrepStations() {
    const store = useAppSelector(getActiveStore);

    const key = {
        storeId: store?._id || "",
        label: "prep stations"
    };

    const {
        data: prepStations,
        isPending,
        isFetching,
        error,
        refetch
    } = useQuery({
        queryKey: [key],
        queryFn: async (context) =>
            fetchPrepStations(context.queryKey[0].storeId),
        refetchOnWindowFocus: true
    });

    const nonNullPrepStations = prepStations
        ? prepStations.filter((station) => station !== null)
        : undefined;

    useEffect(() => {
        if (error) {
            toast.error(`Error fetching prep stations`, {
                description: error.message
            });
        }
    }, [error]);

    return {
        prepStations: nonNullPrepStations,
        isPending,
        isFetching,
        error,
        refetch
    };
}
