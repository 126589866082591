import { z } from "zod";
import { useFormContext } from "react-hook-form";
import { NumberInput } from "@tremor/react";
import clsx from "clsx";

import {
    FormField,
    FormDescription,
    FormMessage,
    FormItem,
    FormControl
} from "src/@/components/ui/form";
import { Separator } from "src/@/components/ui/separator";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { Switch } from "src/@/components/ui/switch";
import { FormCard, FormCardProps } from "src/@/components/form-card";
import {
    PriceAdjustment,
    PriceAdjustmentPolarity,
    PriceAdjustmentSchema
} from "#menu-manager/components/menu-form/schema";
import { PriceAdjustmentType } from "src/api/graphql/generated/types";
import { ToggleRow } from "#menu-manager/components/ToggleRow";
import { DEFAULT_MENU_VALUES } from "#menu-manager/components/menu-form/lib";

type Props = Omit<FormCardProps, "title" | "subtitle">;

export function PriceAdjustmentCard(props: Props) {
    const form = useFormContext<PriceAdjustment>();
    const enabled = form.watch("priceAdjustment.enabled");
    return (
        <FormCard
            title="Default Price Adjustment"
            subtitle={"Adjust prices by a percentage or fixed amount."}
            {...props}
            sideTitleComponent={<EnableDefaultPriceAdjustmentSwitch />}
        >
            <div className={clsx(!enabled && "pointer-events-none opacity-40")}>
                <AdjustByInputs />
                <Separator className="my-4" />
                <RoundTo />
                <Separator className="mt-4" />
                <IncludeModifiers />
            </div>
        </FormCard>
    );
}

function EnableDefaultPriceAdjustmentSwitch() {
    const form = useFormContext<PriceAdjustment>();
    return (
        <FormField
            control={form.control}
            name="priceAdjustment.enabled"
            render={({ field: { value, onChange, name } }) => (
                <FormItem>
                    <FormControl>
                        <Switch
                            aria-label="toggle price adjustment"
                            checked={value}
                            name={name}
                            onCheckedChange={(checked) => {
                                if (checked) onChange(true);
                                else {
                                    form.reset({
                                        ...form.getValues(),
                                        priceAdjustment: {
                                            ...DEFAULT_MENU_VALUES.priceAdjustment,
                                            enabled: checked
                                        }
                                    });
                                }
                            }}
                        />
                    </FormControl>
                    <FormDescription />
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}

function AdjustByInputs() {
    const form = useFormContext<z.infer<typeof PriceAdjustmentSchema>>();
    const enabled = form.watch("priceAdjustment.enabled");
    const adjustmentType = form.watch("priceAdjustment.type");
    return (
        <div>
            <span className="block text-small font-semibold">Adjust by...</span>
            <span className="mt-1 block text-small text-neutral-600">
                Percentage or fixed amount.
            </span>
            <div className="mt-4 flex flex-col md:flex-row md:space-x-3">
                <FormField
                    control={form.control}
                    name="priceAdjustment.polarity"
                    render={({ field: { value, onChange, name } }) => (
                        <FormItem className="w-full flex-1">
                            <FormControl>
                                <Select
                                    name={name}
                                    value={value}
                                    onValueChange={onChange}
                                    disabled={!enabled}
                                >
                                    <SelectTrigger
                                        className="flex-1"
                                        aria-label="markup or markdown price"
                                    >
                                        <SelectValue placeholder="Markdown" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem
                                            value={
                                                PriceAdjustmentPolarity.MARKDOWN
                                            }
                                        >
                                            Markdown
                                        </SelectItem>
                                        <SelectItem
                                            value={
                                                PriceAdjustmentPolarity.MARKUP
                                            }
                                        >
                                            Markup
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormDescription />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="priceAdjustment.type"
                    render={({ field: { name, value, onChange } }) => (
                        <FormItem className="w-full flex-1">
                            <FormControl>
                                <Select
                                    name={name}
                                    value={value}
                                    onValueChange={onChange}
                                    disabled={!enabled}
                                >
                                    <SelectTrigger aria-label="flat or percentage price adjustment">
                                        <SelectValue placeholder="Percentage" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem
                                            value={
                                                PriceAdjustmentType.Percentage
                                            }
                                        >
                                            Percentage
                                        </SelectItem>
                                        <SelectItem
                                            value={PriceAdjustmentType.Flat}
                                        >
                                            Flat
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormDescription />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="priceAdjustment.value"
                    render={({ field: { value, onChange } }) => (
                        <FormItem className="w-full flex-1">
                            <FormControl>
                                {adjustmentType ===
                                PriceAdjustmentType.Flat /* Allow cents input for absolute amounts */ ? (
                                    <NumberInput
                                        disabled={!enabled}
                                        className="[&>input]:pl-2"
                                        enableStepper={false}
                                        step={0.01}
                                        icon={() => (
                                            <div className="flex w-5 items-center justify-center">
                                                $
                                            </div>
                                        )}
                                        aria-label="price adjustment dollar value"
                                        min={0}
                                        placeholder={"0.00"}
                                        value={
                                            value !== null ? value / 100 : ""
                                        }
                                        onValueChange={(v) => {
                                            if (Number.isNaN(v)) onChange(null);
                                            onChange(Math.round(v * 100));
                                        }}
                                    />
                                ) : (
                                    <NumberInput
                                        disabled={!enabled}
                                        className="[&>input]:pl-2"
                                        enableStepper={false}
                                        aria-label="price adjustment percentage"
                                        step={1}
                                        min={0}
                                        icon={() => (
                                            <div className="absolute right-4 flex shrink-0 items-center text-micro">
                                                %
                                            </div>
                                        )}
                                        placeholder={"00"}
                                        value={value}
                                        onValueChange={(v) => {
                                            if (Number.isNaN(v)) onChange(null);
                                            onChange(Math.round(v));
                                        }}
                                    />
                                )}
                            </FormControl>
                            <FormDescription />
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    );
}

function RoundTo() {
    const form = useFormContext<PriceAdjustment>();
    const enabled = form.watch("priceAdjustment.enabled");
    return (
        <FormField
            control={form.control}
            name="priceAdjustment.roundTo"
            render={({ field: { value, onChange } }) => (
                <FormItem>
                    <FormControl>
                        <div>
                            <span className="block text-small font-semibold">
                                Round to...
                            </span>
                            <span className="mt-1 block text-small text-neutral-600">
                                Round price to nearest increment in cents.
                            </span>
                            <NumberInput
                                className="mt-4 w-60 [&>input]:pl-2"
                                aria-label="round to cents value"
                                enableStepper={false}
                                disabled={!enabled}
                                step={1}
                                min={0}
                                icon={() => (
                                    <span className="flex w-5 items-center justify-center">
                                        ¢
                                    </span>
                                )}
                                placeholder="00"
                                value={value ?? undefined}
                                onValueChange={(value) =>
                                    Number.isNaN(value)
                                        ? onChange(undefined)
                                        : onChange(Math.round(value))
                                }
                            />
                        </div>
                    </FormControl>
                    <FormDescription />
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}

function IncludeModifiers() {
    const form = useFormContext<PriceAdjustment>();
    const enabled = form.watch("priceAdjustment.enabled");
    return (
        <FormField
            control={form.control}
            name="priceAdjustment.adjustModifiers"
            render={({ field: { value, onChange } }) => (
                <FormItem>
                    <FormControl>
                        <ToggleRow
                            disabled={!enabled}
                            title={"Include Modifiers"}
                            className="mb-0 border-b-0 pb-0"
                            description={
                                "Adjust price for modifiers when displayed on this menu."
                            }
                            ariaLabel="toggle whether modifiers are included in price adjustment"
                            checked={!!value}
                            onCheckedChange={onChange}
                        />
                    </FormControl>
                    <FormDescription />
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}
